import React, { useContext, useMemo } from "react";
import { Button, Container, Row, Col, Card } from "react-bootstrap";
import { PDFViewer } from "@react-pdf/renderer";
import PDF from "components/PDF";
import BiolabPdf from "components/BiolabPdf";
import AltaPdf from "components/AltaPdf";
import { AppContext } from "../../context/app-context";
import API from "api";
import { CONFIG, LAB_ID } from "constant";
import { parseTestResultForPDF } from "utils";
import LabReportSisterPirerrePdf from "components/LabReportSisterPierrePdf";
import { isLocalhost } from "utils";

const PDFLayout = (props) => {
  const { testPdf, testsLab, setShowPdf } = props;
  const appContext = useContext(AppContext);

  const loadPDF = () => {
    // const LAB_ID_TEST = "b63c398e-dc3f-412c-871e-ad89a94ddb0e";
    const LAB_ID_TEST = "b63c398e-dc3f-412c-871e-ad89a94ddb00";

    const { labID, site } = testPdf;

    if (isLocalhost()) {
      return (
        <LabReportSisterPirerrePdf
          test={testPdf}
          testsLab={testsLab}
          parseTestResultForPDF={appContext?.parseTestResultForPDF || parseTestResultForPDF}
          site={site}
        />
      );
    }

    if (!CONFIG.isWhiteLabel || !labID) {
      return (
        <PDF
          test={testPdf}
          testsLab={testsLab}
          parseTestResultForPDF={appContext?.parseTestResultForPDF || parseTestResultForPDF}
        />
      );
    }

    if (labID === LAB_ID.alta || labID === LAB_ID_TEST) {
      return (
        <AltaPdf
          test={testPdf}
          testsLab={testsLab}
          parseTestResultForPDF={appContext?.parseTestResultForPDF || parseTestResultForPDF}
          site={site}
        />
      );
    }
    if (labID === LAB_ID.seroclinix) {
      return (
        <SerolinixPdf
          test={testPdf}
          testsLab={testsLab}
          parseTestResultForPDF={appContext?.parseTestResultForPDF || parseTestResultForPDF}
          site={site}
        />
      );
    }

    if (labID === LAB_ID.discovery) {
      return (
        <LabReportSisterPirerrePdf
          test={testPdf}
          testsLab={testsLab}
          parseTestResultForPDF={appContext?.parseTestResultForPDF || parseTestResultForPDF}
          site={site}
        />
      );
    }

    // return (
    //   <LabReportPrattPdf
    //     test={testPdf}
    //     testsLab={testsLab}
    //     parseTestResultForPDF={appContext?.parseTestResultForPDF || parseTestResultForPDF}
    //     site={site?.resultTemplate === "covid" ? site : null}
    //   />
    // );

    return (
      <BiolabPdf
        test={testPdf}
        testsLab={testsLab}
        parseTestResultForPDF={appContext?.parseTestResultForPDF || parseTestResultForPDF}
        site={site?.resultTemplate === "covid" ? site : null}
      />
    );
  };

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover" style={{ padding: 20, marginLeft: "auto", marginRight: "auto" }}>
            <Card.Header>
              <Row style={{ alignItems: "center" }}>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#565656",
                  }}
                >
                  Test Results - {testPdf ? testPdf.name : ""}
                </Card.Title>
                <Button
                  style={{
                    marginTop: 10,
                  }}
                  className="createButtons"
                  onClick={() => setShowPdf(false)}
                >
                  Close
                </Button>
              </Row>
            </Card.Header>
            <Card.Body className="pdfContainer px-0">
              <PDFViewer className="pdfView">{loadPDF()}</PDFViewer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PDFLayout;
