import ViewCheckBox from "components/CheckBox/ViewCheckBox";
import Icon from "components/Icon";
import Loader from "components/Loader/Loader";
import { useCallback, useEffect, useRef, useState, useMemo, useContext } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../../../src/tableStyling.css";
import EditIcon from "../../assets/img/edit-icon.png";
import TrashIcon from "../../assets/img/trash-icon.png";
import { INSURANCE_PERSONALIZE } from "../../constants/personalization/InsPersonalization";
import { insurnaceSelector, totalInsurancesRecordsSelector } from "../../store/features/insurances/insuranceSelectors";
import { selectedCompanySetting } from "../../store/features/companySetting/companySettingSelectors";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { t } from "../../stringConstants";
import Filter from "components/Filter";
import PersonalizationModal from "components/Modal/personalizationModal";
import ExportToExcel from "components/ExportToExcel";
import {
  fetchInsurancesAsync,
  setInsurnaceList,
  setOpenInsuranceModal,
  setSelectedInsurance,
} from "../../store/features/insurances/insuranceSlice";
// import InsuranceModal from "../../components/Insurances/InsuranceModal;
import { debounce } from "debounce";
import InsuranceModal from "components/Insurances/InsuranceModal";
import {
  INSURANCE_EMPTY_OBJECT,
  PAGE_LIMIT,
  TABLE_QUICK_TOOLS,
  PAYER_MANAGEMENT_VALUE,
  PAYER_DROPDOWN_OPTIONS,
} from "../../constant";
import MainTable from "../../components/Table/MainTable";
import { draggablePersonalizationLocalStorage, userCompanyID, formatNumber, nameToTitleCase } from "../../utils";
import api from "../../api";
import { AppContext } from "context/app-context";
import { userGivenPermission } from "store/features/authentication/authenticationSelectors";
import PGPagination from "components/Pagination/PGPagination";
import Status from "components/Status/Status";

const Insurances = (props) => {
  const [sortBy, setSortBy] = useState("");
  const [personalize, setPersonalize] = useState([]);
  const [sortDescending, setSortDescending] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const dispatch = useDispatch();
  const { insurancesList, filteredInsurances } = useSelector(insurnaceSelector);
  const totalInsurances = useSelector(totalInsurancesRecordsSelector);
  const setting = useSelector(selectedCompanySetting);
  const appContext = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(PAGE_LIMIT);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const permission = useSelector(userGivenPermission);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNo, setPageNo] = useState(1);

  const quickTools = [permission[PAYER_MANAGEMENT_VALUE]?.write && TABLE_QUICK_TOOLS.edit];
  const loginUser = appContext.user;
  const searchTerms = ["Name", "Alias", "Electronic Claims", "Electronic Eligibility", "Electronic ERA", "Active"];

  const fetchFromApi = useCallback(
    debounce(async (filter, sortBy, sortDescending, page) => {
      dispatch(fetchInsurancesAsync({ ...filter, orderBy: sortBy, orderByDirection: sortDescending, page: page || 1 }));
    }, 1000),
    []
  );

  useEffect(() => {
    fetchFromApi(filter, sortBy, sortDescending);
  }, [filter, sortBy, sortDescending]);

  useEffect(() => {
    const response = draggablePersonalizationLocalStorage.get(loginUser, "insurance", INSURANCE_PERSONALIZE);
    setPersonalize(response);
  }, [setting]);

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.edit:
        dispatch(setOpenInsuranceModal(true));
        dispatch(setSelectedInsurance({ ...row, isNew: false }));
        break;

      default:
        break;
    }
  };

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    const personalisationData = draggablePersonalizationLocalStorage.saveAs(data, "insurance");
    setOpenPersonalizationModal(false);
    draggablePersonalizationLocalStorage.save(JSON.stringify(personalisationData));
    await api.saveUserPersonalisation(loginUser.sub, personalisationData);
  };

  const insurancesToMap = useMemo(() => {
    const list = filteredInsurances ? [...filteredInsurances] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredInsurances, currentPage]);

  const handleChangePage = (page, direction) => {
    setCurrentPage(page);
    const currentRecord = filteredInsurances.length;
    if (direction === "next" && page === totalPages && totalInsurances > 0 && currentRecord < totalInsurances) {
      setPageNo(pageNo + 1);
    }
  };

  useEffect(() => {
    if (pageNo > 1) {
      fetchFromApi(filter, sortBy, sortDescending, pageNo);
    }
  }, [pageNo]);

  const customRenderTD = (item, row, index) => {
    if (item.itemKey === "isActive") {
      const isActive = row[item.itemKey] || false;
      return (
        <td>
          <div className="star-icon-wrapper cursor-pointer text-center">
            <Status
              type="circle"
              size="md"
              title={isActive ? "Active" : "Inactive"}
              color={isActive ? "green" : "orange"}
              crossIcon={isActive === false}
              tickIcon={isActive === true}
            />
          </div>
        </td>
      );
    }
    if (item.itemKey === "network") {
      const inNetwork = row[item.itemKey] === "In Network" ? true : false;
      return (
        <td>
          <div className="star-icon-wrapper cursor-pointer text-center">
            <Status
              type="circle"
              size="md"
              title={row[item.itemKey]}
              color={inNetwork ? "green" : "red"}
              crossIcon={inNetwork === false}
              tickIcon={inNetwork === true}
            />
          </div>
        </td>
      );
    }
  };

  return (
    <div style={{ flex: 1 }}>
      <>
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  {`${t("payerManagement")}  (${formatNumber(totalInsurances) || insurancesToMap.length})`}
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />
                  <Icon
                    handleClick={() => setOpenPersonalizationModal(true)}
                    title={t("personalize")}
                    label={t("personalize")}
                    iconType={"personalizeIcon"}
                  />

                  <ExportToExcel
                    selectedItem={personalize.length > 0 && personalize.filter((m) => m.isCheck && m.itemKey)}
                    items={filteredInsurances.map((e) => {
                      return {
                        ...e,
                      };
                    })}
                    filter={filter}
                    title={t("payerManagement")}
                    sortBy={sortBy}
                  />
                  {permission[PAYER_MANAGEMENT_VALUE]?.write && (
                    <Icon
                      handleClick={() => {
                        dispatch(setOpenInsuranceModal(true));
                        dispatch(setSelectedInsurance({ ...INSURANCE_EMPTY_OBJECT, clientID: userCompanyID.get() }));
                      }}
                      title={"Create Insurance"}
                      label={"Create"}
                      iconType={"createIcon"}
                    />
                  )}
                </div>
                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    personalisationData={INSURANCE_PERSONALIZE}
                  />
                )}
              </Card.Header>

              <Card.Body className="table-full-width desktop-noScroll">
                <Card.Header className="shows-screen-wrapper mb-3">
                  <Row className="pb-4">
                    <Col md-="6"></Col>
                    <Col md="6">
                      <PGPagination
                        currentPage={currentPage}
                        handleChangePage={handleChangePage}
                        totalPages={totalPages}
                        currentRecord={filteredInsurances?.length}
                      />
                    </Col>
                  </Row>
                </Card.Header>
                <MainTable
                  cssClass={filteredInsurances.length === 0 && "overFlow-y-hidden"}
                  columns={personalize}
                  rows={insurancesToMap}
                  flipSort={flipSort}
                  sortBy={sortBy}
                  sortDescending={sortDescending}
                  draggable
                  resizable
                  personalisationKey={"insurance"}
                  tools={quickTools}
                  handleSaveDragAndResize={(personalizeArr) => setPersonalize(personalizeArr)}
                  widthToSkip={130}
                  handleCellClick={handleCellClick}
                  customColumnCellRenderer={customRenderTD}
                  dropDownOptions={PAYER_DROPDOWN_OPTIONS}
                  handleDropDownClick={(type, row) => {
                    console.log("Type", type);
                    if (type === "Audit Trail") {
                      appContext.showLogs({ ...row, recordType: "logs", title: "Payer Logs" });
                    }
                  }}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {openPersonalizationModal && (
          <PersonalizationModal
            data={personalize}
            handleChange={handlePersonalization}
            show={openPersonalizationModal}
            handleClose={() => setOpenPersonalizationModal(false)}
          />
        )}
      </>
    </div>
  );
};

export default Insurances;
