/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import Test from "views/Test";
import TableListApi from "views/GraphQL/TableListApi";
import ProcessedTestListApi from "views/GraphQL/ProcessedTestListApi";
import UsersViewWhiteLabel from "views/GraphQL/UsersViewWhiteLabel";
import Typography from "views/Typography.js";
import Icons from "views/Icons.js";
import Maps from "views/Maps.js";
import Notifications from "views/Notifications.js";
import Upgrade from "views/Upgrade.js";
import Settings from "views/Settings.js";
import LabSites from "views/GraphQL/LabSites";
import CrewProfile from "components/CrewProfile";
import ExternalTestCrew from "views/ExternelTestCrew";
import settingIcon from "../assets/img/settings-garage.png";
import AdminTotalTestApi from "views/GraphQL/AdminTotalTestApi";
import DeleteTestApi from "views/GraphQL/DeleteTestApi";
import PendingReleaseTestListView from "views/GraphQL/PendingReleaseTestListView";

import UnprocessedTestsApi from "views/GraphQL/UnprocessedTestsApi";
import UsersView from "views/GraphQL/UsersView";
import ProfileEmployeeTest from "views/ProfileEmployeeTest";
// import TestTypesSetting from "views/TestTypeSetting/TestTypesSetting";
import LabTestTypesSetting from "views/TestTypeSetting/LabTestTypesSetting";
import calendarIcon from "../assets/img/calendar-icon.svg";
import TestDetails from "views/TestDetails";
import Employee from "views/Hipaa/Employees";
import PatientView from "views/GraphQL/PatientView";
import CheckEligibility from "views/GraphQL/CheckEligibility";
import OrdersView from "views/Order/OrderView";
import PatientProfile from "views/PatientProfile";
import TestListAdminApi from "views/GraphQL/TestListCompletedTest/TestListAdminApi";

const dashboardRoutes = [
  // {
  //   path: "/employees",
  //   name: "Employee",
  //   icon: "nc-icon nc-notes",
  //   component: Employee,
  //   layout: "/admin",
  // },
  {
    path: "/patient",
    name: "Patients",
    icon: "nc-icon nc-notes",
    component: PatientView,
    layout: "/admin",
  },
  {
    path: "/order",
    name: "Orders",
    icon: "nc-icon nc-notes",
    component: OrdersView,
    layout: "/admin",
  },
  // {
  //   path: "/unprocessedtests",
  //   name: "Unprocessed Tests",
  //   icon: "nc-icon nc-notes",
  //   component: UnprocessedTestsApi,
  //   layout: "/admin",
  // },
  {
    path: "/senttest",
    name: "Pending Results",
    icon: "nc-icon nc-notes",
    component: UnprocessedTestsApi,
    layout: "/admin",
  },
  {
    path: "/alltests",
    name: "Completed Tests",
    icon: "nc-icon nc-check-2",
    component: TestListAdminApi,
    layout: "/admin",
  },
  {
    path: "/test/:testId",
    name: "Test",
    icon: "nc-icon nc-circle-09",
    component: TestDetails,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/employee/:employeeId",
    name: "Profile Employee Test",
    icon: "nc-icon nc-circle-09",
    component: ProfileEmployeeTest,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/profile/:patientId",
    name: "Profile Patient Test",
    icon: "nc-icon nc-circle-09",
    component: PatientProfile,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/externaltest/:testId",
    name: "Test",
    icon: "nc-icon nc-circle-09",
    component: ExternalTestCrew,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/crewprofile/:empId",
    name: "CrewProfile",
    icon: "nc-icon nc-circle-09",
    component: CrewProfile,
    layout: "/admin",
    redirect: true,
  },
  // {
  //   path: "/locations",
  //   name: "Locations",
  //   icon: "nc-icon nc-tv-2",
  //   component: LabSites,
  //   layout: "/admin",
  // },

  // {
  //   path: "/whiteusers",
  //   name: "System User",
  //   icon: "nc-icon nc-badge",
  //   component: UsersViewWhiteLabel,
  //   layout: "/admin",
  // },
  // {
  //   path: "/checkeligibility",
  //   name: "Check Eligibility",
  //   icon: "nc-icon nc-badge",
  //   src: calendarIcon,
  //   component: CheckEligibility,
  //   layout: "/admin",
  // },
  {
    path: "/testtypes",
    name: "Settings",
    icon: "nc-icon nc-notes",
    src: settingIcon,
    component: LabTestTypesSetting,
    layout: "/admin",
  },
  {
    path: "/archivedtests",
    name: "Archived Tests",
    icon: "nc-icon nc-check-2",
    component: DeleteTestApi,
    layout: "/admin",
  },
];

export default dashboardRoutes;
