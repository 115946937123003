import CheckBoxBar from "components/CheckBox/CheckBoxBar";
import CheckBoxInput from "components/CheckBoxInput/CheckBoxInput";
import RadioInput from "components/RadioInput/RadioInput";
import { RADIO_OPTION_REF_INTERVAL } from "constant";
import { RESULT_SETTINGS_OPT, RESULT_SETTINGS_OPT_QUALITATIVE, ANTIBIOTIC_RESISTANCE_GENES_TYPE } from "constant";
import { TESTS_UNITS_SET } from "constant";
import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import Select from "react-select";
import { generateRefInterval, parseJSONObject } from "utils";
import TestTextAreaField from "./TestTextAreaField";
import TestInputField from "./TestInputField";
import InputField from "components/InputField/InputField";
import TestDetailsRangeView from "./TestDetailsRangeView/TestDetailsRangeView";
import Icon from "components/Icon";
import moment from "moment";
import { AppContext } from "context/app-context";
import { customIsEmpty } from "util/customLodash";
import TestSelector from "./TestUnitSelector/TestSelector";
import { customCloneDeep } from "util/customLodash";
import { TEST_SETTING_TYPES } from "constant";
import { PANEL_RESULT_TYPE } from "constant";
import { isAnyRangeEmpty } from "utils";

const TestDetailsModal = ({ data, handleSaveTestDetail, handleClose }) => {
  const appContext = useContext(AppContext);

  const [newItem, setNewItem] = useState({
    isNew: true,
    name: "",
    units: {
      label: "cfu/mL",
      value: "cfu/mL",
    },
    minValueF: "",
    maxValueF: "",
    maxValueM: "",
    minValueM: "",
    effectiveDate: "",
    additionalInfo: "",
    possibleValues: "",
    refInterval: "",
    isActive: "",
  });

  const [ranges, setRanges] = useState([
    {
      id: 1,

      // AgeStart: "",
      // AgeEnd: "",

      NormalLow: "",
      NormalHigh: "",

      HighLow: "",
      HighHigh: "",

      PanicLow: "",
      PanicHigh: "",

      RepeatLow: "",
      RepeatHigh: "",

      LowLow: "",
      LowHigh: "",
    },
  ]);

  const [refIntervalSelection, setRefIntervalSelection] = useState("ctscore");

  const [resultType, setResultType] = useState("");

  const setRefInterval = (data) => {
    let refType;

    try {
      refType = JSON.parse(data.refType);
    } catch (error) {
      refType = "ctscore";
    }
    setRefIntervalSelection(refType);
  };

  useEffect(() => {
    if (data) {
      setNewItem({ ...newItem, ...data });
      setRanges(
        data?.ranges || [
          {
            id: 1,

            // AgeStart: "",
            // AgeEnd: "",

            NormalLow: "",
            NormalHigh: "",

            HighLow: "",
            HighHigh: "",

            PanicLow: "",
            PanicHigh: "",

            RepeatLow: "",
            RepeatHigh: "",

            LowLow: "",
            LowHigh: "",
          },
        ]
      );
      setRefInterval(data);
      setResultType(data?.resultType || "");
    }
  }, [data]);

  const validateInput = () => {
    if (!newItem.name) {
      appContext.showErrorMessage("Enter valid pathegon name");
      return;
    }
    if (!newItem.units || customIsEmpty(newItem.units)) {
      appContext.showErrorMessage("Select pathegon units");
      return;
    }

    if (!newItem.sources || customIsEmpty(newItem.sources)) {
      appContext.showErrorMessage("Select pathegon Source");
      return;
    }

    if (newItem.typeOfTest === "Qualitative" && isAnyRangeEmpty(ranges)) {
      appContext.showErrorMessage("Fill all ranges");
      return;
    }
    // if (
    //   newItem.typeOfTest === "Qualitative" &&
    //   (!newItem?.additionalInfo?.resultDescription || newItem?.additionalInfo?.resultDescription === "")
    // ) {
    //   appContext.showErrorMessage("Add Result Description");
    //   return;
    // }

    // if (!newItem.effectiveDate) {
    //   appContext.showErrorMessage("Select effective date");
    //   return;
    // }

    const referenceInterval = generateRefInterval({ ...newItem, ranges: ranges[0] });

    const testObj = {
      ...newItem,
      additionalInfo: newItem?.additionalInfo,
      units: newItem?.units,
      refInterval: newItem?.typeOfTest === "Qualitative" ? "Not Detected" : referenceInterval,

      // refInterval:
      //   newItem?.typeOfTest === "Qualitative"
      //     ? "Not Detected"
      //     : refIntervalSelection === "ctscore"
      //     ? referenceInterval
      //     : newItem.refInterval.value,

      refType: newItem?.typeOfTest === "Qualitative" ? "Not Detected" : refIntervalSelection,
      ranges,
      resultType,
      possibleValues: newItem.typeOfTest,
      // isActive: newItem.isActive === "" ? false : true,
      isActive: true,
    };

    handleSaveTestDetail(testObj);
  };

  const handleInputChange = (field, value) => {
    setNewItem((prev) => ({ ...prev, [field]: value }));
  };

  const handelRefIntervalChange = (e) => {
    const targetValue = e.target.value;
    setRefIntervalSelection(targetValue);
    if (targetValue === "ctscore") {
      setNewItem({ ...newItem, possibleValues: [], refInterval: "" });
    } else {
      setNewItem({ ...newItem, minValueM: "", maxValueM: "", minValueF: "", maxValueF: "" });
    }
  };

  const handleSelectPossibleResults = (e) => {
    setNewItem({ ...newItem, possibleValues: e });
  };

  const handleChangeShowResult = (e) => {
    setResultType(e);
  };

  const handleChangeRanges = (e, index) => {
    const { name, value } = e.target;
    let copyRanges = customCloneDeep(ranges);
    copyRanges[index][name] = value;
    setRanges(copyRanges);
  };

  const handleAddRange = () => {
    setRanges((prevRanges) => [
      ...prevRanges,
      {
        id: prevRanges.length + 1,

        AgeStart: "",
        AgeEnd: "",

        NormalLow: "",
        NormalHigh: "",

        HighLow: "",
        HighHigh: "",

        PanicLow: "",
        PanicHigh: "",

        RepeatLow: "",
        RepeatHigh: "",

        LowLow: "",
        LowHigh: "",
      },
    ]);
  };

  const handelRemoveGrid = (id) => {
    const filteredRanges = ranges.filter((item) => item.id !== id);
    setRanges(filteredRanges);
  };

  const handelEffectiveDate = (e) => {
    const effectiveDate = moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).format("YYYY-MM-DD") : "";
    setNewItem({ ...newItem, effectiveDate });
  };

  const handelResultDescription = (e) => {
    const value = e.target.value;

    const itemObj = {
      ...newItem,
      additionalInfo: {
        resultDescription: value,
      },
    };

    setNewItem(itemObj);
  };

  return (
    <>
      <Modal
        show
        animation={true}
        className="second-modal"
        onHide={() => handleClose()}
        centered
        size={"2xl"}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0 h4" id="contained-modal-title-vcenter">
            Pathogen Creation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: 0 }}>
          {/* First row */}

          <div style={{ display: "flex", gap: "16px" }}>
            <div style={{ flex: 1 }}>
              <TestInputField
                label="Name"
                value={newItem.name}
                onChange={(e) => handleInputChange("name", e.target.value)}
              />
            </div>

            <div style={{ flex: 1 }}>
              <TestSelector
                value={newItem?.units || null}
                onChange={(e) => setNewItem({ ...newItem, units: e })}
                settingType={"unit"}
                placeholder="Enter Unit Name"
                updateMainTest={(value) => setNewItem({ ...newItem, units: { label: value, value: value } })}
              />
            </div>
            {data?.typeOfTest === "Quantitative" && (
              <div style={{ flex: 1 }}>
                <InputField
                  type="datePicker"
                  inputStyle="w-100 orderTestDob"
                  labelStyle="modalLineHeaders mt-0 text-capitalize"
                  label="Effective Date"
                  index="ed"
                  placeholder="Effective Date"
                  disabled={false}
                  groupWrapper={`simple-form-group-wrapper mt-1`}
                  required
                  handleChange={handelEffectiveDate}
                  value={newItem.effectiveDate}
                  isFutureDateAllowed={true}
                />
              </div>
            )}
            {/* {data?.typeOfTest === "Qualitative" && (
              <div style={{ flex: 1 }}>
                <>
                  <p className="modalLineHeaders">Interface Values</p>
                  <Select
                    className="w-100"
                    options={RESULT_SETTINGS_OPT_QUALITATIVE}
                    blurInputOnSelect={true}
                    defaultValue={null}
                    menuPlacement="auto"
                    isMulti
                    value={newItem.possibleValues}
                    placeholder="Select Interface"
                    onChange={handleSelectPossibleResults}
                  />
                </>
              </div>
            )} */}

            <div style={{ flex: 1 }}>
              <TestSelector
                value={newItem.sources}
                tabIndex={3}
                onChange={(e) => setNewItem({ ...newItem, sources: e })}
                settingType={TEST_SETTING_TYPES.SOURCE}
                label={"Source"}
                required={true}
                isMulti
                title={"Create Source"}
                placeholder="Enter Source Name"
                updateMainTest={(value) => setNewItem({ ...newItem, sources: { label: value, value: value } })}
              />
            </div>

            <div style={{ flex: 1 }}>
              <>
                <p className="modalLineHeaders">Antibiotic-Resistance Genes</p>
                <Select
                  className="w-100"
                  options={ANTIBIOTIC_RESISTANCE_GENES_TYPE}
                  blurInputOnSelect={true}
                  defaultValue={null}
                  menuPlacement="auto"
                  value={
                    newItem.antibioticResistanceType
                      ? { label: newItem.antibioticResistanceType, value: newItem.antibioticResistanceType }
                      : null
                  }
                  placeholder="Select"
                  onChange={(e) =>
                    setNewItem({
                      ...newItem,
                      antibioticResistanceType: e.value,
                    })
                  }
                />
              </>
            </div>
          </div>

          {/* Second Row */}

          {data?.typeOfTest === "Quantitative" && (
            <div style={{ border: "1px solid gray", padding: "10px", marginTop: "10px", borderRadius: "10px" }}>
              <div>
                <h4 style={{ margin: "0px" }}>Panel Setup</h4>
              </div>

              {/* Third Row */}

              <div style={{ display: "flex" }}>
                <RadioInput
                  options={RADIO_OPTION_REF_INTERVAL}
                  handleRadioButtonChange={(e) => handelRefIntervalChange(e)}
                  input={refIntervalSelection}
                />
              </div>

              {/* Forth Row  */}

              <div style={{ display: "flex", gap: "16px" }}>
                {/* Forth Row First Column  */}

                <div style={{ flex: 1 }}>
                  {refIntervalSelection === "ctscore" && (
                    <TestInputField
                      label="Default Low"
                      value={newItem.minValueM}
                      onChange={(e) => handleInputChange("minValueM", e.target.value)}
                    />
                  )}
                  {refIntervalSelection === "alphabet" && (
                    <>
                      <p className="modalLineHeaders">Interface Values</p>
                      <Select
                        className="w-100"
                        options={RESULT_SETTINGS_OPT}
                        blurInputOnSelect={true}
                        defaultValue={null}
                        menuPlacement="auto"
                        isMulti
                        value={newItem.possibleValues}
                        placeholder="Select Interface"
                        onChange={handleSelectPossibleResults}
                      />
                    </>
                  )}
                </div>

                {/* Forth Row Second Column  */}

                <div style={{ flex: 1 }}>
                  {refIntervalSelection === "ctscore" && (
                    <TestInputField
                      label="Default High"
                      value={newItem.maxValueM}
                      onChange={(e) => handleInputChange("maxValueM", e.target.value)}
                    />
                  )}

                  {refIntervalSelection === "alphabet" && (
                    <>
                      <p className="modalLineHeaders">Result Values</p>
                      <Select
                        className="w-100 siteSelector"
                        options={newItem?.possibleValues || []}
                        blurInputOnSelect={true}
                        defaultValue={null}
                        menuPlacement="auto"
                        value={
                          newItem.refInterval ? RESULT_SETTINGS_OPT.find((f) => f.label === newItem.refInterval) : null
                        }
                        placeholder="Select Result Values"
                        onChange={(e) => setNewItem({ ...newItem, refInterval: e })}
                      />
                    </>
                  )}
                </div>

                <div style={{ flex: 1 }}>
                  {refIntervalSelection === "ctscore" && (
                    <CheckBoxInput
                      label="Select Result Type"
                      options={PANEL_RESULT_TYPE}
                      handleButtonChange={handleChangeShowResult}
                      input={resultType || ""}
                    />
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Ranges View  */}

          <div style={{ border: "1px solid gray", padding: "10px", marginTop: "10px", borderRadius: "10px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h4 style={{ margin: 0 }}>Range Setup</h4>
              {/* {refIntervalSelection === "ctscore" && (
                <Icon
                  iconType={"createIcon"}
                  containerClass={"ms-2 cursor-pointer"}
                  innerClass={""}
                  handleClick={handleAddRange}
                />
              )} */}
            </div>
            {/* {ranges &&
              ranges.map((range) => (
                <TestDetailsRangeView
                  key={range.id}
                  data={range}
                  onChange={handleChangeRanges}
                  handelRemoveView={(data) => handelRemoveGrid(data)}
                />
              ))} */}

            <TestDetailsRangeView
              data={ranges}
              onChange={handleChangeRanges}
              handelRemoveView={(data) => handelRemoveGrid(data)}
            />
          </div>
          {/* {data?.typeOfTest === "Qualitative" && (
            <InputField
              type="text"
              inputStyle="w-50"
              labelStyle="modalLineHeaders mt-4 text-capitalize"
              label="Result Description"
              index="ed"
              placeholder="Enter Result Description"
              disabled={false}
              groupWrapper={`simple-form-group-wrapper mt-1`}
              required
              handleChange={handelResultDescription}
              value={newItem?.additionalInfo?.resultDescription || ""}
            />
          )} */}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" className="modalButtons" onClick={() => handleClose()}>
            Close
          </Button>

          <Button variant="primary" className="modalButtons" onClick={validateInput}>
            {newItem.isNew ? "Create" : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TestDetailsModal;
