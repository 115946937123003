import React, { useEffect, useState, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { AppContext } from "context/app-context";

const CreateLabelOption = ({ handleClose, handleSaveChange, selectedValue }) => {
  const [option, setOption] = useState({ label: "", code: "" });

  const appContext = useContext(AppContext);

  useEffect(() => {
    if (selectedValue) {
      setOption(selectedValue);
    }
  }, [selectedValue]);

  const handleSave = () => {
   
    if (!option.code) {
      appContext.showErrorMessage("Enter valid code");

      return;
    }
    if (!option.label) {
      appContext.showErrorMessage("Enter the title");
      return;
    }
    handleSaveChange(option);
  };

  return (
    <Modal
      show
      animation={true}
      onHide={() => handleClose()}
      backdrop="static"
      centered
      size={"md"}
      keyboard={true}
      className="second-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Create Label
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="row mb-3">
          <div className="col-lg-12 col-12 mb-3">
            <div className="col-lg-12 col-12 mb-3">
              <label className="modalLineHeaders mt-0">Code:</label>
              <input
                value={option.code}
                className="modalInput"
                placeholder="Enter Code"
                style={{
                  width: "100%",
                }}
                maxLength={3}
                onChange={(e) => setOption({ ...option, code: e.target.value.toLocaleUpperCase() })}
              />
            </div>
            <label className="modalLineHeaders mt-0">Title:</label>
            <input
              value={option.label}
              className="modalInput"
              placeholder="Enter Title"
              style={{
                width: "100%",
              }}
              onChange={(e) => setOption({ ...option, label: e.target.value })}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="headerButton" onClick={() => handleClose()}>
          Close
        </Button>
        <Button variant="primary" className="modalButtons" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateLabelOption;
