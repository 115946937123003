import React from "react";

const TestInputField = ({ label, value, onChange, type = "text", name = "" }) => (
  <div>
    <p className="modalLineHeaders">{label}</p>
    <input
      className="modalInput"
      value={value}
      placeholder={label}
      style={{ width: "100%" }}
      type={type}
      name={name}
      onChange={onChange}
    />
  </div>
);

export default TestInputField;
