import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showConfirmationModal } from "./generalAction";
import CONSTANTS from "./generalConstant";
import api from "../../../api";

export const updateTestNames = createAsyncThunk(CONSTANTS.UPDATE_TEST_NAME, async (param, { dispatch }) => {
  const apiData = await api.updateTestNames(param);
  return {  };
});


const generalSlice = createSlice({
  name: CONSTANTS.GENERAL,
  initialState: {
    loader: false,
    error: null,
    testPrint: null,
    message: {
      text: "",
      mode: "",
      title: "",
    },
    confirmationModal: {
      showModal: false,
      message: "",
      title: "",
      onConfirm: null,
    },
    selectedDevice: false,
  },
  reducers: {
    setLoader: (state, action) => {
      state.loader = Boolean(action.payload);
    },
    setTestPrintView: (state, action) => {
      state.testPrint = action.payload;
    },
    setSelectedDevice: (state, action) => {
      state.selectedDevice = action.payload;
    },
    showMessage: (state, action) => {
      state.message = {
        text: action.payload?.text || "",
        mode: action.payload?.mode || "",
        title: action.payload?.title || "",
      };
    },
    setError: (state, action) => {
      state.error = action.payload || null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(showConfirmationModal.fulfilled, (state, action) => {
      state.confirmationModal = action.payload;
    });
  },
});

export const { setLoader, showMessage, setError, setTestPrintView, setSelectedDevice } = generalSlice.actions;

export default generalSlice.reducer;
