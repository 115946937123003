import React, { useEffect, useState, useCallback, useRef, memo, useContext } from "react";
import Loader from "components/Loader/Loader";
import MainTable from "components/Table/MainTable";
import Select from "react-select";
import api from "api";
import { Button, Modal } from "react-bootstrap";
import { PANEL_RESULT_TEST_PERSONALIZE } from "constants/personalization";
import { useDispatch, useSelector } from "react-redux";
import { resetStateResultState } from "store/features/resultsTests/resultsTestSlice";
import { parseTestUnit } from "utils";
import { customIsJsonString } from "util/customLodash";
import { RESULT_SETTINGS_OPT_QUALITATIVE } from "constant";
import { TYPE_OF_TEST } from "constant";
import { customIsObject } from "util/customLodash";
import { isJSONString, toTitleCase } from "utils";
import { generateRefInterval } from "utils";
import { AppContext } from "context/app-context";
import { v4 as uuidv4 } from "uuid";
import {
  keysPickForPanel,
  keysPickForWithouPathogenQualitative,
  keysPickForWithouPathogenQuantitative,
  keysPickForWithPathogenQuantitative,
} from "constants/personalization";
import { getSpecificResultPersonalize } from "utils";
import { TEST_PANEL_TYPES } from "constant";
import { locationsSelectors } from "store/features/locations/locationsSelectors";

const TestResultInput = memo(({ row, onUpdateTestResult }) => {
  const inputRef = useRef(null);
  const handleBlur = (event) => {
    onUpdateTestResult(event.target.value, row.uniqueId);
  };
  return (
    <td>
      {row?.typeOfTest === "Qualitative" ? (
        <Select
          className="w-100 siteSelector"
          options={RESULT_SETTINGS_OPT_QUALITATIVE}
          // options={resultTypes || RESULT_SETTINGS_OPT_QUALITATIVE}
          blurInputOnSelect={true}
          defaultValue={null}
          menuPlacement="auto"
          value={customIsJsonString(row?.testResult) && JSON.parse(row?.testResult)}
          placeholder="Select Result"
          onChange={(e) => onUpdateTestResult(e, row.uniqueId)}
        />
      ) : (
        <input
          className="w-100 modalInput"
          ref={inputRef}
          defaultValue={row?.testResult || ""}
          onBlur={handleBlur}
          onFocus={() => inputRef.current && inputRef.current.select()}
          key={row?.id}
          placeholder="Enter Result"
        />
      )}
    </td>
  );
});

const ResultMarkInput = memo(({ row, onUpdateResultMark }) => {
  const resultInputRef = useRef(null);

  const handleBlur = (event) => {
    onUpdateResultMark(event.target.value, row.uniqueId);
  };

  const isDisable = (testData) => {
    if (!testData.testResult) {
      return true;
    }
    if (isJSONString(testData.testResult)) {
      const { value } = JSON.parse(testData.testResult);
      return value !== "Detected";
    }
    return true;
  };

  return (
    <td>
      <input
        className="w-100 modalInput"
        ref={resultInputRef}
        defaultValue={row?.resultValue || ""}
        onBlur={handleBlur}
        onFocus={() => resultInputRef.current && resultInputRef.current.select()}
        key={row?.id}
        //disabled={row.typeOfTest === TYPE_OF_TEST.QUANTITATIVE || isDisable(row)}
        type="number"
        placeholder="CT Value"
      />
    </td>
  );
});

const PanelResultModal = ({ panelTests }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [resultsData, setResultsData] = useState([]);
 
  const labLocations = useSelector(locationsSelectors);

  const managePersonalization = (testData) => {
    if (testData.type === TEST_PANEL_TYPES.QUALITATIVE_WITH_PATHOGEN) {
      return getSpecificResultPersonalize(keysPickForPanel);
    }
    if (testData.type === TEST_PANEL_TYPES.QUANTITATIVE_WITH_PATHOGEN) {
      return getSpecificResultPersonalize(keysPickForWithPathogenQuantitative);
    }
    if (testData.type === TEST_PANEL_TYPES.QUALITATIVE_WITHOUT_PATHOGEN) {
      return getSpecificResultPersonalize(keysPickForWithouPathogenQualitative, false);
    }
    if (testData.type === TEST_PANEL_TYPES.QUANTITATIVE_WITHOUT_PATHOGEN) {
      return getSpecificResultPersonalize(keysPickForWithouPathogenQuantitative, false);
    } else {
      return PANEL_RESULT_TEST_PERSONALIZE;
    }
  };

  const appContext = useContext(AppContext);

  const dispatch = useDispatch();

  const addDefaultdResult = (list) => {
    return list.map((item) => {
      if (item.typeOfTest === "Qualitative") {
        return { ...item, testResult: JSON.stringify({ label: "Not Detected", value: "Not Detected" }) };
      }
      return item;
    });
  };

  const formatPanelTest = (panelData) => {
    const formatedData = [];
    formatedData.push({ panelName: panelData.panelName, showEmptyRow: true, uniqueId: uuidv4() });

    panelData?.tests?.forEach((item) => {
      if (
        item.type === TEST_PANEL_TYPES.QUALITATIVE_WITH_PATHOGEN ||
        item.type === TEST_PANEL_TYPES.QUANTITATIVE_WITH_PATHOGEN
      ) {
        formatedData.push({ testName: item.name, showEmptyRow: true, uniqueId: uuidv4() });
        formatedData.push(item.targetRanges.map((item) => ({ ...item, uniqueId: uuidv4() })));
      } else if (
        item.type === TEST_PANEL_TYPES.QUALITATIVE_WITHOUT_PATHOGEN ||
        item.type === TEST_PANEL_TYPES.QUANTITATIVE_WITHOUT_PATHOGEN
      ) {
        formatedData.push({
          testName: item.name,
          units: { label: item.units, value: item.units },
          typeOfTest: item.typeOfTest,
          id: item.id,
          uniqueId: uuidv4(),
          refInterval: item.refInterval,
        });
      }

      if (item.type === TEST_PANEL_TYPES.PANEL) {
        formatedData.push({ panelName: item.panelName, showEmptyRow: true, uniqueId: uuidv4() });

        item.targetRanges.map((subItem) => {
          if (
            subItem.type === TEST_PANEL_TYPES.QUALITATIVE_WITH_PATHOGEN ||
            subItem.type === TEST_PANEL_TYPES.QUANTITATIVE_WITH_PATHOGEN
          ) {
            formatedData.push({ testName: subItem.name, showEmptyRow: true, uniqueId: uuidv4() });
            formatedData.push(subItem.targetRanges.map((item) => ({ ...item, uniqueId: uuidv4() })));
          } else if (
            subItem.type === TEST_PANEL_TYPES.QUALITATIVE_WITHOUT_PATHOGEN ||
            subItem.type === TEST_PANEL_TYPES.QUANTITATIVE_WITHOUT_PATHOGEN
          ) {
            formatedData.push({
              testName: subItem.name,
              uniqueId: uuidv4(),
              units: { label: subItem.units, value: subItem.units },
              typeOfTest: subItem.typeOfTest,
              id: subItem.id,
            });
          }
        });
      }
    });

    setResultsData(addDefaultdResult(formatedData.flat()));
  };

  const formatTestWithoutPanel = (panelData) => {
    const { tests } = panelData;

    setResultsData(addDefaultdResult([tests]));
  };
  const formatMainTestWithPanel = (panelData) => {
    const { tests } = panelData;

    const formatedData = [];
    formatedData.push({ panelName: panelData.testName, showEmptyRow: true, uniqueId: uuidv4() });

    tests?.forEach((item) => {
      formatedData.push({ ...item, uniqueId: uuidv4() });
    });

    setResultsData(addDefaultdResult(formatedData));
  };

  useEffect(() => {
    if (panelTests?.hasOwnProperty("resultDetails")) {
      const parsedJson = JSON.parse(panelTests.resultDetails);
      setResultsData(parsedJson.map((item) => ({ ...item, uniqueId: uuidv4() })));
    } else if (panelTests.type === TEST_PANEL_TYPES.PANEL) {
      formatPanelTest(panelTests);
    } else if (
      panelTests.type === TEST_PANEL_TYPES.QUALITATIVE_WITHOUT_PATHOGEN ||
      panelTests.type === TEST_PANEL_TYPES.QUANTITATIVE_WITHOUT_PATHOGEN
    ) {
      formatTestWithoutPanel(panelTests);
    } else if (
      panelTests.type === TEST_PANEL_TYPES.QUALITATIVE_WITH_PATHOGEN ||
      panelTests.type === TEST_PANEL_TYPES.QUANTITATIVE_WITH_PATHOGEN
    ) {
      formatMainTestWithPanel(panelTests);
    }
  }, [panelTests]);

  const handleClose = () => {
    dispatch(resetStateResultState());
  };

  const handleUpdateTestResult = useCallback((value, id) => {
    setResultsData((currentResultsData) => {
      const index = currentResultsData.findIndex((item) => item.uniqueId === id);

      if (index !== -1) {
        const newData = [...currentResultsData];

        newData[index] = {
          ...newData[index],
          testResult: customIsObject(value) ? JSON.stringify(value) : value,
          // resultValue: value.value === "Detected" ? newData[index]?.resultValue : "",
        };

        return newData;
      }
      return currentResultsData;
    });
  }, []);

  const handleUpdateResultMark = useCallback((value, id) => {
    setResultsData((currentResultsData) => {
      const index = currentResultsData.findIndex((item) => item.uniqueId === id);

      if (index !== -1) {
        const newData = [...currentResultsData];
        newData[index] = { ...newData[index], resultValue: value };
        return newData;
      }
      return currentResultsData;
    });
  }, []);

  const getResultValue = () => {
    const isPathogenTest =
      panelTests.type === TEST_PANEL_TYPES.QUALITATIVE_WITH_PATHOGEN ||
      panelTests.type === TEST_PANEL_TYPES.QUANTITATIVE_WITH_PATHOGEN;

    if (isPathogenTest) {
      return "Details";
    }

    const resultValue = resultsData?.[0]?.testResult || null;

    return customIsJsonString(resultValue) ? JSON.parse(resultValue).value : "Details";
  };

  const handleSaveResult = async () => {
    const isAllResultMarked = resultsData.filter((r) => !r.showEmptyRow).every((t) => t.testResult);

    if (!isAllResultMarked) {
      appContext.showErrorMessage(`Please marked the complete result`);
      return;
    }

    // Get From App Context
    const companyClient = appContext.clients[0];

    const clientAutoRelease = !!companyClient?.setting?.autoRelease;

    // Find From LabClinet
    const show = labLocations.find((item) => item.id === panelTests.siteID);

    const showAutoRelease = !!show?.setting?.autoRelease;

    setIsLoading(true);
    try {
      const updatedObj = {
        id: panelTests.id,
        resultDetails: resultsData,
        isAssociatedPanel: panelTests.isAssociatedPanel,
        type: panelTests.type,
        typeOfTest: panelTests.typeOfTest,
        result: getResultValue(),
        clientAutoRelease,
        showAutoRelease,
      };

      await api.assingTestResult(updatedObj);

      handleClose();

      appContext.showSuccessMessage(`Result marked.`);
    } catch (error) {
      console.log("error", error);
      appContext.showErrorMessage(`Error in result marking.`);
    }
  };

  const customRenderTD = (item, row, index) => {
    if (item.itemKey === "testResult") {
      return row.showEmptyRow ? (
        <td key={`empty_${item.id}`} style={{ textAlign: "center" }}></td>
      ) : (
        <TestResultInput row={row} onUpdateTestResult={handleUpdateTestResult} />
      );
    }

    if (item.itemKey === "unit") {
      return (
        <td key={`unit_${item.id}`} style={{ textAlign: "center" }}>
          {parseTestUnit(row?.units)}
        </td>
      );
    }
    if (item.itemKey === "panelName") {
      return (
        <td
          key={`unit_${item.id}`}
          style={{
            fontWeight: row?.testName ? "600" : "700",
            paddingLeft: row?.testName ? "30px" : "10px",
          }}
        >
          {toTitleCase(row?.panelName || row?.testName || "")}
        </td>
      );
    }

    if (item.itemKey === "testResultMarks") {
      return row.showEmptyRow || row.typeOfTest !== TYPE_OF_TEST.QUALITATIVE ? (
        <td key={`empty_${item.id}`} style={{ textAlign: "center" }}></td>
      ) : (
        <ResultMarkInput row={row} onUpdateResultMark={handleUpdateResultMark} />
      );
    }
  };

  return (
    <Modal show animation={true} onHide={handleClose} style={{ paddingLeft: "0" }} centered size="3xl">
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Result for - {panelTests?.barcode}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          paddingTop: 0,
        }}
      >
        {isLoading && <Loader />}

        <div style={{ background: "#f2f0f0", paddingLeft: "4px", paddingRight: "4px" }}>
          <MainTable
            cssClass="table-noresponsive"
            trDataWrapperHeader={"trDataWrapperHeader"}
            customColumnCellRenderer={customRenderTD}
            columns={managePersonalization(panelTests)}
            individualRowCssClass={(row) =>
              customIsJsonString(row?.testResult) && JSON.parse(row?.testResult).label === "Detected"
                ? "trDataWrapper isPositiveResult"
                : "trDataWrapper"
            }
            rows={resultsData}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button style={{ marginBottom: 10 }} variant="secondary" className="modalButtons" onClick={handleClose}>
          Close
        </Button>
        <Button style={{ marginBottom: 10 }} variant="primary" className="modalButtons" onClick={handleSaveResult}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PanelResultModal;
