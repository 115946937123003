import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CONFIG } from "constant";
import api from "../../../api";
import {
  emailMsgToCreatedMember,
  formatEmployeesData,
  formatTimeZone,
  patientDataInLocalStorage,
} from "../../../utils";
import {
  EMPLOYEE,
  EMPLOYEE_CREATE,
  EMPLOYEE_DELETE,
  EMPLOYEE_FETCH,
  EMPLOYEE_FETCH_RECORD,
  EMPLPLOYEE_UPDATE,
} from "./employeesConstant";
import { setMessage } from "../general/generalAction";
import { t } from "stringConstants";
import { MESSAGE_MODES, CUSTOM_INS } from "constant";
import { EmptyUserObj } from "constant";

const nestedFilter = (targetArray, filters) => {
  if (Object.keys(filters).length === 0) return targetArray;
  const filterKeys = Object.keys(filters);
  //filters main array of objects
  const models = targetArray.filter((obj) => {
    //goes through each key being filtered for
    return filterKeys.every((key) => {
      if (!filters[key].length && !Object.keys(filters[key]).length) {
        return true;
      }
      if (key === "updatedAt" || key === "createdAt") {
        return new Date(obj[key]) > filters[key].startDate._d && new Date(obj[key]) < filters[key].endDate._d;
      }
      if (key === "First Name") {
        return obj.firstName && obj.firstName.toLowerCase().includes(filters[key].toLowerCase());
      }

      if (key === "Last Name") {
        return obj.lastName && obj.lastName.toLowerCase().includes(filters[key].toLowerCase());
      }

      if (key === "scheduleID") {
        return obj.scheduleID && (obj.scheduleID === filters[key] || obj.scheduleLinked.indexOf(filters[key]) !== -1);
      }
      if (key === "department") {
        return obj.department && obj.department === filters[key];
      }
      if (key === "isSchedule") {
        return (obj.isSchedule ? obj.isSchedule : 0) == filters[key];
      }
      if (key === "isVaccinated") {
        return (obj.isVaccinated ? 1 : 0).toString() === filter[key];
      }
      if (key === "qaDone") {
        return obj.qaDone === filters[key];
      }
      if (key === "autoShipment") {
        return obj.autoShipment == filters[key];
      }
      if (key === "testDone") {
        return obj.testDone === filter[key];
      }
      if (key === "note") {
        if (filters[key] === "1" && obj.note) return true;
        if (filters[key] === "0") return true;
      }
      if (key === "eligibilityStatus") {
        const val = obj[key]?.message === "Eligibile" ? "eligibile" : obj[key] ? "invalid" : "not checked";
        return val && val.toString() === filters[key];
      }
      if (key === "testOrdered" || key === "testAvailable") {
        return obj[key].toString() === filters[key];
      }
      if (key === "empTZ") {
        return formatTimeZone(obj[key]).toLowerCase().includes(filters[key].toLowerCase());
      }
      if (key === "checkIn") {
        return obj.checkIn === filters[key];
        // return new Date(obj.checkIn) > filters[key].startDate._d && new Date(obj.checkIn) < filters[key].endDate._d;
      }
      if (key === "payerId") {
        return filters[key].includes(obj.insuranceCompany);
      }
      if (key === "secondaryInsurance") {
        return filters[key].includes(obj.secondaryInsurance);
      }
      if ((key === "programName" || key === "zoneColor") && filters[key] && filters[key].length > 0) {
        return obj[key] && filters[key].indexOf(obj[key]) !== -1;
      }
      if (key === "status") {
        return filters[key] === obj.status;
      }
      if (key === "Blank" && filters[key].length > 0) {
        return filters[key].some((f) => !obj[PARSE_FILTER_KEY_NAME[f]]);
      }
      return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
    });
  });
  return models;
};

// Async thunk to fetch all employees
export const fetchAllEmployees = createAsyncThunk(EMPLOYEE_FETCH, async (_, { getState }) => {
  const response = await api.getEmployees();

  const [subAgents, locations, clients, providers] = [
    getState().subAgents.subAgents,
    getState().locations.locations,
    getState().clients.clients,
    getState().providers.providers,
  ];

  const emps = formatEmployeesData(response, subAgents || [], locations || [], clients || [], providers || []);
  return emps;
});
// External email Send Function

const sendEmailToMember = async (newUser) => {
  try {
    const message = emailMsgToCreatedMember(newUser.firstName, newUser.medicalNo, newUser.password);

    await api.sendEmail([{ email: newUser.email, subject: "SafeCamp LTC Team", msg: message }]);
  } catch (error) {
    console.log("ERROR createUser: ", error);
  }
};

// Async thunk to create a employee
export const createEmployeeAsync = createAsyncThunk(EMPLOYEE_CREATE, async (employee, { getState, dispatch }) => {
  const user = getState().auth.user;
  const documents = getState().documents.documents;
  const result = await api.newEmployee(employee, null, user);

  // Create Patient in LIS
  if (result.res) {
    await api.newPatientCreateAPI(result.res);
  }
  // Check Manual Eligibility on create Patient
  if (result.res && !employee.eEligibility && !CUSTOM_INS.includes(result.res.insuranceCompany)) {
    await api.checkManualBulkEligibility([result.res], user);
  }

  // add Logs for Eligibility
  if (result.res && employee.eligibilityID) {
    await api.updateLogs({ employeeID: result.res.id, id: employee.eligibilityID });
  }
  if (documents.length > 0) {
    const newDocs = documents.filter((f) => !f.employeeID);
    if (newDocs.length > 0) {
      const modifyDocs = newDocs.map((m) => ({ ...m, employeeID: result.res.id }));
      if (modifyDocs && modifyDocs.length > 0) await api.newDocumentCreateAPI(modifyDocs);
    }
  }

  const [subAgents, locations, clients, providers] = [
    getState().subAgents.subAgents,
    getState().locations.locations,
    getState().clients.clients,
    getState().providers.providers,
  ];

  // Format Employees Data
  const emps = formatEmployeesData([result.res], subAgents || [], locations || [], clients || [], providers || []);
  dispatch(setMessage(t("clientCreatedSuccessfully"), MESSAGE_MODES.success));
  return emps[0];
});

//Async thunk to Fetch user employee
export const fetchEmployeeRecord = createAsyncThunk(EMPLOYEE_FETCH_RECORD, async (user) => {
  const item = await api.getLoggedInUserEmployeeID(null, user.preferred_username);

  return item;
});

// Async thunk to update a employee
export const updateEmployeeAsync = createAsyncThunk(EMPLPLOYEE_UPDATE, async (employee, { getState, dispatch }) => {
  const user = getState().auth.user;
  const response = await api.updateEmployee(employee, user);

  if (response.res) {
    await api.patientUpdateAPI(response.res);
  }

  const [subAgents, locations, clients, providers] = [
    getState().subAgents.subAgents,
    getState().locations.locations,
    getState().clients.clients,
    getState().providers.providers,
  ];
  const emps = formatEmployeesData([response.res], subAgents || [], locations || [], clients || [], providers || []);

  if (!employee.onAlert) dispatch(setMessage(t("clientUpdatedSuccessfully"), MESSAGE_MODES.success));

  return emps[0];
});

// Async thunk to delete a employee
export const deleteEmployeeAsync = createAsyncThunk(EMPLOYEE_DELETE, async (employeeId, { getState }) => {
  const user = getState().auth.user;
  const employees = getState().employees.employees;
  const emp = employees.find((f) => f.id === employeeId);
  const response = await api.deleteEmployee(employeeId, user);
  if (emp.loginID && !CONFIG.isLabType) await api.deleteUser(emp.loginID);
  return employeeId;
});

const employeeSlice = createSlice({
  name: EMPLOYEE,
  initialState: {
    employees: [],
    filteredEmployees: [],
    proxyRelations: [],
    employeesFilter: {},
    employeeRecord: null,
    openCreator: false,
    newUser: EmptyUserObj,
  },
  reducers: {
    setFilteredEmployees: (state, action) => {
      state.filteredEmployees = action.payload;
    },
    setProxyRelations: (state, action) => {
      state.proxyRelations = action.payload;
    },
    setOpenCreator: (state, action) => {
      state.openCreator = action.payload;
    },
    setNewUser: (state, action) => {
      state.newUser = action.payload;
    },
    setEmployeesFilter: (state, action) => {
      state.employeesFilter = action.payload;
    },
    updateTestAvailableQty: (state, action) => {
      const updateEmps = state.employees.map((m) => {
        if (action.payload.Ids.includes(m.id)) {
          return { ...m, testAvailable: action.payload.testQty };
        }
        return { ...m };
      });
      const filterUpdateEmps = state.filteredEmployees.map((m) => {
        if (action.payload.Ids.includes(m.id)) {
          return { ...m, testAvailable: action.payload.testQty };
        }
        return { ...m };
      });
      state.employees = updateEmps;
      state.filteredEmployees = filterUpdateEmps;
    },
    updateNotes: (state, action) => {
      const data = action.payload;
      const emp = state.employees.find((f) => f.id === data.id);
      emp["note"] = data.note;
      emp.status = data.status;
      if (data.status === "inactive") {
        emp.testAvailable = 0;
      }
      emp.noteAddedBy = data.userName;
      emp.updatedBy = data.userID;
      emp.updatedByName = data.userName;

      const existingemployeeIndex = state.employees.findIndex((employee) => employee.id === emp.id);
      const existingFilteremployeeIndex = state.filteredEmployees.findIndex((employee) => employee.id === emp.id);
      if (existingemployeeIndex !== -1) {
        state.employees[existingemployeeIndex] = emp;
      }
      if (existingFilteremployeeIndex !== -1) {
        state.filteredEmployees[existingFilteremployeeIndex] = emp;
      }
    },
    updateEmployeeFromClaim: (state, action) => {
      const updatedemployee = action.payload;
      const existingemployeeIndex = state.employees.findIndex((employee) => employee.id === updatedemployee?.id);
      const existingFilteremployeeIndex = state.filteredEmployees.findIndex(
        (employee) => employee.id === updatedemployee?.id
      );
      if (existingemployeeIndex !== -1) {
        state.employees[existingemployeeIndex] = updatedemployee;
      }
      if (existingFilteremployeeIndex !== -1) {
        state.filteredEmployees[existingFilteremployeeIndex] = updatedemployee;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllEmployees.fulfilled, (state, action) => {
        state.employees = action.payload;
        state.filteredEmployees = nestedFilter(action.payload, state.employeesFilter);
      })
      .addCase(createEmployeeAsync.fulfilled, (state, action) => {
        const filteredEmployees = [...state.filteredEmployees];
        state.employees.unshift(action.payload);
        filteredEmployees.unshift(action.payload);
        state.filteredEmployees = nestedFilter(filteredEmployees, state.employeesFilter);
      })
      .addCase(updateEmployeeAsync.fulfilled, (state, action) => {
        const updatedemployee = action.payload;
        const existingemployeeIndex = state.employees.findIndex((employee) => employee.id === updatedemployee.id);
        const existingFilteremployeeIndex = state.filteredEmployees.findIndex(
          (employee) => employee.id === updatedemployee.id
        );
        if (existingemployeeIndex !== -1) {
          state.employees[existingemployeeIndex] = updatedemployee;
        }
        if (existingFilteremployeeIndex !== -1) {
          state.filteredEmployees[existingFilteremployeeIndex] = updatedemployee;
        }
        state.filteredEmployees = nestedFilter(state.filteredEmployees, state.employeesFilter);
      })
      .addCase(deleteEmployeeAsync.fulfilled, (state, action) => {
        const deletedemployeeId = action.payload;
        state.employees = state.employees.filter((employee) => employee.id !== deletedemployeeId);
        state.filteredEmployees = state.filteredEmployees.filter((emp) => emp.id !== deletedemployeeId);
      })
      .addCase(fetchEmployeeRecord.fulfilled, (state, action) => {
        patientDataInLocalStorage.save(action.payload);
        state.employeeRecord = action.payload;
      });
  },
});

export const {
  setFilteredEmployees,
  updateNotes,
  updateTestAvailableQty,
  setOpenCreator,
  setNewUser,
  setEmployeesFilter,
  updateEmployeeFromClaim,
  setProxyRelations,
} = employeeSlice.actions;

export default employeeSlice.reducer;
