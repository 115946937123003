/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createLab = /* GraphQL */ `
  mutation CreateLab(
    $input: CreateLabInput!
    $condition: ModelLabConditionInput
  ) {
    createLab(input: $input, condition: $condition) {
      id
      name
      logo
      contact_name
      contact_phone
      phone_number
      fax_number
      user_id
      contact_email
      tubes_provided
      lab_director
      country
      referredDoctor
      clia_number
      testing_notes
      street
      city_state_zip
      lab_contacts
      default_antigen
      antigen_notes
      default_molecular
      default_other
      default_rapidflu
      default_lucira
      molecular_notes
      custom_notes
      invalid_notes
      indeterminate_notes
      other_notes
      lucira_notes
      rapidflu_notes
      sendInsurance
      barCodeProvided
      barCodeAlertLimit
      whiteLabel
      whiteLabelPackage
      showOnSummaryScreen
      orderCodes
      isArchive
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      pendingRelease
      logourl
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseStatus
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateLab = /* GraphQL */ `
  mutation UpdateLab(
    $input: UpdateLabInput!
    $condition: ModelLabConditionInput
  ) {
    updateLab(input: $input, condition: $condition) {
      id
      name
      logo
      contact_name
      contact_phone
      phone_number
      fax_number
      user_id
      contact_email
      tubes_provided
      lab_director
      country
      referredDoctor
      clia_number
      testing_notes
      street
      city_state_zip
      lab_contacts
      default_antigen
      antigen_notes
      default_molecular
      default_other
      default_rapidflu
      default_lucira
      molecular_notes
      custom_notes
      invalid_notes
      indeterminate_notes
      other_notes
      lucira_notes
      rapidflu_notes
      sendInsurance
      barCodeProvided
      barCodeAlertLimit
      whiteLabel
      whiteLabelPackage
      showOnSummaryScreen
      orderCodes
      isArchive
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      pendingRelease
      logourl
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseStatus
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteLab = /* GraphQL */ `
  mutation DeleteLab(
    $input: DeleteLabInput!
    $condition: ModelLabConditionInput
  ) {
    deleteLab(input: $input, condition: $condition) {
      id
      name
      logo
      contact_name
      contact_phone
      phone_number
      fax_number
      user_id
      contact_email
      tubes_provided
      lab_director
      country
      referredDoctor
      clia_number
      testing_notes
      street
      city_state_zip
      lab_contacts
      default_antigen
      antigen_notes
      default_molecular
      default_other
      default_rapidflu
      default_lucira
      molecular_notes
      custom_notes
      invalid_notes
      indeterminate_notes
      other_notes
      lucira_notes
      rapidflu_notes
      sendInsurance
      barCodeProvided
      barCodeAlertLimit
      whiteLabel
      whiteLabelPackage
      showOnSummaryScreen
      orderCodes
      isArchive
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      pendingRelease
      logourl
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseStatus
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createSite = /* GraphQL */ `
  mutation CreateSite(
    $input: CreateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    createSite(input: $input, condition: $condition) {
      id
      name
      clientID
      contact_name
      contactName
      contact_phone
      phone_number
      email
      contact_email
      safeCampAdmin
      antigen
      automateTestType
      user_id
      show_contacts
      insurance_required
      sendInsuranceCard
      isInsurance
      resetTestDay
      startDate
      endDate
      orderKitDate
      orderKitType
      orderQuantity
      enableShipment
      showOnSummaryScreen
      messageTested
      messageResulted
      messagePositive
      messageReTest
      printLabel
      isArchive
      isActive
      isLucira
      isPCRCustody
      vaccinationCard
      externalTest
      externalTestType
      externalTestDuration
      externalTestAdmit
      patternTesting
      isHRPR
      patternHippa
      preRegistration
      admit
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      totalPR
      totalOrdered
      totalExtTest
      totalCheckIn
      totalGuest
      totalStaff
      status
      companyID
      sendIdentification
      isAutoSubmitClaim
      sendExternalRequisition
      sendPrescription
      sendAuthorization
      sendOtherDocuments
      startBarCode
      uploadFileStatus
      hippa
      hippaFile
      resultTemplate
      isTesting
      street
      city
      state
      zipcode
      countryCode
      country
      setting
      defaultProvider
      practiceID
      labLocalID
      fax
      contact_fax
      discount
      commission
      totalOrders
      totalBilledAmount
      claimAmount
      openAmount
      paid
      lastOrder
      note
      createdBy
      createdByName
      updatedByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseStatus
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateSite = /* GraphQL */ `
  mutation UpdateSite(
    $input: UpdateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    updateSite(input: $input, condition: $condition) {
      id
      name
      clientID
      contact_name
      contactName
      contact_phone
      phone_number
      email
      contact_email
      safeCampAdmin
      antigen
      automateTestType
      user_id
      show_contacts
      insurance_required
      sendInsuranceCard
      isInsurance
      resetTestDay
      startDate
      endDate
      orderKitDate
      orderKitType
      orderQuantity
      enableShipment
      showOnSummaryScreen
      messageTested
      messageResulted
      messagePositive
      messageReTest
      printLabel
      isArchive
      isActive
      isLucira
      isPCRCustody
      vaccinationCard
      externalTest
      externalTestType
      externalTestDuration
      externalTestAdmit
      patternTesting
      isHRPR
      patternHippa
      preRegistration
      admit
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      totalPR
      totalOrdered
      totalExtTest
      totalCheckIn
      totalGuest
      totalStaff
      status
      companyID
      sendIdentification
      isAutoSubmitClaim
      sendExternalRequisition
      sendPrescription
      sendAuthorization
      sendOtherDocuments
      startBarCode
      uploadFileStatus
      hippa
      hippaFile
      resultTemplate
      isTesting
      street
      city
      state
      zipcode
      countryCode
      country
      setting
      defaultProvider
      practiceID
      labLocalID
      fax
      contact_fax
      discount
      commission
      totalOrders
      totalBilledAmount
      claimAmount
      openAmount
      paid
      lastOrder
      note
      createdBy
      createdByName
      updatedByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseStatus
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteSite = /* GraphQL */ `
  mutation DeleteSite(
    $input: DeleteSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    deleteSite(input: $input, condition: $condition) {
      id
      name
      clientID
      contact_name
      contactName
      contact_phone
      phone_number
      email
      contact_email
      safeCampAdmin
      antigen
      automateTestType
      user_id
      show_contacts
      insurance_required
      sendInsuranceCard
      isInsurance
      resetTestDay
      startDate
      endDate
      orderKitDate
      orderKitType
      orderQuantity
      enableShipment
      showOnSummaryScreen
      messageTested
      messageResulted
      messagePositive
      messageReTest
      printLabel
      isArchive
      isActive
      isLucira
      isPCRCustody
      vaccinationCard
      externalTest
      externalTestType
      externalTestDuration
      externalTestAdmit
      patternTesting
      isHRPR
      patternHippa
      preRegistration
      admit
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      totalPR
      totalOrdered
      totalExtTest
      totalCheckIn
      totalGuest
      totalStaff
      status
      companyID
      sendIdentification
      isAutoSubmitClaim
      sendExternalRequisition
      sendPrescription
      sendAuthorization
      sendOtherDocuments
      startBarCode
      uploadFileStatus
      hippa
      hippaFile
      resultTemplate
      isTesting
      street
      city
      state
      zipcode
      countryCode
      country
      setting
      defaultProvider
      practiceID
      labLocalID
      fax
      contact_fax
      discount
      commission
      totalOrders
      totalBilledAmount
      claimAmount
      openAmount
      paid
      lastOrder
      note
      createdBy
      createdByName
      updatedByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseStatus
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      name
      contact_name
      contact_phone
      phone_number
      country
      timezone
      fax_number
      street
      city
      state
      zipcode
      contact_email
      hippa
      hippaFile
      resultType
      showOnSummaryScreen
      whiteLabel
      whiteLabelPackage
      isArchive
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      resultTypes
      brmSetting
      setting
      pgName
      colLabId
      uploadFileStatus
      orderCounter
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Sites {
        items {
          id
          name
          clientID
          contact_name
          contactName
          contact_phone
          phone_number
          email
          contact_email
          safeCampAdmin
          antigen
          automateTestType
          user_id
          show_contacts
          insurance_required
          sendInsuranceCard
          isInsurance
          resetTestDay
          startDate
          endDate
          orderKitDate
          orderKitType
          orderQuantity
          enableShipment
          showOnSummaryScreen
          messageTested
          messageResulted
          messagePositive
          messageReTest
          printLabel
          isArchive
          isActive
          isLucira
          isPCRCustody
          vaccinationCard
          externalTest
          externalTestType
          externalTestDuration
          externalTestAdmit
          patternTesting
          isHRPR
          patternHippa
          preRegistration
          admit
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          totalPR
          totalOrdered
          totalExtTest
          totalCheckIn
          totalGuest
          totalStaff
          status
          companyID
          sendIdentification
          isAutoSubmitClaim
          sendExternalRequisition
          sendPrescription
          sendAuthorization
          sendOtherDocuments
          startBarCode
          uploadFileStatus
          hippa
          hippaFile
          resultTemplate
          isTesting
          street
          city
          state
          zipcode
          countryCode
          country
          setting
          defaultProvider
          practiceID
          labLocalID
          fax
          contact_fax
          discount
          commission
          totalOrders
          totalBilledAmount
          claimAmount
          openAmount
          paid
          lastOrder
          note
          createdBy
          createdByName
          updatedByName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Employees {
        items {
          id
          first
          last
          middleName
          dob
          id_number
          clientID
          employeeFND
          street
          street2
          city
          state
          county
          zip
          country
          countryCode
          phone_number
          sex
          email
          isVaccinated
          insurance_name
          insurance_number
          insuranceGroupId
          insurance_img_front
          insurance_img_back
          subID
          site_name
          isNew
          isPreRegistered
          medflowDomain
          mdID
          schrID
          whiteGlove
          employeeType
          profileImage
          vaccinationCardUpload
          vaccinationCardImage
          vaccinationDetails
          vaccinationType
          vaccinationDate
          vaccinationLocation
          vaccinationDateS
          vaccinationLocationS
          isBooster
          BoosterShotCardImage
          isExternalTest
          externalTestImage
          externalTestDate
          externalTestType
          externalTestResult
          preRegisterShowId
          patternConsent
          hippaConsent
          insuranceScan
          createdAt
          updatedBy
          updatedByName
          lastUpdatedTest
          autoRelease
          lastUsed
          isPrimaryProfile
          parentRelation
          note
          sendSMS
          sendEmail
          totalTest
          connectedTo
          createdBy
          createdByName
          insuranceDetails
          insuranceCompanyCode
          medicalNo
          postGridID
          noteAddedBy
          status
          isHospice
          snf
          isInsHousing
          eligibilityStatus
          requestedDetails
          primaryCarePhysician
          referringPyhsician
          renderingProvider
          referringProvider
          orderingProvider
          codes
          testTwo
          siteID
          setting
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseStatus
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      name
      contact_name
      contact_phone
      phone_number
      country
      timezone
      fax_number
      street
      city
      state
      zipcode
      contact_email
      hippa
      hippaFile
      resultType
      showOnSummaryScreen
      whiteLabel
      whiteLabelPackage
      isArchive
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      resultTypes
      brmSetting
      setting
      pgName
      colLabId
      uploadFileStatus
      orderCounter
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Sites {
        items {
          id
          name
          clientID
          contact_name
          contactName
          contact_phone
          phone_number
          email
          contact_email
          safeCampAdmin
          antigen
          automateTestType
          user_id
          show_contacts
          insurance_required
          sendInsuranceCard
          isInsurance
          resetTestDay
          startDate
          endDate
          orderKitDate
          orderKitType
          orderQuantity
          enableShipment
          showOnSummaryScreen
          messageTested
          messageResulted
          messagePositive
          messageReTest
          printLabel
          isArchive
          isActive
          isLucira
          isPCRCustody
          vaccinationCard
          externalTest
          externalTestType
          externalTestDuration
          externalTestAdmit
          patternTesting
          isHRPR
          patternHippa
          preRegistration
          admit
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          totalPR
          totalOrdered
          totalExtTest
          totalCheckIn
          totalGuest
          totalStaff
          status
          companyID
          sendIdentification
          isAutoSubmitClaim
          sendExternalRequisition
          sendPrescription
          sendAuthorization
          sendOtherDocuments
          startBarCode
          uploadFileStatus
          hippa
          hippaFile
          resultTemplate
          isTesting
          street
          city
          state
          zipcode
          countryCode
          country
          setting
          defaultProvider
          practiceID
          labLocalID
          fax
          contact_fax
          discount
          commission
          totalOrders
          totalBilledAmount
          claimAmount
          openAmount
          paid
          lastOrder
          note
          createdBy
          createdByName
          updatedByName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Employees {
        items {
          id
          first
          last
          middleName
          dob
          id_number
          clientID
          employeeFND
          street
          street2
          city
          state
          county
          zip
          country
          countryCode
          phone_number
          sex
          email
          isVaccinated
          insurance_name
          insurance_number
          insuranceGroupId
          insurance_img_front
          insurance_img_back
          subID
          site_name
          isNew
          isPreRegistered
          medflowDomain
          mdID
          schrID
          whiteGlove
          employeeType
          profileImage
          vaccinationCardUpload
          vaccinationCardImage
          vaccinationDetails
          vaccinationType
          vaccinationDate
          vaccinationLocation
          vaccinationDateS
          vaccinationLocationS
          isBooster
          BoosterShotCardImage
          isExternalTest
          externalTestImage
          externalTestDate
          externalTestType
          externalTestResult
          preRegisterShowId
          patternConsent
          hippaConsent
          insuranceScan
          createdAt
          updatedBy
          updatedByName
          lastUpdatedTest
          autoRelease
          lastUsed
          isPrimaryProfile
          parentRelation
          note
          sendSMS
          sendEmail
          totalTest
          connectedTo
          createdBy
          createdByName
          insuranceDetails
          insuranceCompanyCode
          medicalNo
          postGridID
          noteAddedBy
          status
          isHospice
          snf
          isInsHousing
          eligibilityStatus
          requestedDetails
          primaryCarePhysician
          referringPyhsician
          renderingProvider
          referringProvider
          orderingProvider
          codes
          testTwo
          siteID
          setting
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseStatus
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      name
      contact_name
      contact_phone
      phone_number
      country
      timezone
      fax_number
      street
      city
      state
      zipcode
      contact_email
      hippa
      hippaFile
      resultType
      showOnSummaryScreen
      whiteLabel
      whiteLabelPackage
      isArchive
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      resultTypes
      brmSetting
      setting
      pgName
      colLabId
      uploadFileStatus
      orderCounter
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Sites {
        items {
          id
          name
          clientID
          contact_name
          contactName
          contact_phone
          phone_number
          email
          contact_email
          safeCampAdmin
          antigen
          automateTestType
          user_id
          show_contacts
          insurance_required
          sendInsuranceCard
          isInsurance
          resetTestDay
          startDate
          endDate
          orderKitDate
          orderKitType
          orderQuantity
          enableShipment
          showOnSummaryScreen
          messageTested
          messageResulted
          messagePositive
          messageReTest
          printLabel
          isArchive
          isActive
          isLucira
          isPCRCustody
          vaccinationCard
          externalTest
          externalTestType
          externalTestDuration
          externalTestAdmit
          patternTesting
          isHRPR
          patternHippa
          preRegistration
          admit
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          totalPR
          totalOrdered
          totalExtTest
          totalCheckIn
          totalGuest
          totalStaff
          status
          companyID
          sendIdentification
          isAutoSubmitClaim
          sendExternalRequisition
          sendPrescription
          sendAuthorization
          sendOtherDocuments
          startBarCode
          uploadFileStatus
          hippa
          hippaFile
          resultTemplate
          isTesting
          street
          city
          state
          zipcode
          countryCode
          country
          setting
          defaultProvider
          practiceID
          labLocalID
          fax
          contact_fax
          discount
          commission
          totalOrders
          totalBilledAmount
          claimAmount
          openAmount
          paid
          lastOrder
          note
          createdBy
          createdByName
          updatedByName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Employees {
        items {
          id
          first
          last
          middleName
          dob
          id_number
          clientID
          employeeFND
          street
          street2
          city
          state
          county
          zip
          country
          countryCode
          phone_number
          sex
          email
          isVaccinated
          insurance_name
          insurance_number
          insuranceGroupId
          insurance_img_front
          insurance_img_back
          subID
          site_name
          isNew
          isPreRegistered
          medflowDomain
          mdID
          schrID
          whiteGlove
          employeeType
          profileImage
          vaccinationCardUpload
          vaccinationCardImage
          vaccinationDetails
          vaccinationType
          vaccinationDate
          vaccinationLocation
          vaccinationDateS
          vaccinationLocationS
          isBooster
          BoosterShotCardImage
          isExternalTest
          externalTestImage
          externalTestDate
          externalTestType
          externalTestResult
          preRegisterShowId
          patternConsent
          hippaConsent
          insuranceScan
          createdAt
          updatedBy
          updatedByName
          lastUpdatedTest
          autoRelease
          lastUsed
          isPrimaryProfile
          parentRelation
          note
          sendSMS
          sendEmail
          totalTest
          connectedTo
          createdBy
          createdByName
          insuranceDetails
          insuranceCompanyCode
          medicalNo
          postGridID
          noteAddedBy
          status
          isHospice
          snf
          isInsHousing
          eligibilityStatus
          requestedDetails
          primaryCarePhysician
          referringPyhsician
          renderingProvider
          referringProvider
          orderingProvider
          codes
          testTwo
          siteID
          setting
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseStatus
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createEmployee = /* GraphQL */ `
  mutation CreateEmployee(
    $input: CreateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    createEmployee(input: $input, condition: $condition) {
      id
      first
      last
      middleName
      dob
      id_number
      clientID
      employeeFND
      street
      street2
      city
      state
      county
      zip
      country
      countryCode
      phone_number
      sex
      email
      isVaccinated
      insurance_name
      insurance_number
      insuranceGroupId
      insurance_img_front
      insurance_img_back
      subID
      site_name
      isNew
      isPreRegistered
      medflowDomain
      mdID
      schrID
      whiteGlove
      employeeType
      profileImage
      vaccinationCardUpload
      vaccinationCardImage
      vaccinationDetails
      vaccinationType
      vaccinationDate
      vaccinationLocation
      vaccinationDateS
      vaccinationLocationS
      isBooster
      BoosterShotCardImage
      isExternalTest
      externalTestImage
      externalTestDate
      externalTestType
      externalTestResult
      preRegisterShowId
      patternConsent
      hippaConsent
      insuranceScan
      createdAt
      updatedBy
      updatedByName
      lastUpdatedTest
      autoRelease
      lastUsed
      isPrimaryProfile
      parentRelation
      note
      sendSMS
      sendEmail
      totalTest
      connectedTo
      createdBy
      createdByName
      insuranceDetails
      insuranceCompanyCode
      medicalNo
      postGridID
      noteAddedBy
      status
      isHospice
      snf
      isInsHousing
      eligibilityStatus
      requestedDetails
      primaryCarePhysician
      referringPyhsician
      renderingProvider
      referringProvider
      orderingProvider
      codes
      testTwo
      siteID
      setting
      _version
      _deleted
      _lastChangedAt
      updatedAt
      Client {
        id
        name
        contact_name
        contact_phone
        phone_number
        country
        timezone
        fax_number
        street
        city
        state
        zipcode
        contact_email
        hippa
        hippaFile
        resultType
        showOnSummaryScreen
        whiteLabel
        whiteLabelPackage
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        resultTypes
        brmSetting
        setting
        pgName
        colLabId
        uploadFileStatus
        orderCounter
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Sites {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseStatus
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateEmployee = /* GraphQL */ `
  mutation UpdateEmployee(
    $input: UpdateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    updateEmployee(input: $input, condition: $condition) {
      id
      first
      last
      middleName
      dob
      id_number
      clientID
      employeeFND
      street
      street2
      city
      state
      county
      zip
      country
      countryCode
      phone_number
      sex
      email
      isVaccinated
      insurance_name
      insurance_number
      insuranceGroupId
      insurance_img_front
      insurance_img_back
      subID
      site_name
      isNew
      isPreRegistered
      medflowDomain
      mdID
      schrID
      whiteGlove
      employeeType
      profileImage
      vaccinationCardUpload
      vaccinationCardImage
      vaccinationDetails
      vaccinationType
      vaccinationDate
      vaccinationLocation
      vaccinationDateS
      vaccinationLocationS
      isBooster
      BoosterShotCardImage
      isExternalTest
      externalTestImage
      externalTestDate
      externalTestType
      externalTestResult
      preRegisterShowId
      patternConsent
      hippaConsent
      insuranceScan
      createdAt
      updatedBy
      updatedByName
      lastUpdatedTest
      autoRelease
      lastUsed
      isPrimaryProfile
      parentRelation
      note
      sendSMS
      sendEmail
      totalTest
      connectedTo
      createdBy
      createdByName
      insuranceDetails
      insuranceCompanyCode
      medicalNo
      postGridID
      noteAddedBy
      status
      isHospice
      snf
      isInsHousing
      eligibilityStatus
      requestedDetails
      primaryCarePhysician
      referringPyhsician
      renderingProvider
      referringProvider
      orderingProvider
      codes
      testTwo
      siteID
      setting
      _version
      _deleted
      _lastChangedAt
      updatedAt
      Client {
        id
        name
        contact_name
        contact_phone
        phone_number
        country
        timezone
        fax_number
        street
        city
        state
        zipcode
        contact_email
        hippa
        hippaFile
        resultType
        showOnSummaryScreen
        whiteLabel
        whiteLabelPackage
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        resultTypes
        brmSetting
        setting
        pgName
        colLabId
        uploadFileStatus
        orderCounter
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Sites {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseStatus
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteEmployee = /* GraphQL */ `
  mutation DeleteEmployee(
    $input: DeleteEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    deleteEmployee(input: $input, condition: $condition) {
      id
      first
      last
      middleName
      dob
      id_number
      clientID
      employeeFND
      street
      street2
      city
      state
      county
      zip
      country
      countryCode
      phone_number
      sex
      email
      isVaccinated
      insurance_name
      insurance_number
      insuranceGroupId
      insurance_img_front
      insurance_img_back
      subID
      site_name
      isNew
      isPreRegistered
      medflowDomain
      mdID
      schrID
      whiteGlove
      employeeType
      profileImage
      vaccinationCardUpload
      vaccinationCardImage
      vaccinationDetails
      vaccinationType
      vaccinationDate
      vaccinationLocation
      vaccinationDateS
      vaccinationLocationS
      isBooster
      BoosterShotCardImage
      isExternalTest
      externalTestImage
      externalTestDate
      externalTestType
      externalTestResult
      preRegisterShowId
      patternConsent
      hippaConsent
      insuranceScan
      createdAt
      updatedBy
      updatedByName
      lastUpdatedTest
      autoRelease
      lastUsed
      isPrimaryProfile
      parentRelation
      note
      sendSMS
      sendEmail
      totalTest
      connectedTo
      createdBy
      createdByName
      insuranceDetails
      insuranceCompanyCode
      medicalNo
      postGridID
      noteAddedBy
      status
      isHospice
      snf
      isInsHousing
      eligibilityStatus
      requestedDetails
      primaryCarePhysician
      referringPyhsician
      renderingProvider
      referringProvider
      orderingProvider
      codes
      testTwo
      siteID
      setting
      _version
      _deleted
      _lastChangedAt
      updatedAt
      Client {
        id
        name
        contact_name
        contact_phone
        phone_number
        country
        timezone
        fax_number
        street
        city
        state
        zipcode
        contact_email
        hippa
        hippaFile
        resultType
        showOnSummaryScreen
        whiteLabel
        whiteLabelPackage
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        resultTypes
        brmSetting
        setting
        pgName
        colLabId
        uploadFileStatus
        orderCounter
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Sites {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseStatus
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createTest = /* GraphQL */ `
  mutation CreateTest(
    $input: CreateTestInput!
    $condition: ModelTestConditionInput
  ) {
    createTest(input: $input, condition: $condition) {
      id
      status
      clientID
      labID
      employeeID
      siteID
      orderID
      baseSiteID
      testTypeID
      batch
      barcode
      owner
      result
      resultDetails
      resultDate
      resultDateTime
      employee_demographics
      labOrderDetail
      site_name
      clientName
      labName
      phoneNumber
      test_number
      email
      tester_name
      isAntigen
      referenceID
      test_type
      createdBy
      testerPhone
      submittedBy
      submittedByName
      StampBy
      StampByName
      emailSend
      quarantinedStart
      quarantinedEnd
      startTimeStamp
      timerStatus
      startDate
      expired
      beenTimed
      done
      sr_no
      appVer
      sequenceNo
      createSource
      isFalsePositive
      invalidResultStatus
      isLucira
      patternTestAnswer
      schrID
      schrTestID
      createdAt
      timezone
      pcrStatus
      scanByTesterID
      scanByTesterName
      scanByLabUserID
      scanByLabUserName
      labelPrinted
      isExternalTest
      externalTestFile
      testLocation
      isArchive
      arkstoneID
      arkstoneStatus
      sendToID
      sendToName
      sentToLabDate
      verifiedBy
      verifiedByName
      releaseStatus
      _version
      _deleted
      _lastChangedAt
      updatedAt
      lab {
        id
        name
        logo
        contact_name
        contact_phone
        phone_number
        fax_number
        user_id
        contact_email
        tubes_provided
        lab_director
        country
        referredDoctor
        clia_number
        testing_notes
        street
        city_state_zip
        lab_contacts
        default_antigen
        antigen_notes
        default_molecular
        default_other
        default_rapidflu
        default_lucira
        molecular_notes
        custom_notes
        invalid_notes
        indeterminate_notes
        other_notes
        lucira_notes
        rapidflu_notes
        sendInsurance
        barCodeProvided
        barCodeAlertLimit
        whiteLabel
        whiteLabelPackage
        showOnSummaryScreen
        orderCodes
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        pendingRelease
        logourl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      site {
        id
        name
        clientID
        contact_name
        contactName
        contact_phone
        phone_number
        email
        contact_email
        safeCampAdmin
        antigen
        automateTestType
        user_id
        show_contacts
        insurance_required
        sendInsuranceCard
        isInsurance
        resetTestDay
        startDate
        endDate
        orderKitDate
        orderKitType
        orderQuantity
        enableShipment
        showOnSummaryScreen
        messageTested
        messageResulted
        messagePositive
        messageReTest
        printLabel
        isArchive
        isActive
        isLucira
        isPCRCustody
        vaccinationCard
        externalTest
        externalTestType
        externalTestDuration
        externalTestAdmit
        patternTesting
        isHRPR
        patternHippa
        preRegistration
        admit
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        totalPR
        totalOrdered
        totalExtTest
        totalCheckIn
        totalGuest
        totalStaff
        status
        companyID
        sendIdentification
        isAutoSubmitClaim
        sendExternalRequisition
        sendPrescription
        sendAuthorization
        sendOtherDocuments
        startBarCode
        uploadFileStatus
        hippa
        hippaFile
        resultTemplate
        isTesting
        street
        city
        state
        zipcode
        countryCode
        country
        setting
        defaultProvider
        practiceID
        labLocalID
        fax
        contact_fax
        discount
        commission
        totalOrders
        totalBilledAmount
        claimAmount
        openAmount
        paid
        lastOrder
        note
        createdBy
        createdByName
        updatedByName
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      client {
        id
        name
        contact_name
        contact_phone
        phone_number
        country
        timezone
        fax_number
        street
        city
        state
        zipcode
        contact_email
        hippa
        hippaFile
        resultType
        showOnSummaryScreen
        whiteLabel
        whiteLabelPackage
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        resultTypes
        brmSetting
        setting
        pgName
        colLabId
        uploadFileStatus
        orderCounter
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Sites {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      employee {
        id
        first
        last
        middleName
        dob
        id_number
        clientID
        employeeFND
        street
        street2
        city
        state
        county
        zip
        country
        countryCode
        phone_number
        sex
        email
        isVaccinated
        insurance_name
        insurance_number
        insuranceGroupId
        insurance_img_front
        insurance_img_back
        subID
        site_name
        isNew
        isPreRegistered
        medflowDomain
        mdID
        schrID
        whiteGlove
        employeeType
        profileImage
        vaccinationCardUpload
        vaccinationCardImage
        vaccinationDetails
        vaccinationType
        vaccinationDate
        vaccinationLocation
        vaccinationDateS
        vaccinationLocationS
        isBooster
        BoosterShotCardImage
        isExternalTest
        externalTestImage
        externalTestDate
        externalTestType
        externalTestResult
        preRegisterShowId
        patternConsent
        hippaConsent
        insuranceScan
        createdAt
        updatedBy
        updatedByName
        lastUpdatedTest
        autoRelease
        lastUsed
        isPrimaryProfile
        parentRelation
        note
        sendSMS
        sendEmail
        totalTest
        connectedTo
        createdBy
        createdByName
        insuranceDetails
        insuranceCompanyCode
        medicalNo
        postGridID
        noteAddedBy
        status
        isHospice
        snf
        isInsHousing
        eligibilityStatus
        requestedDetails
        primaryCarePhysician
        referringPyhsician
        renderingProvider
        referringProvider
        orderingProvider
        codes
        testTwo
        siteID
        setting
        _version
        _deleted
        _lastChangedAt
        updatedAt
        Client {
          id
          name
          contact_name
          contact_phone
          phone_number
          country
          timezone
          fax_number
          street
          city
          state
          zipcode
          contact_email
          hippa
          hippaFile
          resultType
          showOnSummaryScreen
          whiteLabel
          whiteLabelPackage
          isArchive
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          resultTypes
          brmSetting
          setting
          pgName
          colLabId
          uploadFileStatus
          orderCounter
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      order {
        id
        employeeID
        orderNo
        providerID
        clientID
        labID
        labName
        clientName
        providerName
        siteID
        site_name
        employee_demographics
        testsToDo
        barcode
        diag_1
        diag_2
        diag_3
        diag_4
        diag_5
        diag_6
        diag_7
        diag_8
        diag_9
        diag_10
        diag_11
        diag_12
        proc_array
        provider
        orderDate
        testDoneDate
        status
        cancelledReason
        notes
        isActive
        isResultEmailed
        floorNo
        requestID
        receivedBy
        receivedByName
        receivedDate
        updatedBy
        updatedByName
        createdBy
        createdByName
        timezone
        eligibilityStatus
        location
        tdDate
        externalID
        clincialComments
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateTest = /* GraphQL */ `
  mutation UpdateTest(
    $input: UpdateTestInput!
    $condition: ModelTestConditionInput
  ) {
    updateTest(input: $input, condition: $condition) {
      id
      status
      clientID
      labID
      employeeID
      siteID
      orderID
      baseSiteID
      testTypeID
      batch
      barcode
      owner
      result
      resultDetails
      resultDate
      resultDateTime
      employee_demographics
      labOrderDetail
      site_name
      clientName
      labName
      phoneNumber
      test_number
      email
      tester_name
      isAntigen
      referenceID
      test_type
      createdBy
      testerPhone
      submittedBy
      submittedByName
      StampBy
      StampByName
      emailSend
      quarantinedStart
      quarantinedEnd
      startTimeStamp
      timerStatus
      startDate
      expired
      beenTimed
      done
      sr_no
      appVer
      sequenceNo
      createSource
      isFalsePositive
      invalidResultStatus
      isLucira
      patternTestAnswer
      schrID
      schrTestID
      createdAt
      timezone
      pcrStatus
      scanByTesterID
      scanByTesterName
      scanByLabUserID
      scanByLabUserName
      labelPrinted
      isExternalTest
      externalTestFile
      testLocation
      isArchive
      arkstoneID
      arkstoneStatus
      sendToID
      sendToName
      sentToLabDate
      verifiedBy
      verifiedByName
      releaseStatus
      _version
      _deleted
      _lastChangedAt
      updatedAt
      lab {
        id
        name
        logo
        contact_name
        contact_phone
        phone_number
        fax_number
        user_id
        contact_email
        tubes_provided
        lab_director
        country
        referredDoctor
        clia_number
        testing_notes
        street
        city_state_zip
        lab_contacts
        default_antigen
        antigen_notes
        default_molecular
        default_other
        default_rapidflu
        default_lucira
        molecular_notes
        custom_notes
        invalid_notes
        indeterminate_notes
        other_notes
        lucira_notes
        rapidflu_notes
        sendInsurance
        barCodeProvided
        barCodeAlertLimit
        whiteLabel
        whiteLabelPackage
        showOnSummaryScreen
        orderCodes
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        pendingRelease
        logourl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      site {
        id
        name
        clientID
        contact_name
        contactName
        contact_phone
        phone_number
        email
        contact_email
        safeCampAdmin
        antigen
        automateTestType
        user_id
        show_contacts
        insurance_required
        sendInsuranceCard
        isInsurance
        resetTestDay
        startDate
        endDate
        orderKitDate
        orderKitType
        orderQuantity
        enableShipment
        showOnSummaryScreen
        messageTested
        messageResulted
        messagePositive
        messageReTest
        printLabel
        isArchive
        isActive
        isLucira
        isPCRCustody
        vaccinationCard
        externalTest
        externalTestType
        externalTestDuration
        externalTestAdmit
        patternTesting
        isHRPR
        patternHippa
        preRegistration
        admit
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        totalPR
        totalOrdered
        totalExtTest
        totalCheckIn
        totalGuest
        totalStaff
        status
        companyID
        sendIdentification
        isAutoSubmitClaim
        sendExternalRequisition
        sendPrescription
        sendAuthorization
        sendOtherDocuments
        startBarCode
        uploadFileStatus
        hippa
        hippaFile
        resultTemplate
        isTesting
        street
        city
        state
        zipcode
        countryCode
        country
        setting
        defaultProvider
        practiceID
        labLocalID
        fax
        contact_fax
        discount
        commission
        totalOrders
        totalBilledAmount
        claimAmount
        openAmount
        paid
        lastOrder
        note
        createdBy
        createdByName
        updatedByName
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      client {
        id
        name
        contact_name
        contact_phone
        phone_number
        country
        timezone
        fax_number
        street
        city
        state
        zipcode
        contact_email
        hippa
        hippaFile
        resultType
        showOnSummaryScreen
        whiteLabel
        whiteLabelPackage
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        resultTypes
        brmSetting
        setting
        pgName
        colLabId
        uploadFileStatus
        orderCounter
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Sites {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      employee {
        id
        first
        last
        middleName
        dob
        id_number
        clientID
        employeeFND
        street
        street2
        city
        state
        county
        zip
        country
        countryCode
        phone_number
        sex
        email
        isVaccinated
        insurance_name
        insurance_number
        insuranceGroupId
        insurance_img_front
        insurance_img_back
        subID
        site_name
        isNew
        isPreRegistered
        medflowDomain
        mdID
        schrID
        whiteGlove
        employeeType
        profileImage
        vaccinationCardUpload
        vaccinationCardImage
        vaccinationDetails
        vaccinationType
        vaccinationDate
        vaccinationLocation
        vaccinationDateS
        vaccinationLocationS
        isBooster
        BoosterShotCardImage
        isExternalTest
        externalTestImage
        externalTestDate
        externalTestType
        externalTestResult
        preRegisterShowId
        patternConsent
        hippaConsent
        insuranceScan
        createdAt
        updatedBy
        updatedByName
        lastUpdatedTest
        autoRelease
        lastUsed
        isPrimaryProfile
        parentRelation
        note
        sendSMS
        sendEmail
        totalTest
        connectedTo
        createdBy
        createdByName
        insuranceDetails
        insuranceCompanyCode
        medicalNo
        postGridID
        noteAddedBy
        status
        isHospice
        snf
        isInsHousing
        eligibilityStatus
        requestedDetails
        primaryCarePhysician
        referringPyhsician
        renderingProvider
        referringProvider
        orderingProvider
        codes
        testTwo
        siteID
        setting
        _version
        _deleted
        _lastChangedAt
        updatedAt
        Client {
          id
          name
          contact_name
          contact_phone
          phone_number
          country
          timezone
          fax_number
          street
          city
          state
          zipcode
          contact_email
          hippa
          hippaFile
          resultType
          showOnSummaryScreen
          whiteLabel
          whiteLabelPackage
          isArchive
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          resultTypes
          brmSetting
          setting
          pgName
          colLabId
          uploadFileStatus
          orderCounter
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      order {
        id
        employeeID
        orderNo
        providerID
        clientID
        labID
        labName
        clientName
        providerName
        siteID
        site_name
        employee_demographics
        testsToDo
        barcode
        diag_1
        diag_2
        diag_3
        diag_4
        diag_5
        diag_6
        diag_7
        diag_8
        diag_9
        diag_10
        diag_11
        diag_12
        proc_array
        provider
        orderDate
        testDoneDate
        status
        cancelledReason
        notes
        isActive
        isResultEmailed
        floorNo
        requestID
        receivedBy
        receivedByName
        receivedDate
        updatedBy
        updatedByName
        createdBy
        createdByName
        timezone
        eligibilityStatus
        location
        tdDate
        externalID
        clincialComments
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteTest = /* GraphQL */ `
  mutation DeleteTest(
    $input: DeleteTestInput!
    $condition: ModelTestConditionInput
  ) {
    deleteTest(input: $input, condition: $condition) {
      id
      status
      clientID
      labID
      employeeID
      siteID
      orderID
      baseSiteID
      testTypeID
      batch
      barcode
      owner
      result
      resultDetails
      resultDate
      resultDateTime
      employee_demographics
      labOrderDetail
      site_name
      clientName
      labName
      phoneNumber
      test_number
      email
      tester_name
      isAntigen
      referenceID
      test_type
      createdBy
      testerPhone
      submittedBy
      submittedByName
      StampBy
      StampByName
      emailSend
      quarantinedStart
      quarantinedEnd
      startTimeStamp
      timerStatus
      startDate
      expired
      beenTimed
      done
      sr_no
      appVer
      sequenceNo
      createSource
      isFalsePositive
      invalidResultStatus
      isLucira
      patternTestAnswer
      schrID
      schrTestID
      createdAt
      timezone
      pcrStatus
      scanByTesterID
      scanByTesterName
      scanByLabUserID
      scanByLabUserName
      labelPrinted
      isExternalTest
      externalTestFile
      testLocation
      isArchive
      arkstoneID
      arkstoneStatus
      sendToID
      sendToName
      sentToLabDate
      verifiedBy
      verifiedByName
      releaseStatus
      _version
      _deleted
      _lastChangedAt
      updatedAt
      lab {
        id
        name
        logo
        contact_name
        contact_phone
        phone_number
        fax_number
        user_id
        contact_email
        tubes_provided
        lab_director
        country
        referredDoctor
        clia_number
        testing_notes
        street
        city_state_zip
        lab_contacts
        default_antigen
        antigen_notes
        default_molecular
        default_other
        default_rapidflu
        default_lucira
        molecular_notes
        custom_notes
        invalid_notes
        indeterminate_notes
        other_notes
        lucira_notes
        rapidflu_notes
        sendInsurance
        barCodeProvided
        barCodeAlertLimit
        whiteLabel
        whiteLabelPackage
        showOnSummaryScreen
        orderCodes
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        pendingRelease
        logourl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      site {
        id
        name
        clientID
        contact_name
        contactName
        contact_phone
        phone_number
        email
        contact_email
        safeCampAdmin
        antigen
        automateTestType
        user_id
        show_contacts
        insurance_required
        sendInsuranceCard
        isInsurance
        resetTestDay
        startDate
        endDate
        orderKitDate
        orderKitType
        orderQuantity
        enableShipment
        showOnSummaryScreen
        messageTested
        messageResulted
        messagePositive
        messageReTest
        printLabel
        isArchive
        isActive
        isLucira
        isPCRCustody
        vaccinationCard
        externalTest
        externalTestType
        externalTestDuration
        externalTestAdmit
        patternTesting
        isHRPR
        patternHippa
        preRegistration
        admit
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        totalPR
        totalOrdered
        totalExtTest
        totalCheckIn
        totalGuest
        totalStaff
        status
        companyID
        sendIdentification
        isAutoSubmitClaim
        sendExternalRequisition
        sendPrescription
        sendAuthorization
        sendOtherDocuments
        startBarCode
        uploadFileStatus
        hippa
        hippaFile
        resultTemplate
        isTesting
        street
        city
        state
        zipcode
        countryCode
        country
        setting
        defaultProvider
        practiceID
        labLocalID
        fax
        contact_fax
        discount
        commission
        totalOrders
        totalBilledAmount
        claimAmount
        openAmount
        paid
        lastOrder
        note
        createdBy
        createdByName
        updatedByName
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      client {
        id
        name
        contact_name
        contact_phone
        phone_number
        country
        timezone
        fax_number
        street
        city
        state
        zipcode
        contact_email
        hippa
        hippaFile
        resultType
        showOnSummaryScreen
        whiteLabel
        whiteLabelPackage
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        resultTypes
        brmSetting
        setting
        pgName
        colLabId
        uploadFileStatus
        orderCounter
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Sites {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      employee {
        id
        first
        last
        middleName
        dob
        id_number
        clientID
        employeeFND
        street
        street2
        city
        state
        county
        zip
        country
        countryCode
        phone_number
        sex
        email
        isVaccinated
        insurance_name
        insurance_number
        insuranceGroupId
        insurance_img_front
        insurance_img_back
        subID
        site_name
        isNew
        isPreRegistered
        medflowDomain
        mdID
        schrID
        whiteGlove
        employeeType
        profileImage
        vaccinationCardUpload
        vaccinationCardImage
        vaccinationDetails
        vaccinationType
        vaccinationDate
        vaccinationLocation
        vaccinationDateS
        vaccinationLocationS
        isBooster
        BoosterShotCardImage
        isExternalTest
        externalTestImage
        externalTestDate
        externalTestType
        externalTestResult
        preRegisterShowId
        patternConsent
        hippaConsent
        insuranceScan
        createdAt
        updatedBy
        updatedByName
        lastUpdatedTest
        autoRelease
        lastUsed
        isPrimaryProfile
        parentRelation
        note
        sendSMS
        sendEmail
        totalTest
        connectedTo
        createdBy
        createdByName
        insuranceDetails
        insuranceCompanyCode
        medicalNo
        postGridID
        noteAddedBy
        status
        isHospice
        snf
        isInsHousing
        eligibilityStatus
        requestedDetails
        primaryCarePhysician
        referringPyhsician
        renderingProvider
        referringProvider
        orderingProvider
        codes
        testTwo
        siteID
        setting
        _version
        _deleted
        _lastChangedAt
        updatedAt
        Client {
          id
          name
          contact_name
          contact_phone
          phone_number
          country
          timezone
          fax_number
          street
          city
          state
          zipcode
          contact_email
          hippa
          hippaFile
          resultType
          showOnSummaryScreen
          whiteLabel
          whiteLabelPackage
          isArchive
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          resultTypes
          brmSetting
          setting
          pgName
          colLabId
          uploadFileStatus
          orderCounter
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      order {
        id
        employeeID
        orderNo
        providerID
        clientID
        labID
        labName
        clientName
        providerName
        siteID
        site_name
        employee_demographics
        testsToDo
        barcode
        diag_1
        diag_2
        diag_3
        diag_4
        diag_5
        diag_6
        diag_7
        diag_8
        diag_9
        diag_10
        diag_11
        diag_12
        proc_array
        provider
        orderDate
        testDoneDate
        status
        cancelledReason
        notes
        isActive
        isResultEmailed
        floorNo
        requestID
        receivedBy
        receivedByName
        receivedDate
        updatedBy
        updatedByName
        createdBy
        createdByName
        timezone
        eligibilityStatus
        location
        tdDate
        externalID
        clincialComments
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createLencoBarcodes = /* GraphQL */ `
  mutation CreateLencoBarcodes(
    $input: CreateLencoBarcodesInput!
    $condition: ModelLencoBarcodesConditionInput
  ) {
    createLencoBarcodes(input: $input, condition: $condition) {
      id
      labID
      clientID
      barcode
      available
      isActive
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLencoBarcodes = /* GraphQL */ `
  mutation UpdateLencoBarcodes(
    $input: UpdateLencoBarcodesInput!
    $condition: ModelLencoBarcodesConditionInput
  ) {
    updateLencoBarcodes(input: $input, condition: $condition) {
      id
      labID
      clientID
      barcode
      available
      isActive
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLencoBarcodes = /* GraphQL */ `
  mutation DeleteLencoBarcodes(
    $input: DeleteLencoBarcodesInput!
    $condition: ModelLencoBarcodesConditionInput
  ) {
    deleteLencoBarcodes(input: $input, condition: $condition) {
      id
      labID
      clientID
      barcode
      available
      isActive
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTestConsent = /* GraphQL */ `
  mutation CreateTestConsent(
    $input: CreateTestConsentInput!
    $condition: ModelTestConsentConditionInput
  ) {
    createTestConsent(input: $input, condition: $condition) {
      id
      employeeID
      siteID
      isConsent
      isInsuranceCardScan
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      site {
        id
        name
        clientID
        contact_name
        contactName
        contact_phone
        phone_number
        email
        contact_email
        safeCampAdmin
        antigen
        automateTestType
        user_id
        show_contacts
        insurance_required
        sendInsuranceCard
        isInsurance
        resetTestDay
        startDate
        endDate
        orderKitDate
        orderKitType
        orderQuantity
        enableShipment
        showOnSummaryScreen
        messageTested
        messageResulted
        messagePositive
        messageReTest
        printLabel
        isArchive
        isActive
        isLucira
        isPCRCustody
        vaccinationCard
        externalTest
        externalTestType
        externalTestDuration
        externalTestAdmit
        patternTesting
        isHRPR
        patternHippa
        preRegistration
        admit
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        totalPR
        totalOrdered
        totalExtTest
        totalCheckIn
        totalGuest
        totalStaff
        status
        companyID
        sendIdentification
        isAutoSubmitClaim
        sendExternalRequisition
        sendPrescription
        sendAuthorization
        sendOtherDocuments
        startBarCode
        uploadFileStatus
        hippa
        hippaFile
        resultTemplate
        isTesting
        street
        city
        state
        zipcode
        countryCode
        country
        setting
        defaultProvider
        practiceID
        labLocalID
        fax
        contact_fax
        discount
        commission
        totalOrders
        totalBilledAmount
        claimAmount
        openAmount
        paid
        lastOrder
        note
        createdBy
        createdByName
        updatedByName
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      employee {
        id
        first
        last
        middleName
        dob
        id_number
        clientID
        employeeFND
        street
        street2
        city
        state
        county
        zip
        country
        countryCode
        phone_number
        sex
        email
        isVaccinated
        insurance_name
        insurance_number
        insuranceGroupId
        insurance_img_front
        insurance_img_back
        subID
        site_name
        isNew
        isPreRegistered
        medflowDomain
        mdID
        schrID
        whiteGlove
        employeeType
        profileImage
        vaccinationCardUpload
        vaccinationCardImage
        vaccinationDetails
        vaccinationType
        vaccinationDate
        vaccinationLocation
        vaccinationDateS
        vaccinationLocationS
        isBooster
        BoosterShotCardImage
        isExternalTest
        externalTestImage
        externalTestDate
        externalTestType
        externalTestResult
        preRegisterShowId
        patternConsent
        hippaConsent
        insuranceScan
        createdAt
        updatedBy
        updatedByName
        lastUpdatedTest
        autoRelease
        lastUsed
        isPrimaryProfile
        parentRelation
        note
        sendSMS
        sendEmail
        totalTest
        connectedTo
        createdBy
        createdByName
        insuranceDetails
        insuranceCompanyCode
        medicalNo
        postGridID
        noteAddedBy
        status
        isHospice
        snf
        isInsHousing
        eligibilityStatus
        requestedDetails
        primaryCarePhysician
        referringPyhsician
        renderingProvider
        referringProvider
        orderingProvider
        codes
        testTwo
        siteID
        setting
        _version
        _deleted
        _lastChangedAt
        updatedAt
        Client {
          id
          name
          contact_name
          contact_phone
          phone_number
          country
          timezone
          fax_number
          street
          city
          state
          zipcode
          contact_email
          hippa
          hippaFile
          resultType
          showOnSummaryScreen
          whiteLabel
          whiteLabelPackage
          isArchive
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          resultTypes
          brmSetting
          setting
          pgName
          colLabId
          uploadFileStatus
          orderCounter
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateTestConsent = /* GraphQL */ `
  mutation UpdateTestConsent(
    $input: UpdateTestConsentInput!
    $condition: ModelTestConsentConditionInput
  ) {
    updateTestConsent(input: $input, condition: $condition) {
      id
      employeeID
      siteID
      isConsent
      isInsuranceCardScan
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      site {
        id
        name
        clientID
        contact_name
        contactName
        contact_phone
        phone_number
        email
        contact_email
        safeCampAdmin
        antigen
        automateTestType
        user_id
        show_contacts
        insurance_required
        sendInsuranceCard
        isInsurance
        resetTestDay
        startDate
        endDate
        orderKitDate
        orderKitType
        orderQuantity
        enableShipment
        showOnSummaryScreen
        messageTested
        messageResulted
        messagePositive
        messageReTest
        printLabel
        isArchive
        isActive
        isLucira
        isPCRCustody
        vaccinationCard
        externalTest
        externalTestType
        externalTestDuration
        externalTestAdmit
        patternTesting
        isHRPR
        patternHippa
        preRegistration
        admit
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        totalPR
        totalOrdered
        totalExtTest
        totalCheckIn
        totalGuest
        totalStaff
        status
        companyID
        sendIdentification
        isAutoSubmitClaim
        sendExternalRequisition
        sendPrescription
        sendAuthorization
        sendOtherDocuments
        startBarCode
        uploadFileStatus
        hippa
        hippaFile
        resultTemplate
        isTesting
        street
        city
        state
        zipcode
        countryCode
        country
        setting
        defaultProvider
        practiceID
        labLocalID
        fax
        contact_fax
        discount
        commission
        totalOrders
        totalBilledAmount
        claimAmount
        openAmount
        paid
        lastOrder
        note
        createdBy
        createdByName
        updatedByName
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      employee {
        id
        first
        last
        middleName
        dob
        id_number
        clientID
        employeeFND
        street
        street2
        city
        state
        county
        zip
        country
        countryCode
        phone_number
        sex
        email
        isVaccinated
        insurance_name
        insurance_number
        insuranceGroupId
        insurance_img_front
        insurance_img_back
        subID
        site_name
        isNew
        isPreRegistered
        medflowDomain
        mdID
        schrID
        whiteGlove
        employeeType
        profileImage
        vaccinationCardUpload
        vaccinationCardImage
        vaccinationDetails
        vaccinationType
        vaccinationDate
        vaccinationLocation
        vaccinationDateS
        vaccinationLocationS
        isBooster
        BoosterShotCardImage
        isExternalTest
        externalTestImage
        externalTestDate
        externalTestType
        externalTestResult
        preRegisterShowId
        patternConsent
        hippaConsent
        insuranceScan
        createdAt
        updatedBy
        updatedByName
        lastUpdatedTest
        autoRelease
        lastUsed
        isPrimaryProfile
        parentRelation
        note
        sendSMS
        sendEmail
        totalTest
        connectedTo
        createdBy
        createdByName
        insuranceDetails
        insuranceCompanyCode
        medicalNo
        postGridID
        noteAddedBy
        status
        isHospice
        snf
        isInsHousing
        eligibilityStatus
        requestedDetails
        primaryCarePhysician
        referringPyhsician
        renderingProvider
        referringProvider
        orderingProvider
        codes
        testTwo
        siteID
        setting
        _version
        _deleted
        _lastChangedAt
        updatedAt
        Client {
          id
          name
          contact_name
          contact_phone
          phone_number
          country
          timezone
          fax_number
          street
          city
          state
          zipcode
          contact_email
          hippa
          hippaFile
          resultType
          showOnSummaryScreen
          whiteLabel
          whiteLabelPackage
          isArchive
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          resultTypes
          brmSetting
          setting
          pgName
          colLabId
          uploadFileStatus
          orderCounter
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteTestConsent = /* GraphQL */ `
  mutation DeleteTestConsent(
    $input: DeleteTestConsentInput!
    $condition: ModelTestConsentConditionInput
  ) {
    deleteTestConsent(input: $input, condition: $condition) {
      id
      employeeID
      siteID
      isConsent
      isInsuranceCardScan
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      site {
        id
        name
        clientID
        contact_name
        contactName
        contact_phone
        phone_number
        email
        contact_email
        safeCampAdmin
        antigen
        automateTestType
        user_id
        show_contacts
        insurance_required
        sendInsuranceCard
        isInsurance
        resetTestDay
        startDate
        endDate
        orderKitDate
        orderKitType
        orderQuantity
        enableShipment
        showOnSummaryScreen
        messageTested
        messageResulted
        messagePositive
        messageReTest
        printLabel
        isArchive
        isActive
        isLucira
        isPCRCustody
        vaccinationCard
        externalTest
        externalTestType
        externalTestDuration
        externalTestAdmit
        patternTesting
        isHRPR
        patternHippa
        preRegistration
        admit
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        totalPR
        totalOrdered
        totalExtTest
        totalCheckIn
        totalGuest
        totalStaff
        status
        companyID
        sendIdentification
        isAutoSubmitClaim
        sendExternalRequisition
        sendPrescription
        sendAuthorization
        sendOtherDocuments
        startBarCode
        uploadFileStatus
        hippa
        hippaFile
        resultTemplate
        isTesting
        street
        city
        state
        zipcode
        countryCode
        country
        setting
        defaultProvider
        practiceID
        labLocalID
        fax
        contact_fax
        discount
        commission
        totalOrders
        totalBilledAmount
        claimAmount
        openAmount
        paid
        lastOrder
        note
        createdBy
        createdByName
        updatedByName
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      employee {
        id
        first
        last
        middleName
        dob
        id_number
        clientID
        employeeFND
        street
        street2
        city
        state
        county
        zip
        country
        countryCode
        phone_number
        sex
        email
        isVaccinated
        insurance_name
        insurance_number
        insuranceGroupId
        insurance_img_front
        insurance_img_back
        subID
        site_name
        isNew
        isPreRegistered
        medflowDomain
        mdID
        schrID
        whiteGlove
        employeeType
        profileImage
        vaccinationCardUpload
        vaccinationCardImage
        vaccinationDetails
        vaccinationType
        vaccinationDate
        vaccinationLocation
        vaccinationDateS
        vaccinationLocationS
        isBooster
        BoosterShotCardImage
        isExternalTest
        externalTestImage
        externalTestDate
        externalTestType
        externalTestResult
        preRegisterShowId
        patternConsent
        hippaConsent
        insuranceScan
        createdAt
        updatedBy
        updatedByName
        lastUpdatedTest
        autoRelease
        lastUsed
        isPrimaryProfile
        parentRelation
        note
        sendSMS
        sendEmail
        totalTest
        connectedTo
        createdBy
        createdByName
        insuranceDetails
        insuranceCompanyCode
        medicalNo
        postGridID
        noteAddedBy
        status
        isHospice
        snf
        isInsHousing
        eligibilityStatus
        requestedDetails
        primaryCarePhysician
        referringPyhsician
        renderingProvider
        referringProvider
        orderingProvider
        codes
        testTwo
        siteID
        setting
        _version
        _deleted
        _lastChangedAt
        updatedAt
        Client {
          id
          name
          contact_name
          contact_phone
          phone_number
          country
          timezone
          fax_number
          street
          city
          state
          zipcode
          contact_email
          hippa
          hippaFile
          resultType
          showOnSummaryScreen
          whiteLabel
          whiteLabelPackage
          isArchive
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          resultTypes
          brmSetting
          setting
          pgName
          colLabId
          uploadFileStatus
          orderCounter
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        Tests {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createTestTypes = /* GraphQL */ `
  mutation CreateTestTypes(
    $input: CreateTestTypesInput!
    $condition: ModelTestTypesConditionInput
  ) {
    createTestTypes(input: $input, condition: $condition) {
      id
      code
      name
      category
      custom_notes
      typeOfTest
      type
      totalTime
      firstAlert
      secondAlert
      units
      minValue
      maxValue
      refInterval
      duration
      hrCounter
      siteID
      clientID
      sources
      cptCodes
      ictCodes
      targetRanges
      isActive
      isAssociatedPanel
      isARKStone
      lonicCode
      setting
      ranges
      createdByName
      updatedByName
      lastUsed
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTestTypes = /* GraphQL */ `
  mutation UpdateTestTypes(
    $input: UpdateTestTypesInput!
    $condition: ModelTestTypesConditionInput
  ) {
    updateTestTypes(input: $input, condition: $condition) {
      id
      code
      name
      category
      custom_notes
      typeOfTest
      type
      totalTime
      firstAlert
      secondAlert
      units
      minValue
      maxValue
      refInterval
      duration
      hrCounter
      siteID
      clientID
      sources
      cptCodes
      ictCodes
      targetRanges
      isActive
      isAssociatedPanel
      isARKStone
      lonicCode
      setting
      ranges
      createdByName
      updatedByName
      lastUsed
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTestTypes = /* GraphQL */ `
  mutation DeleteTestTypes(
    $input: DeleteTestTypesInput!
    $condition: ModelTestTypesConditionInput
  ) {
    deleteTestTypes(input: $input, condition: $condition) {
      id
      code
      name
      category
      custom_notes
      typeOfTest
      type
      totalTime
      firstAlert
      secondAlert
      units
      minValue
      maxValue
      refInterval
      duration
      hrCounter
      siteID
      clientID
      sources
      cptCodes
      ictCodes
      targetRanges
      isActive
      isAssociatedPanel
      isARKStone
      lonicCode
      setting
      ranges
      createdByName
      updatedByName
      lastUsed
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEmployeeBarCodes = /* GraphQL */ `
  mutation CreateEmployeeBarCodes(
    $input: CreateEmployeeBarCodesInput!
    $condition: ModelEmployeeBarCodesConditionInput
  ) {
    createEmployeeBarCodes(input: $input, condition: $condition) {
      id
      employeeID
      barcode
      phone_number
      licenceNumber
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
  }
`;
export const updateEmployeeBarCodes = /* GraphQL */ `
  mutation UpdateEmployeeBarCodes(
    $input: UpdateEmployeeBarCodesInput!
    $condition: ModelEmployeeBarCodesConditionInput
  ) {
    updateEmployeeBarCodes(input: $input, condition: $condition) {
      id
      employeeID
      barcode
      phone_number
      licenceNumber
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEmployeeBarCodes = /* GraphQL */ `
  mutation DeleteEmployeeBarCodes(
    $input: DeleteEmployeeBarCodesInput!
    $condition: ModelEmployeeBarCodesConditionInput
  ) {
    deleteEmployeeBarCodes(input: $input, condition: $condition) {
      id
      employeeID
      barcode
      phone_number
      licenceNumber
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
  }
`;
export const createEmployeeTestCounter = /* GraphQL */ `
  mutation CreateEmployeeTestCounter(
    $input: CreateEmployeeTestCounterInput!
    $condition: ModelEmployeeTestCounterConditionInput
  ) {
    createEmployeeTestCounter(input: $input, condition: $condition) {
      id
      counter
      employeeCounter
      orderCounter
      patternQuestion
      totalTest
      employeeUpdate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEmployeeTestCounter = /* GraphQL */ `
  mutation UpdateEmployeeTestCounter(
    $input: UpdateEmployeeTestCounterInput!
    $condition: ModelEmployeeTestCounterConditionInput
  ) {
    updateEmployeeTestCounter(input: $input, condition: $condition) {
      id
      counter
      employeeCounter
      orderCounter
      patternQuestion
      totalTest
      employeeUpdate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEmployeeTestCounter = /* GraphQL */ `
  mutation DeleteEmployeeTestCounter(
    $input: DeleteEmployeeTestCounterInput!
    $condition: ModelEmployeeTestCounterConditionInput
  ) {
    deleteEmployeeTestCounter(input: $input, condition: $condition) {
      id
      counter
      employeeCounter
      orderCounter
      patternQuestion
      totalTest
      employeeUpdate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createExternalTest = /* GraphQL */ `
  mutation CreateExternalTest(
    $input: CreateExternalTestInput!
    $condition: ModelExternalTestConditionInput
  ) {
    createExternalTest(input: $input, condition: $condition) {
      id
      status
      clientID
      employeeID
      siteID
      owner
      reason
      employee_demographics
      site_name
      phoneNumber
      tester_name
      email
      vaccineCardFile
      vaccineCardFileBack
      test_type
      result
      resultDateTime
      externalTestFile
      createdAt
      prID
      isPosted
      testNow
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
  }
`;
export const updateExternalTest = /* GraphQL */ `
  mutation UpdateExternalTest(
    $input: UpdateExternalTestInput!
    $condition: ModelExternalTestConditionInput
  ) {
    updateExternalTest(input: $input, condition: $condition) {
      id
      status
      clientID
      employeeID
      siteID
      owner
      reason
      employee_demographics
      site_name
      phoneNumber
      tester_name
      email
      vaccineCardFile
      vaccineCardFileBack
      test_type
      result
      resultDateTime
      externalTestFile
      createdAt
      prID
      isPosted
      testNow
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
  }
`;
export const deleteExternalTest = /* GraphQL */ `
  mutation DeleteExternalTest(
    $input: DeleteExternalTestInput!
    $condition: ModelExternalTestConditionInput
  ) {
    deleteExternalTest(input: $input, condition: $condition) {
      id
      status
      clientID
      employeeID
      siteID
      owner
      reason
      employee_demographics
      site_name
      phoneNumber
      tester_name
      email
      vaccineCardFile
      vaccineCardFileBack
      test_type
      result
      resultDateTime
      externalTestFile
      createdAt
      prID
      isPosted
      testNow
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
  }
`;
export const createEmployeeLogin = /* GraphQL */ `
  mutation CreateEmployeeLogin(
    $input: CreateEmployeeLoginInput!
    $condition: ModelEmployeeLoginConditionInput
  ) {
    createEmployeeLogin(input: $input, condition: $condition) {
      id
      employeeID
      deviceID
      deviceType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEmployeeLogin = /* GraphQL */ `
  mutation UpdateEmployeeLogin(
    $input: UpdateEmployeeLoginInput!
    $condition: ModelEmployeeLoginConditionInput
  ) {
    updateEmployeeLogin(input: $input, condition: $condition) {
      id
      employeeID
      deviceID
      deviceType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEmployeeLogin = /* GraphQL */ `
  mutation DeleteEmployeeLogin(
    $input: DeleteEmployeeLoginInput!
    $condition: ModelEmployeeLoginConditionInput
  ) {
    deleteEmployeeLogin(input: $input, condition: $condition) {
      id
      employeeID
      deviceID
      deviceType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTestSession = /* GraphQL */ `
  mutation CreateTestSession(
    $input: CreateTestSessionInput!
    $condition: ModelTestSessionConditionInput
  ) {
    createTestSession(input: $input, condition: $condition) {
      id
      siteID
      clientID
      sessionStart
      sessionEnd
      activeTester
      Tester
      startedBy
      startedByName
      status
      endedBy
      endedByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTestSession = /* GraphQL */ `
  mutation UpdateTestSession(
    $input: UpdateTestSessionInput!
    $condition: ModelTestSessionConditionInput
  ) {
    updateTestSession(input: $input, condition: $condition) {
      id
      siteID
      clientID
      sessionStart
      sessionEnd
      activeTester
      Tester
      startedBy
      startedByName
      status
      endedBy
      endedByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTestSession = /* GraphQL */ `
  mutation DeleteTestSession(
    $input: DeleteTestSessionInput!
    $condition: ModelTestSessionConditionInput
  ) {
    deleteTestSession(input: $input, condition: $condition) {
      id
      siteID
      clientID
      sessionStart
      sessionEnd
      activeTester
      Tester
      startedBy
      startedByName
      status
      endedBy
      endedByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDocuments = /* GraphQL */ `
  mutation CreateDocuments(
    $input: CreateDocumentsInput!
    $condition: ModelDocumentsConditionInput
  ) {
    createDocuments(input: $input, condition: $condition) {
      id
      clientID
      employeeID
      testID
      orderID
      docType
      docLabel
      fileName
      fileType
      fileSize
      notes
      isActive
      updatedBy
      updatedByName
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDocuments = /* GraphQL */ `
  mutation UpdateDocuments(
    $input: UpdateDocumentsInput!
    $condition: ModelDocumentsConditionInput
  ) {
    updateDocuments(input: $input, condition: $condition) {
      id
      clientID
      employeeID
      testID
      orderID
      docType
      docLabel
      fileName
      fileType
      fileSize
      notes
      isActive
      updatedBy
      updatedByName
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDocuments = /* GraphQL */ `
  mutation DeleteDocuments(
    $input: DeleteDocumentsInput!
    $condition: ModelDocumentsConditionInput
  ) {
    deleteDocuments(input: $input, condition: $condition) {
      id
      clientID
      employeeID
      testID
      orderID
      docType
      docLabel
      fileName
      fileType
      fileSize
      notes
      isActive
      updatedBy
      updatedByName
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      employeeID
      orderNo
      providerID
      clientID
      labID
      labName
      clientName
      providerName
      siteID
      site_name
      employee_demographics
      testsToDo
      barcode
      diag_1
      diag_2
      diag_3
      diag_4
      diag_5
      diag_6
      diag_7
      diag_8
      diag_9
      diag_10
      diag_11
      diag_12
      proc_array
      provider
      orderDate
      testDoneDate
      status
      cancelledReason
      notes
      isActive
      isResultEmailed
      floorNo
      requestID
      receivedBy
      receivedByName
      receivedDate
      updatedBy
      updatedByName
      createdBy
      createdByName
      timezone
      eligibilityStatus
      location
      tdDate
      externalID
      clincialComments
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseStatus
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      employeeID
      orderNo
      providerID
      clientID
      labID
      labName
      clientName
      providerName
      siteID
      site_name
      employee_demographics
      testsToDo
      barcode
      diag_1
      diag_2
      diag_3
      diag_4
      diag_5
      diag_6
      diag_7
      diag_8
      diag_9
      diag_10
      diag_11
      diag_12
      proc_array
      provider
      orderDate
      testDoneDate
      status
      cancelledReason
      notes
      isActive
      isResultEmailed
      floorNo
      requestID
      receivedBy
      receivedByName
      receivedDate
      updatedBy
      updatedByName
      createdBy
      createdByName
      timezone
      eligibilityStatus
      location
      tdDate
      externalID
      clincialComments
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseStatus
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      employeeID
      orderNo
      providerID
      clientID
      labID
      labName
      clientName
      providerName
      siteID
      site_name
      employee_demographics
      testsToDo
      barcode
      diag_1
      diag_2
      diag_3
      diag_4
      diag_5
      diag_6
      diag_7
      diag_8
      diag_9
      diag_10
      diag_11
      diag_12
      proc_array
      provider
      orderDate
      testDoneDate
      status
      cancelledReason
      notes
      isActive
      isResultEmailed
      floorNo
      requestID
      receivedBy
      receivedByName
      receivedDate
      updatedBy
      updatedByName
      createdBy
      createdByName
      timezone
      eligibilityStatus
      location
      tdDate
      externalID
      clincialComments
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          orderID
          baseSiteID
          testTypeID
          batch
          barcode
          owner
          result
          resultDetails
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          isArchive
          arkstoneID
          arkstoneStatus
          sendToID
          sendToName
          sentToLabDate
          verifiedBy
          verifiedByName
          releaseStatus
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createUploadRequestMain = /* GraphQL */ `
  mutation CreateUploadRequestMain(
    $input: CreateUploadRequestMainInput!
    $condition: ModelUploadRequestMainConditionInput
  ) {
    createUploadRequestMain(input: $input, condition: $condition) {
      id
      filesStatus
      employeeID
      clientID
      siteID
      fileName
      fileType
      title
      uploadCallType
      status
      createdBy
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Items {
        items {
          id
          employeeID
          clientID
          siteID
          requestID
          fileID
          fileName
          fileType
          floorNo
          uploadCallType
          data
          status
          reason
          processingStatus
          createdBy
          createdByName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateUploadRequestMain = /* GraphQL */ `
  mutation UpdateUploadRequestMain(
    $input: UpdateUploadRequestMainInput!
    $condition: ModelUploadRequestMainConditionInput
  ) {
    updateUploadRequestMain(input: $input, condition: $condition) {
      id
      filesStatus
      employeeID
      clientID
      siteID
      fileName
      fileType
      title
      uploadCallType
      status
      createdBy
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Items {
        items {
          id
          employeeID
          clientID
          siteID
          requestID
          fileID
          fileName
          fileType
          floorNo
          uploadCallType
          data
          status
          reason
          processingStatus
          createdBy
          createdByName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteUploadRequestMain = /* GraphQL */ `
  mutation DeleteUploadRequestMain(
    $input: DeleteUploadRequestMainInput!
    $condition: ModelUploadRequestMainConditionInput
  ) {
    deleteUploadRequestMain(input: $input, condition: $condition) {
      id
      filesStatus
      employeeID
      clientID
      siteID
      fileName
      fileType
      title
      uploadCallType
      status
      createdBy
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Items {
        items {
          id
          employeeID
          clientID
          siteID
          requestID
          fileID
          fileName
          fileType
          floorNo
          uploadCallType
          data
          status
          reason
          processingStatus
          createdBy
          createdByName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createUploadRequest = /* GraphQL */ `
  mutation CreateUploadRequest(
    $input: CreateUploadRequestInput!
    $condition: ModelUploadRequestConditionInput
  ) {
    createUploadRequest(input: $input, condition: $condition) {
      id
      employeeID
      clientID
      siteID
      requestID
      fileID
      fileName
      fileType
      floorNo
      uploadCallType
      data
      status
      reason
      processingStatus
      createdBy
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUploadRequest = /* GraphQL */ `
  mutation UpdateUploadRequest(
    $input: UpdateUploadRequestInput!
    $condition: ModelUploadRequestConditionInput
  ) {
    updateUploadRequest(input: $input, condition: $condition) {
      id
      employeeID
      clientID
      siteID
      requestID
      fileID
      fileName
      fileType
      floorNo
      uploadCallType
      data
      status
      reason
      processingStatus
      createdBy
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUploadRequest = /* GraphQL */ `
  mutation DeleteUploadRequest(
    $input: DeleteUploadRequestInput!
    $condition: ModelUploadRequestConditionInput
  ) {
    deleteUploadRequest(input: $input, condition: $condition) {
      id
      employeeID
      clientID
      siteID
      requestID
      fileID
      fileName
      fileType
      floorNo
      uploadCallType
      data
      status
      reason
      processingStatus
      createdBy
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGeneralSettingType = /* GraphQL */ `
  mutation CreateGeneralSettingType(
    $input: CreateGeneralSettingTypeInput!
    $condition: ModelGeneralSettingTypeConditionInput
  ) {
    createGeneralSettingType(input: $input, condition: $condition) {
      id
      clientID
      code
      title
      label
      value
      isActive
      settingType
      updatedBy
      updatedByName
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGeneralSettingType = /* GraphQL */ `
  mutation UpdateGeneralSettingType(
    $input: UpdateGeneralSettingTypeInput!
    $condition: ModelGeneralSettingTypeConditionInput
  ) {
    updateGeneralSettingType(input: $input, condition: $condition) {
      id
      clientID
      code
      title
      label
      value
      isActive
      settingType
      updatedBy
      updatedByName
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGeneralSettingType = /* GraphQL */ `
  mutation DeleteGeneralSettingType(
    $input: DeleteGeneralSettingTypeInput!
    $condition: ModelGeneralSettingTypeConditionInput
  ) {
    deleteGeneralSettingType(input: $input, condition: $condition) {
      id
      clientID
      code
      title
      label
      value
      isActive
      settingType
      updatedBy
      updatedByName
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUsers = /* GraphQL */ `
  mutation CreateUsers(
    $input: CreateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    createUsers(input: $input, condition: $condition) {
      id
      roles
      firstName
      lastName
      note
      email
      personalisation
      phone_number
      userRole
      clientID
      connectedID
      labID
      providerID
      status
      systemUser
      appType
      userPermission
      is2FaEnabled
      userID
      labAdmin
      createdBy
      createdByName
      updatedBy
      updatedByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUsers = /* GraphQL */ `
  mutation UpdateUsers(
    $input: UpdateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    updateUsers(input: $input, condition: $condition) {
      id
      roles
      firstName
      lastName
      note
      email
      personalisation
      phone_number
      userRole
      clientID
      connectedID
      labID
      providerID
      status
      systemUser
      appType
      userPermission
      is2FaEnabled
      userID
      labAdmin
      createdBy
      createdByName
      updatedBy
      updatedByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers(
    $input: DeleteUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    deleteUsers(input: $input, condition: $condition) {
      id
      roles
      firstName
      lastName
      note
      email
      personalisation
      phone_number
      userRole
      clientID
      connectedID
      labID
      providerID
      status
      systemUser
      appType
      userPermission
      is2FaEnabled
      userID
      labAdmin
      createdBy
      createdByName
      updatedBy
      updatedByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMessageTemplate = /* GraphQL */ `
  mutation CreateMessageTemplate(
    $input: CreateMessageTemplateInput!
    $condition: ModelMessageTemplateConditionInput
  ) {
    createMessageTemplate(input: $input, condition: $condition) {
      id
      clientID
      companyID
      subAgentID
      name
      category
      subject
      message
      isActive
      criteria
      updatedBy
      updatedByName
      createdBy
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMessageTemplate = /* GraphQL */ `
  mutation UpdateMessageTemplate(
    $input: UpdateMessageTemplateInput!
    $condition: ModelMessageTemplateConditionInput
  ) {
    updateMessageTemplate(input: $input, condition: $condition) {
      id
      clientID
      companyID
      subAgentID
      name
      category
      subject
      message
      isActive
      criteria
      updatedBy
      updatedByName
      createdBy
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMessageTemplate = /* GraphQL */ `
  mutation DeleteMessageTemplate(
    $input: DeleteMessageTemplateInput!
    $condition: ModelMessageTemplateConditionInput
  ) {
    deleteMessageTemplate(input: $input, condition: $condition) {
      id
      clientID
      companyID
      subAgentID
      name
      category
      subject
      message
      isActive
      criteria
      updatedBy
      updatedByName
      createdBy
      createdByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
