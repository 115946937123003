import { TEST_TYPE_OPTIONS, DISCLAIMER_TYPE, DISCLAIMER_KEYS } from "constant";
import React, { useState, useEffect, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { generateRefInterval } from "utils";
import TestDetailsSelector from "./TestDetailsSelector";
import MainTable from "components/Table/MainTable";
import { CREATE_TEST_PERSONALIZE } from "constants/personalization";
import Icon from "components/Icon";
import { AppContext } from "context/app-context";
import TextEditor from "views/TextEditor";
import { REQ_FORM_TESTS_NAME } from "biolabRequisitionFormConstant";
import TestInputField from "./TestInputField";
import { TABLE_QUICK_TOOLS } from "constant";
import TestSelector from "./TestUnitSelector/TestSelector";
import CptList from "components/CPT/CptList";
import { CPT_CODE_SEARCH_TYPE } from "constant";
import moment from "moment";
import InputField from "components/InputField/InputField";
import LabelIdSelector from "./LabelIDSelector/LabelIdSelector";
import { TEST_SETTING_TYPES } from "constant";
import ProcedureCodeDetailView from "./ProcedureCode/ProcedureCodeDetailView";
import { renderSourcesCell } from "utils";
import { formatValueFloatInput } from "utils";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import { getUniqueSources } from "utils";
import { getTestPanelType } from "utils";
import { formatTargetRanges } from "utils";
import { TYPE_OF_TEST } from "constant";

const TestTypeModal = ({ item, handleSave, handleClose, openDetailModal, mainTests, setTestPanelData }) => {
  const [newItem, setNewItem] = useState({
    ...item,
    isAssociatedPanel: item.hasOwnProperty("isAssociatedPanel") ? item.isAssociatedPanel : true,
    custom_notes: item.custom_notes || "",
  });

  const [ictCodes, setIctCodes] = useState([]);

  const quickTools = [TABLE_QUICK_TOOLS.edit];

  const appContext = useContext(AppContext);

  const [showEditor, setShowEditor] = useState(null);

  const [showDiagModal, setShowDiagModal] = useState(false);
  const [showProcedureCodeModal, setShowProcedureCodeModal] = useState(false);

  const [cptList, setCptList] = useState([]);

  useEffect(() => {
    const { ictCodes, cptCodes } = item;

    if (ictCodes?.length > 0) {
      setIctCodes(ictCodes);
      setCptList(ictCodes[0]?.cptCodes || cptCodes || []);
    } else if (cptCodes?.length > 0) {
      setCptList(cptCodes);
    }
  }, []);

  useEffect(() => {
    const { targetRanges } = item;

    setNewItem((prevNewItem) => ({
      ...prevNewItem,
      targetRanges: targetRanges ? targetRanges.slice() : [],
    }));
  }, [item]);

  const validateInput = () => {
    if (!newItem.name) {
      appContext.showErrorMessage("Enter valid test name");

      return;
    }
    if (!newItem.code) {
      appContext.showErrorMessage("Enter valid test code");
      return;
    }
    if (!newItem.category) {
      appContext.showErrorMessage("Select the department");
      return;
    }

    if (!newItem.typeOfTest) {
      appContext.showErrorMessage("Select the valid Result Methodology");
      return;
    }
    if (!newItem.isAssociatedPanel && !newItem.units) {
      appContext.showErrorMessage("Select test unit");
      return;
    }
    if (!newItem.isAssociatedPanel && !newItem.sources) {
      appContext.showErrorMessage("Select test sources");
      return;
    }
    if (newItem.code && newItem.code.length > 4) {
      appContext.showErrorMessage("Test Code should not be greater then 4");
      return;
    }
    if (newItem.isAssociatedPanel && newItem.targetRanges?.length === 0) {
      appContext.showErrorMessage("Add Atleast One Pathogen.");
      return;
    }
    const formatedTargetRanges = formatTargetRanges(
      newItem.targetRanges,
      newItem.typeOfTest,
      newItem.isAssociatedPanel
    );

    const refInt = generateRefInterval(newItem);
    const uniqueSources = getUniqueSources(newItem.targetRanges);
    const testObj = {
      ...newItem,
      sources: newItem.isAssociatedPanel ? uniqueSources : newItem.sources,
      ictCodes: ictCodes && ictCodes.length > 0 ? [{ ...ictCodes[0], cptCodes: cptList }] : [],
      refInterval: newItem?.typeOfTest === TYPE_OF_TEST.QUANTITATIVE ? refInt : "Not Detected",
      targetRanges: formatedTargetRanges,
      minValue: newItem?.typeOfTest === TYPE_OF_TEST.QUANTITATIVE ? newItem.minValue : 0,
      maxValue: newItem?.typeOfTest === TYPE_OF_TEST.QUANTITATIVE ? newItem.maxValue : 0,
      type: getTestPanelType(newItem.typeOfTest, newItem.isAssociatedPanel),
      cptCodes: cptList,
      isActive: true,
      showDisclaimer: newItem.showDisclaimer,
    };

    if (newItem.isNew) {
      const isAlreadyExist = mainTests.some(({ name }) => name.toLowerCase().includes(newItem.name.toLowerCase()));
      if (isAlreadyExist) {
        appContext.showErrorMessage("Test with same name already exists");
        return;
      }
    }

    handleSave(testObj);
  };

  const customRenderTD = (item, row, index) => {
    if (item.itemKey === "action") {
      return (
        <td key={`bin_${item.id}`} style={{ textAlign: "right" }}>
          <Icon
            iconType={"binIcon"}
            containerClass={"ms-2 cursor-pointer"}
            innerClass={""}
            imageClass={"testTabelDeleteIcon"}
            handleClick={() => handleTestRemove(row)}
          />
        </td>
      );
    }
    if (item.itemKey === "units") {
      return <td key={`unit_${item.id}`}>{row?.units?.label ? row.units.label : ""}</td>;
    }
    if (item.itemKey === "sources") {
      return renderSourcesCell(item, row);
    }
  };

  const handleTestRemove = (item) => {
    setNewItem((prevNewItem) => {
      const updatedTargetRanges = prevNewItem?.targetRanges?.filter((x) => x.id !== item.id);
      return { ...prevNewItem, targetRanges: updatedTargetRanges };
    });
  };

  const handleTestSelect = (item) => {
    setNewItem((prevNewItem) => {
      const itemExists = prevNewItem?.targetRanges?.some((x) => x.id === item.id);

      const updatedTargetRanges = itemExists ? prevNewItem.targetRanges : [...prevNewItem.targetRanges, item];

      return { ...prevNewItem, targetRanges: updatedTargetRanges };
    });
  };

  // const saveDiagnosisCodes = async (cpt) => {
  //   setIctCodes(cpt);
  //   setShowDiagModal(false);
  //   if (!newItem.hasOwnProperty("isNew")) {
  //     await api.updateTestTypes({ ...newItem, ictCodes: cpt });
  //   }
  // };

  const handleDisclaimerSave = (val, type) => {
    setShowEditor(null);
    setNewItem({ ...newItem, custom_notes: val });
  };

  const handelAddTestName = (selectedTest) => {
    if (selectedTest) {
      setNewItem({ ...newItem, name: selectedTest.label, code: selectedTest.value });
    }
  };

  const handleInputChange = (inputValue) => {
    if (inputValue) {
      setNewItem({ ...newItem, name: inputValue });
    }
  };

  const handleInputValueChange = (field, value) => {
    setNewItem((prev) => ({ ...prev, [field]: value }));
  };

  const handleEditCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.edit:
        handleEditAssociatedPanels(row);
        break;
      default:
        break;
    }
  };

  const handleEditAssociatedPanels = (accPanel) => {
    setTestPanelData({
      isNew: false,
      ...accPanel,
      typeOfTest: newItem.typeOfTest,
      currentRanges: newItem.targetRanges,
    });
    openDetailModal();
  };

  const handelEffectiveDate = (e) => {
    const effectiveDate = moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).format("YYYY-MM-DD") : "";

    setNewItem({ ...newItem, setting: { ...newItem.setting, effectiveDate } });
  };

  const handleDiagCode = (e) => {
    if (!e) {
      setIctCodes([]);
      return;
    }
    setIctCodes([e.ictCodes[0]]);
  };
  const handleCptCode = (e) => {
    const cptObj = e.cptCodes[0];
    const isAlreadyExist = cptList.some((item) => item.code === cptObj.code);
    if (!isAlreadyExist) {
      setCptList([...cptList, cptObj]);
    }
  };

  const removeCptCode = (index) => {
    setCptList((prevItems) => {
      const newItems = [...prevItems];
      newItems.splice(index, 1);
      return newItems;
    });
  };

  const handleChangeLowHighValue = (event) => {
    const { name, value } = event.target;

    const formattedValue = formatValueFloatInput(value);
    setNewItem((prevItem) => ({ ...prevItem, [name]: Number(formattedValue) }));
  };

  const handelOpenDetailModal = () => {
    setTestPanelData({ isNew: true, typeOfTest: newItem?.typeOfTest || "Quantitative" });
    openDetailModal();
  };

  const handelLoincCode = (e) => {
    const value = e.target.value;

    const numericValue = value.replace(/[^0-9]/g, "");

    setNewItem({ ...newItem, lonicCode: numericValue });
  };

  return (
    <Modal
      show
      // style={{ transform: "translate(0, -30%)" }}
      animation={true}
      onHide={() => handleClose()}
      centered
      size={"2xl"}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 h4" id="contained-modal-title-vcenter">
          Test and Assay Management
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <p style={{ fontSize: "24px", margin: "0px", padding: "0px" }}>Test Details</p>
        <div style={{ display: "flex", gap: "16px" }}>
          {/* First Column */}
          <div style={{ flex: 1 }}>
            {/* <div>
              <p className="modalLineHeaders">
                Test Name
                <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                className="w-100 siteSelector"
                options={REQ_FORM_TESTS_NAME}
                blurInputOnSelect={true}
                defaultValue={null}
                menuPlacement="auto"
                value={(() => {
                  const foundItem = REQ_FORM_TESTS_NAME.find((item) => item.label === newItem.name);
                  return foundItem ? foundItem : newItem.name ? { label: newItem.name, value: newItem.code } : null;
                })()}
                placeholder="Test Name"
                onChange={handelAddTestName}
                tabIndex={1}
                onInputChange={handleInputChange}
                required={true}
              />
            </div> */}
            <p className="modalLineHeaders">
              Test Name
              <span style={{ color: "red" }}>*</span>
            </p>
            <input
              className="modalInput"
              value={newItem.name}
              placeholder="Enter Test Name"
              style={{ width: "100%" }}
              onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
              tabIndex={2}
            />
            {!newItem?.isAssociatedPanel && (
              <TestSelector
                value={newItem.sources}
                tabIndex={3}
                onChange={(e) => setNewItem({ ...newItem, sources: e })}
                settingType={TEST_SETTING_TYPES.SOURCE}
                label={"Source"}
                required={true}
                isMulti
                title={"Create Source"}
                placeholder="Enter Source Name"
                updateMainTest={(value) => setNewItem({ ...newItem, sources: { label: value, value: value } })}
              />
            )}

            <p className="modalLineHeaders">
              Result Type
              <span style={{ color: "red" }}>*</span>
            </p>
            <Select
              className="w-100 siteSelector"
              options={TEST_TYPE_OPTIONS}
              blurInputOnSelect={true}
              defaultValue={null}
              menuPlacement="auto"
              value={newItem.typeOfTest ? TEST_TYPE_OPTIONS.find((f) => f.label === newItem.typeOfTest) : null}
              placeholder="Result Type"
              onChange={(e) =>
                setNewItem({
                  ...newItem,
                  typeOfTest: e.label,
                })
              }
              tabIndex={5}
            />
            <TestSelector
              value={newItem.category ? { label: newItem.category, value: newItem.category } : null}
              tabIndex={4}
              required={true}
              onChange={(e) => setNewItem({ ...newItem, category: e.label })}
              settingType={TEST_SETTING_TYPES.DEPARTMENT}
              label={"Department"}
              title={"Create Department"}
              placeholder="Enter Department Name"
              updateMainTest={(value) => setNewItem({ ...newItem, category: value })}
            />

            <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
              <p className="testDetailsSelector" style={{ marginRight: "15px" }}>
                Does Test Have Pathogen
                {/* <span style={{ color: "red" }}>*</span> */}
              </p>

              <SwitchToggle
                checked={newItem?.isAssociatedPanel}
                handleChange={(e) => setNewItem({ ...newItem, isAssociatedPanel: e.target.checked })}
              />
            </div>

            <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
              <p className="testDetailsSelector" style={{ marginRight: "15px" }}>
                Enable Arkstone
              </p>

              <SwitchToggle
                checked={newItem?.isARKStone}
                handleChange={(e) => setNewItem({ ...newItem, isARKStone: e.target.checked })}
              />
            </div>

            {/* 
            {newItem?.typeOfTest && newItem?.typeOfTest === "Qualitative" && (
              <>
                <p className="modalLineHeaders">Possible Values</p>
                <Select
                  className="w-100 siteSelector"
                  options={RESULT_SETTINGS_OPT}
                  blurInputOnSelect={true}
                  defaultValue={null}
                  menuPlacement="auto"
                  isMulti
                  value={newItem.possibleValues}
                  placeholder="Select Possible Result"
                  onChange={handleSelectPossibleResults}
                />
              </>
            )} */}
          </div>

          {/* Second Column */}
          <div style={{ flex: 1 }}>
            <p className="modalLineHeaders">
              Internal Test Code
              <span style={{ color: "red" }}>*</span>
            </p>
            <input
              className="modalInput"
              value={newItem.code}
              placeholder="Internal Code"
              style={{ width: "100%" }}
              onChange={(e) => {
                const value = e.target.value;
                setNewItem({ ...newItem, code: value });
              }}
              tabIndex={2}
            />

            {!newItem?.isAssociatedPanel && (
              <TestSelector
                value={newItem.units ? { label: newItem.units, value: newItem.units } : null}
                tabIndex={6}
                required={true}
                onChange={(e) => setNewItem({ ...newItem, units: e.label })}
                settingType={TEST_SETTING_TYPES.UNIT}
                label={"Units"}
                title={"Create Unit"}
                placeholder="Enter Unit Name"
                updateMainTest={(value) => setNewItem({ ...newItem, units: value })}
              />
            )}

            <InputField
              type="datePicker"
              inputStyle="w-100 orderTestDob"
              labelStyle="modalLineHeaders mt-0 text-capitalize"
              label="Effective Date"
              index="ed"
              placeholder="Effective Date"
              disabled={false}
              groupWrapper={`simple-form-group-wrapper mt-1`}
              handleChange={handelEffectiveDate}
              value={newItem?.setting?.effectiveDate || moment().format("YYYY-MM-DD")}
              isFutureDateAllowed={true}
            />
            <LabelIdSelector
              value={newItem?.setting?.labelID || ""}
              tabIndex={3}
              onChange={(e) => setNewItem({ ...newItem, setting: { ...newItem.setting, labelID: e } })}
              updateMainTest={(value) =>
                setNewItem({
                  ...newItem,
                  setting: { ...newItem.setting, labelID: { label: value.code, value: value.code } },
                })
              }
              required={true}
            />

            {/* <TestTextAreaField
              label="Reagent"
              value={newItem?.setting?.reagent || ""}
              onChange={(e) => setNewItem({ ...newItem, setting: { ...newItem.setting, reagent: e.target.value } })}
            
            /> */}

            {/* {newItem?.typeOfTest && newItem?.typeOfTest === "Qualitative" && (
              <>
                <p className="modalLineHeaders">Reference Interval</p>
                <Select
                  className="w-100 siteSelector"
                  options={RESULT_SETTINGS_OPT}
                  blurInputOnSelect={true}
                  defaultValue={null}
                  menuPlacement="auto"
                  value={newItem.refInterval ? RESULT_SETTINGS_OPT.find((f) => f.label === newItem.refInterval) : null}
                  placeholder="Select Possible Result"
                  onChange={(e) => setNewItem({ ...newItem, refInterval: e })}
                />
              </>
            )} */}

            {showEditor && (
              <TextEditor
                text={showEditor.message ?? ""}
                handleSave={handleDisclaimerSave}
                handleClose={() => setShowEditor(null)}
                type={showEditor.type}
              />
            )}

            {/* {showDiagModal && (
              <DiagnosisAccordionView
                handleClose={() => setShowDiagModal(false)}
                handelSaveDiagnosisPanel={saveDiagnosisCodes}
                ictList={ictCodes}
                setIctList={setIctCodes}
                // secondModalClass="secondModal"
              />
            )} */}

            {/* {showProcedureCodeModal && (
              <ProcedureCodeView
                handleClose={() => setShowProcedureCodeModal(false)}
                cptCodes={cptList}
                saveCptCodes={(data) => {
                  setCptList(data);
                  setShowProcedureCodeModal(false);
                }}
              />
            )} */}
          </div>

          {/* Third Column */}
          <div style={{ flex: 1 }}>
            <CptList
              item={ictCodes.length > 0 ? ictCodes[0] : {}}
              setItem={handleDiagCode}
              title="ICD / Diagnosis Code"
              searchtype={CPT_CODE_SEARCH_TYPE.ICT}
              groupWrapper="mt-1 form-group-wrapper margin-bottom-1"
              showLabelOnTop={true}
              showInput={true}
              showLabel={true}
              showCross={false}
            />
            <div style={{ border: "1px solid gray", marginTop: "5px", borderRadius: "5px" }}>
              <h4
                style={{
                  margin: "0px",
                  // background: "lightGray",
                  borderTopLeftRadius: "5px",
                  borderTopRightRadius: "5px",
                  padding: "10px",
                }}
              >
                Linked CPT Code {cptList.length > 0 ? `(${cptList.length})` : ""}
              </h4>
              <div style={{ padding: "10px" }}>
                <CptList
                  item={{}}
                  setItem={handleCptCode}
                  title="Lookup Procedure Code"
                  searchtype={CPT_CODE_SEARCH_TYPE.CPT}
                  groupWrapper="form-group-wrapper margin-bottom-1"
                  showLabelOnTop={true}
                  showInput={false}
                />
                {cptList && cptList.length > 0 && (
                  <ProcedureCodeDetailView cptCodeList={cptList} handelDeleteCpt={removeCptCode} />
                )}
              </div>
            </div>

            {/* <div className="createShowWrapper h4 ">
              <div> Diagnosis and CPT:</div>
              <div onClick={() => setShowDiagModal(true)} className="text-primary cursor-pointer">
                Manage
              </div>
            </div> */}

            {/* <div className="createShowWrapper h4 ">
              <div>Procedure Code:</div>
              <div onClick={() => setShowProcedureCodeModal(true)} className="text-primary cursor-pointer">
                Manage
              </div>
            </div> */}

            <div
              className="createShowWrapper h4 "
              style={{
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <div>Result Disclaimer:</div>
                {/* <span style={{ color: "red" }}>*</span> */}
              </div>

              <div style={{ width: "200px", display: "flex", alignItems: "center" }}>
                <SwitchToggle
                  checked={newItem?.custom_notes || newItem?.showDisclaimer ? true : false}
                  handleChange={(e) => setNewItem({ ...newItem, showDisclaimer: e.target.checked })}
                  disabled={newItem?.custom_notes?.trim() !== ""}
                />
                {(newItem?.showDisclaimer || newItem?.custom_notes) && (
                  <div
                    onClick={() => setShowEditor({ type: DISCLAIMER_TYPE.CUSTOM, message: newItem?.custom_notes })}
                    className="text-primary cursor-pointer "
                    style={{ marginLeft: "10px" }}
                  >
                    {newItem.custom_notes ? "Edit Text" : "Add Text"}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <p style={{ fontSize: "24px", margin: "0px", padding: "0px" }}>Additional Test Info</p>
        <div style={{ display: "flex", gap: "16px" }}>
          <div style={{ flex: 1 }}>
            <TestInputField
              label="Specimen Requirement"
              value={newItem?.setting?.requirements || ""}
              type="text"
              onChange={(e) =>
                setNewItem({ ...newItem, setting: { ...newItem.setting, requirements: e.target.value } })
              }
            />

            {newItem?.typeOfTest && newItem?.typeOfTest === "Quantitative" && !newItem.isAssociatedPanel && (
              <TestInputField
                label="Low Value"
                value={newItem.minValue}
                name="minValue"
                onChange={handleChangeLowHighValue}
              />
            )}
            <TestInputField
              label="Reagent Used"
              value={newItem?.setting?.reagent || ""}
              onChange={(e) => setNewItem({ ...newItem, setting: { ...newItem.setting, reagent: e.target.value } })}
            />
          </div>

          <div style={{ flex: 1 }}>
            <TestInputField label="LOINC Code" value={newItem?.lonicCode || ""} onChange={handelLoincCode} />

            {newItem?.typeOfTest && newItem?.typeOfTest === "Quantitative" && !newItem.isAssociatedPanel && (
              <TestInputField
                label="High Value"
                name="maxValue"
                value={newItem.maxValue}
                onChange={handleChangeLowHighValue}
              />
            )}
          </div>

          <div style={{ flex: 1 }}></div>
        </div>

        {newItem?.isAssociatedPanel && (
          <TestDetailsSelector
            label="Select Pathogen & Resistance Markers"
            openDetailModal={handelOpenDetailModal}
            onChange={handleTestSelect}
            mainTests={mainTests}
            selectedTest={newItem}
            value={""}
          />
        )}

        {newItem?.isAssociatedPanel && newItem?.targetRanges?.length > 0 && (
          <>
            <p className="mt-2 h4">Pathogens/Organisms</p>
            <div style={{ background: "#f2f0f0", paddingLeft: "4px", paddingRight: "4px" }}>
              <MainTable
                columns={CREATE_TEST_PERSONALIZE}
                rows={newItem?.targetRanges?.filter((item) => item?.antibioticResistanceType === "Organism") || []}
                customColumnCellRenderer={customRenderTD}
                // trDataWrapperHeader={"trDataWrapperHeader"}
                tools={quickTools}
                widthToSkip={155}
                handleCellClick={handleEditCellClick}
              />
            </div>
            {newItem?.targetRanges?.filter((item) => item?.antibioticResistanceType === "Resistance")?.length > 0 && (
              <>
                <p className="mt-2 h4">Reactive Markers</p>
                <div style={{ background: "#f2f0f0", paddingLeft: "4px", paddingRight: "4px" }}>
                  <MainTable
                    columns={CREATE_TEST_PERSONALIZE}
                    rows={newItem?.targetRanges?.filter((item) => item?.antibioticResistanceType === "Resistance") || []}
                    customColumnCellRenderer={customRenderTD}
                    // trDataWrapperHeader={"trDataWrapperHeader"}
                    tools={quickTools}
                    widthToSkip={155}
                    handleCellClick={handleEditCellClick}
                  />
                </div>
              </>
            )}
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" className="modalButtons" onClick={() => handleClose()}>
          Close
        </Button>

        <Button variant="primary" className="modalButtons" onClick={validateInput}>
          {item.isNew ? "Create" : "Update"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TestTypeModal;
