import React, { useEffect, useState, useContext } from "react";
import { Button, Modal, Card, Table, Row, Col, Dropdown } from "react-bootstrap";
import MainTable from "components/Table/MainTable";
import API from "api";
import { CONFIG } from "constant";
import { useDispatch, useSelector } from "react-redux";
import { setTestPrintView } from "../../store/features/general/generalSlice";
import { AppContext } from "context/app-context";
import { personalisationInLC, SYSTEM_TIME_ZONE, formattedString } from "utils";
import { PERSONALISATION_KEY, MESSAGE_MODES, TABLE_QUICK_TOOLS, ORDER_STATUS } from "constant";
import Icon from "components/Icon";
import Status from "components/Status/Status";
import chart_Icon from "../../../src/assets/img/chart-simple-solid.png";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import GQAPI from "views/GraphQL/gqApi";
import Loader from "components/Loader/Loader";
import api from "api";
import moment from "moment";
import { setMessage } from "store/features/general/generalAction";
import "../../../src/tableStyling.css";
import { selectedDeviceSelector } from "store/features/general/generalSelectors";
import { t } from "stringConstants";
import { showTestAssociatePanelAction } from "store/features/testDictionary/testDictionaryActions";
import RenderAssociatePanel from "./RenderAssoicatePanel";
import Select from "react-select";
import { getTestSource, formatDateMDY } from "utils";
import { ReactSelectCustomContainer } from "components/CustomReactSelect/ReactSelectCustomOptionContainer";
import FilterSelect from "components/FilterSelect";
import Filter from "components/Filter";
import { showResultPdf } from "store/features/resultPdf/reslutPdfSlice";
import { GET_CUST_COLOR } from "constant";
import { TEST_HISTORY_MODAL_COLUMN } from "constants/personalization";
import { TEST_DONE_MODAL_COLUMN_DETAILS } from "constants/personalization";
import { filterSourcesBySwabType } from "utils";
import { parseJSONObject } from "utils";
import MainTableInputBox from "components/MainTableInputBox/MainTableInputBox";
import { LAB_ID } from "constant";
import { isLocalhost } from "utils";
import { showResultReqForm } from "store/features/resultPdf/reslutPdfSlice";
import { TEST_COLUMNS_FOR_PROVIDER } from "constants/personalization";
import DropDown from "components/Modal/DropDown";

const TestDoneModal = (props) => {
  const { patient, order, showResult, handleClose, uiType } = props;

  const appContext = useContext(AppContext);

  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(false);

  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [allSelected, setAllSelected] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [timeFilter, setTimeFilter] = useState({});
  const [filter, setFilter] = useState({});
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [personalize, setPersonalize] = useState([]);
  const [checkboxes, setCheckboxes] = useState([]);
  const selectedDevice = useSelector(selectedDeviceSelector);

  const [isShowSendOutOptions, setIsShowSendOutOptions] = useState(false);
  const [selectedSendOutObj, setSelectedSendOutObj] = useState({});

  const sendOutOptions = [{ label: "Magic Owl", value: "850180f3-9cb3-4752-be73-9bc7af1fbb14" }];
  const dispatch = useDispatch();

  useEffect(() => {
    loadTestData();
    if (appContext?.user?.isSite()) {
      setPersonalize(TEST_COLUMNS_FOR_PROVIDER);
    } else if (uiType && uiType === "testHistory") {
      setPersonalize(TEST_HISTORY_MODAL_COLUMN);
    } else {
      setPersonalize(TEST_DONE_MODAL_COLUMN_DETAILS);
    }
  }, []);

  const getStatus = (testData) => {
    if (testData?.sendToID) return "Pending";
    if (testData.collectedDate) return "Processed";
    else if (testData.testStatus === "Rejected") return "Rejected";
    return "Pending";
  };

  const loadTestData = async () => {
    if (patient) {
      const tests = await API.getAdminTestList(patient.mdID);

      setTests(
        tests
          .filter((t) => {
            if (t._deleted) return false;
            if (CONFIG.siteID) t.site !== CONFIG.siteID;
            return true;
          })
          .map((item) => {
            return {
              ...item,
              testStatus: item?.status?.toLowerCase() === "pending" ? "Processed" : item.status,
              collectedDate: item.createdAt,
              testDate: item?.employee_demographics?.orderDate || item?.order?.orderDate || item.createdAt,
              comment: item?.employee_demographics?.comment,
            };
          })
      );
    } else if (order) {
      const testsToDo = order.testsToDo || {};
      const test = testsToDo.test || [];
      let testsDone = [];
      const ids = test.filter((t) => t.testID).map((t) => t.testID);
      if (ids.length > 0) testsDone = await API.getMultipleTests({ ids });

      const testWithDate = test.map((t) => {
        const testDoneObj = testsDone.find((obj) => obj.id === t.testID);
        const dateOfBirth = order.dob || patient.dob;
        const orderDate = order.createdAt;
        // createdAt
        if (testDoneObj) {
          return {
            ...testDoneObj,
            testDate: order.orderDate,
            collectedDate: testDoneObj.createdAt,
            testStatus: testDoneObj?.status?.toLowerCase() === "pending" ? "Processed" : testDoneObj.status,
            testTypeID: testDoneObj?.employee_demographics?.testTypeID || "",
            dob: dateOfBirth,
            orderDate,
          };
        }

        return {
          ...t,
          testStatus: getStatus(t),
          collectedDate: "",
          test_type: t.label,
          barcode: "",
          testDate: order.orderDate,
          rejectedReason: t.rejectedReason,
          testTypeID: t.id,
          dob: dateOfBirth,
          orderDate,
          selectedSource: t?.sources?.slice(0, 1),
        };
      });
      // Check if any tests are not rejected
      if (testWithDate && testWithDate?.length > 0) {
        setTests(testWithDate || []);
      }
    }
  };

  const handleConfirm = async (isRejectionConfirm, reason) => {
    setOpenDeleteConfirmation(false);
    if (!isRejectionConfirm) {
      return;
    }
    setLoading(true);

    const updatedtests = tests.map((t) => {
      const updatedTest = {
        ...t,
        testStatus: getStatus(t),
      };

      if (checkboxes.includes(t.id)) {
        if (!t.collectedDate && t.testStatus !== "Rejected") {
          updatedTest.rejectedReason = reason;
          updatedTest.testStatus = "Rejected";
        }
      }

      return updatedTest;
    });

    setTests(updatedtests);

    await GQAPI.updateRejcetedOrder({ dbOrder: order, selectedTestIds: checkboxes, reason, user: appContext?.user });

    handlePrintClick();

    setLoading(false);

    // appContext.showSuccessMessage(`Selcted Test has been rejected`);
  };

  const getNoteName = () => {
    if (patient) return patient?.firstName;
    if (order) return `${order?.firstName} ${order?.lastName}`;
    return "";
  };

  const handleUpdateNoteReocrd = async (row) => {
    try {
      // await gqApi.updateOrderStatus(row);
      setTests((prevTests) => {
        const prevIndex = prevTests.findIndex((t) => t.id === row.id);
        if (prevIndex !== -1) {
          prevTests[prevIndex].notes = row.note;
        }
        return [...prevTests];
      });
      appContext.showSuccessMessage("Note Added Successfully!");
    } catch (err) {
      console.log("Error:-", err);
    }
  };

  const handleCellClick = async (item, row, event) => {
    if (!row) return;
    switch (item) {
      case "result":
        if (row.result || row.resultDetails) {
          if (row.labID === LAB_ID.alta) {
            dispatch(showResultPdf(row));
          } else {
            const testDetails = await API.getTestByID(row.id, "test");

            if (testDetails?.arkstoneID) {
              dispatch(showResultPdf(row));
              return;
            }
            showResult(testDetails);
          }
        }
        break;
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row);
        break;
      case TABLE_QUICK_TOOLS.notes:
        if (row?.employee_demographics) {
          appContext.openNoteModal({
            ...row,
            objectType: "test",
            title: getNoteName(),
          });
        } else {
          appContext.showErrorMessage("Only Processed Test Can Add Notes");
        }
        break;
    }
  };

  const showReqForm = async (row) => {
    if (row.barcode) {
      if (row.labID === LAB_ID.alta || row.labID === LAB_ID.discovery || isLocalhost()) {
        dispatch(showResultReqForm(row));
        return;
      }
      const testDetails = await API.getTestByID(row.id, "test");
      if (testDetails?.arkstoneID) {
        dispatch(showResultReqForm(row));
        return;
      }
      appContext.showRequisitionForm({ testID: row.id });
    }
  };

  // const showReqForm = (row) => {
  //   if (row.barcode) {
  //     appContext.showRequisitionForm({ testID: row.id });
  //   } else {
  //   }
  // };

  const toggleCheckboxes = (val) => setCheckboxes(val ? tests?.map((t) => t.id) : []);

  const [selectedSources, setSelectedSources] = useState({});
  const handleSelect = (e, row) => {
    const updatedItem = { ...row, selectedSource: e };
    const updatedTests = tests.map((item) => (item.id === row.id ? { ...item, ...updatedItem } : item));

    setTests(updatedTests);
  };

  const handleDifferentStatus = (e, row) => {
    const updatedItem = { ...row, testStatus: e.value };
    const updatedTests = tests.map((item) => (item.id === row.id ? { ...item, ...updatedItem } : item));
    setTests(updatedTests);

    if (e.value === "sendOut") {
      setIsShowSendOutOptions(true);
    }
  };

  const handleCheckboxChange = (e, user) => {
    const filteredList = checkboxes.filter((c) => c !== user.id);
    if (e.target.checked) {
      filteredList.push(user.id);
    }
    setCheckboxes(filteredList);
  };

  const handlePrintOptions = () => {
    const rows = tests?.filter((test) => checkboxes.includes(test.id));
    dispatch(setTestPrintView(rows));
  };
  const quickTools = [TABLE_QUICK_TOOLS.checkbox, TABLE_QUICK_TOOLS.notes];

  const handleAllPrintTests = () => {
    let testToPerformList = [];

    testToPerformList = tests.filter((t) => checkboxes.includes(t.id));

    const hasRejectedTest = testToPerformList.some((t) => t.testStatus === "Rejected" && !t.collectedDate);

    if (hasRejectedTest) {
      setOpenDeleteConfirmation(true);
      return;
    }
    handlePrintClick();
  };

  const handlePrintClick = async (row) => {
    setLoading(true);
    let testToPerformList = [];

    if (row?.id) {
      testToPerformList.push(row);
    } else {
      testToPerformList = tests.filter((t) => checkboxes.includes(t.id));
    }

    if (!testToPerformList.every((t) => t.barcode || t.selectedSource?.length > 0)) {
      dispatch(setMessage("Please select Test Source", MESSAGE_MODES.error));
      setLoading(false);
      setCheckboxes([]);
      return;
    }

    const bracodeList = testToPerformList.filter((t) => t.barcode);

    if (bracodeList.length > 0) {
      bracodeList.forEach((item) => appContext.printBarcode(item));
      setCheckboxes([]);
      setLoading(false);
    }

    try {
      const { user = {}, userLabName } = appContext;

      const { eligibilityStatus, testsToDo } = order;

      const testsToPerform = testToPerformList
        .filter((t) => !t.barcode && t.testStatus !== "Rejected")
        .map((t) => {
          const itemSelectedSources = [...new Set(t.selectedSource.map((src) => src.label.trim()))].join(", ");
          return {
            ...t,
            isSelected: t.barcode ? false : true,
            selectedSource: itemSelectedSources,
            sendToID: selectedSendOutObj?.sendToID,
            sendToName: selectedSendOutObj?.sendToName,
          };
        });

      if (testsToPerform.length === 0) {
        setLoading(false);
        setCheckboxes([]);
        return;
      }

      const params = {
        labName: userLabName,
        order: {
          ...order,
          testsToDo: { test: testsToPerform },
          employee_demographics: {
            ...order.employee_demographics,
            dob: moment(order.employee_demographics.dob).format("MMDDYYYY"),
            worker: "",
            possibleexposure: "",
            confirmedexposure: "",
            relatedsymptoms: "",
            symptoms: "",
            othercomment: "",
            comment: "",
            docUploaded: false,
            isReview: false,
            venipuncture: false,
            distance: 0,
            isEligible: eligibilityStatus?.status?.toLowerCase() === "eligibile",
            stat: false,
          },
          labID: CONFIG.whiteLabelID,
          labName: userLabName,
          createSource: "portal",
          timezone: SYSTEM_TIME_ZONE,
          createdByPhone: user?.phone_number,
          testCreatedAt: moment().toISOString(),
          testDate: moment().format("YYMMDD"),
          createdBy: user?.sub,
          createdByName: user?.name,
          tester_name: user?.name,
        },
      };
      const res = await api.createMulipleTests(params);

      const updatedTests = tests.map((test) => {
        const processedTest = res[test.label]?.test;
        if (processedTest) {
          return {
            ...test,
            ...processedTest,
            dob: order.dob,
            testStatus: processedTest.status,
            testTypeID: test.id,
            testDate: order.orderDate,
            collectedDate: processedTest.createdAt,
            isDoneTest: true,
            isNewlyCreated: true,
            orderDate: order.createdAt,
          };
        }
        return test;
      });

      // tests for printings

      const testForPrinting = tests
        .filter((test) => res[test.label]?.test) // Filter out only the tests that have processedTest
        .map((test) => {
          const processedTest = res[test.label].test;
          return {
            ...test,
            ...processedTest,
            dob: order.dob,
            testStatus: processedTest.status,
            testTypeID: test.id,
            testDate: order.orderDate,
            collectedDate: processedTest.createdAt,
            isDoneTest: true,
            isNewlyCreated: true,
            orderDate: order.createdAt,
          };
        });

      setTests(updatedTests);

      // // Print barcodes for newly processed tests
      testForPrinting.forEach((test) => {
        if (selectedDevice) {
          if (test.barcode && test.isNewlyCreated && test.isDoneTest) {
            appContext.printBarcode(test);
          }
        }
      });

      setLoading(false);
      setCheckboxes([]);
    } catch (error) {
      console.log("Err", error);

      setLoading(false);
      setCheckboxes([]);
      dispatch(setMessage("Fail to print the Label", MESSAGE_MODES.error));
    }
  };

  const downloadRequisitionFile = async () => {
    try {
      await appContext.downloadRequisition({ filteredTests: tests, allSelected, checkboxes });
    } catch (error) {
      console.log("error", error);
      appContext.showErrorMessage("Fail to downlaod");
    }
  };

  const handleInputComment = (e, row) => {
    const updatedItem = {
      ...row,
      labComment: e.target.value,
    };

    const updatedTests = tests.map((item) => (item.id === row.id ? { ...item, ...updatedItem } : item));

    setTests(updatedTests);
  };

  const showAssociatedPanel = (test) => {
    dispatch(showTestAssociatePanelAction(test.testTypeID));
  };

  const formatTestStatus = (row) => {
    if (row.testStatus === "Rejected") {
      return { label: "Reject", value: "Rejected" };
    } else if (row?.sendToID || row.testStatus === "sendOut") {
      return { label: "Send Out", value: "sendOut" };
    }
    return { label: "Accept", value: "Accepted" };
  };

  const customRenderTD = (item, row, index) => {
    const isTestDone = !!row?.collectedDate;
    if (item.itemKey === "req_form") {
      return (
        <td onClick={() => showReqForm(row)} className="cursor-pointer text-center">
          <img src={chart_Icon} alt="chart Icon" width={20} height={20} />
        </td>
      );
    }
    if (item.itemKey === "name") {
      return (
        <td className="cursor-pointer text-center">
          {formattedString(row?.name?.toUpperCase() || row?.test_type?.toUpperCase() || "")}
        </td>
      );
    }
    if (item.itemKey === "print") {
      return (
        <td className="cursor-pointer text-center">
          <i
            className="fas fa-print mx-2 cursor-pointer"
            style={{
              color: !selectedDevice || row.testStatus === "Rejected" ? "grey" : "green",
              fontSize: "24px",
              opacity: !selectedDevice || row.testStatus === "Rejected" ? "0.5" : "",
            }}
            onClick={() => handlePrintClick(row)}
          ></i>
        </td>
      );
    }
    if (item.itemKey === "testStatus") {
      const orderStatus = getStatus(row);
      return (
        <div className="star-icon-wrapper cursor-pointer text-center mt-3">
          <Status
            type="circle"
            size="md"
            color={ORDER_STATUS[orderStatus]}
            title={orderStatus}
            crossIcon={orderStatus}
          />
        </div>
      );
    }
    if (item.itemKey === "pcrStatus") {
      return (
        <td
          className="ellipsis"
          style={{ textAlign: "center", color: GET_CUST_COLOR[row.pcrStatus] || "" }}
          title={row.pcrStatus || "-"}
          key={`pcrStatus_${row.id}`}
        >
          {row.pcrStatus || "-"}
        </td>
      );
    }
    if (item.itemKey === "comment") {
      return (
        <MainTableInputBox
          value={row?.employee_demographics?.comment || row?.labComment}
          handleBlur={(e) => handleInputComment(e, row)}
          disabled={isTestDone}
        />
      );
    }
    if (item.itemKey === "source") {
      let testDoneSource = [];
      if (row?.employee_demographics?.swabtype) {
        testDoneSource = filterSourcesBySwabType(getTestSource(row.sources), row?.employee_demographics?.swabtype);
      }

      return (
        <td className="text-center">
          <Select
            options={getTestSource(row.sources)}
            blurInputOnSelect={true}
            menuPlacement="auto"
            className="w-100 siteSelector site-select-test"
            placeholder="Select Source"
            isMulti
            isDisabled={true}
            value={row.selectedSource || testDoneSource}
            onChange={(e) => handleSelect(e, row)}
            components={{ ValueContainer: ReactSelectCustomContainer }}
          />
        </td>
      );
    }

    if (item.itemKey === "testAction") {
      let testDoneSource = [];
      if (row?.employee_demographics?.swabtype) {
        testDoneSource = filterSourcesBySwabType(getTestSource(row.sources), row?.employee_demographics?.swabtype);
      }

      return (
        <td className="text-center">
          <Select
            options={[
              { label: "Accept", value: "Accepted" },
              { label: "Reject", value: "Rejected" },
              { label: "Send Out", value: "sendOut" },
            ]}
            blurInputOnSelect={true}
            menuPlacement="auto"
            className="w-100"
            placeholder="Select Source"
            value={formatTestStatus(row)}
            onChange={(e) => handleDifferentStatus(e, row)}
            components={{ ValueContainer: ReactSelectCustomContainer }}
          />
        </td>
      );
    }
  };

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(sortBy === by ? !sortDescending : true);
  };
  const formattedDate = moment(order?.dob).format("MM/DD/YYYY");

  const getName = () => {
    if (patient) {
      return `Test History for ${patient?.firstName} ${patient?.lastName} DOB: ${formatDateMDY(patient.dob)} ${
        patient?.schrID ? `MRN: ${patient.schrID}` : ""
      }`;
    }
    if (order) {
      let testBarcode = tests && tests.length > 0 ? tests[0].barcode : "";
      const nameParts = [
        uiType === "testManagmentUi" && !appContext?.user?.isSite() ? "Test Management" : "Test Details",
        `${order?.firstName || ""} ${order?.lastName || ""}`.trim(),
        `DOB: ${formattedDate}` || "",
      ];

      if (order?.barcode || testBarcode) {
        nameParts.push(`Acc #${order?.barcode || testBarcode}`);
      }

      const filteredParts = nameParts.filter((part) => part && part.length > 0);

      return filteredParts.join(" - ");
    }
    return "Test Management";
  };

  const handleSendEmail = async () => {
    const selectedTest = tests.filter((test) => checkboxes.includes(test.id));
    if (!selectedTest.length) {
      return;
    }
    const params = selectedTest.map((t) => {
      return {
        id: t.id,
        sequenceNo: t.sequenceNo,
      };
    });
    await appContext.sendEmailToCrew(params, "Email");
  };

  const searchTerms = ["Test Name", "Test Status", "Order Date", "Accession No"];

  return (
    <>
      <Modal show animation={true} onHide={() => handleClose()} centered size={"3xl"}>
        <Modal.Header closeButton className="my-0">
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {getName()}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-3  marginTop0 modal-body-bg-gray">
          {loading && <Loader />}

          <div>
            <Row className="m-0">
              <Col md="12" className="p-0">
                <Card className="border-0 mb-1 shows-screen-wrapper">
                  <Card.Body className="p-0">
                    <div className="shows-filter-wrapper">
                      <div className="shows-filter-inner">
                        <Icon
                          handleClick={() => setShowFilter(!showFilter)}
                          title={"Filter"}
                          label={"Filter"}
                          iconType={"filter"}
                        />
                        {uiType === "testHistory" && (
                          <Icon
                            handleClick={handleSendEmail}
                            disabled={checkboxes.length ? false : true}
                            title={"Send Results"}
                            label={"Send Results"}
                            iconType={"messageIcon"}
                          />
                        )}
                        <Icon
                          handleClick={async () => {
                            if (tests && tests.length > 0) {
                              setAllSelected(!allSelected);
                              toggleCheckboxes(!allSelected);
                            }
                            //  await API.invoicesPDF();
                          }}
                          title={!allSelected ? "Select All" : "Deselect All"}
                          label={!allSelected ? "Select All" : "Deselect All"}
                          iconType={"selectAllIcon"}
                        />
                        <Icon
                          handleClick={() => downloadRequisitionFile()}
                          disabled={checkboxes.length === 0}
                          title={"Req Forms"}
                          label="Req Forms"
                          iconType={"dw_Icon"}
                        />
                        {!appContext?.user?.isSite() && (
                          <Icon
                            handleClick={() => handleAllPrintTests()}
                            title={"Print Label"}
                            disabled={checkboxes.length > 0 ? false : true}
                            label="Print"
                            iconType={"printerIcon"}
                          />
                        )}
                      </div>
                    </div>
                    <div>
                      {showFilter && <Filter filterTerms={searchTerms} setFilter={setFilter} filter={filter} />}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <div className="shows-screen-parent">
              <MainTable
                columns={personalize}
                rows={tests}
                selectedRows={checkboxes}
                flipSort={flipSort}
                personalisationKey={PERSONALISATION_KEY.TEST_DETAILS_DONE}
                cssClass="table-noresponsive"
                sortBy={sortBy}
                sortDescending={sortDescending}
                tools={quickTools}
                handleCellClick={handleCellClick}
                customColumnCellRenderer={customRenderTD}
                // dropDownOptions={
                //   uiType === "testManagmentUi" && [
                //     { value: "Accept", title: "Accept" },
                //     //{ value: "Create New", title: "Create New" },
                //     { value: "Reject", title: "Reject" },
                //     // { value: "Send Out", title: "Send Out" },
                //   ]
                // }
                // handleDropDownClick={(type, row) => {
                //   if (type === "Reject") {
                //     setOpenDeleteConfirmation(true);

                //     setCheckboxes([row.id]);
                //   }
                //   if (type === "Audit Trail") {
                //     appContext.showLogs(row);
                //   } else if (type === "Print Label") {
                //     handlePrintClick(row);
                //   } else if (type === "Requisition Form") {
                //     showReqForm(row);
                //   }
                // }}
                isTestDoneModal
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="flex-row-reverse mt-3">
          {!appContext?.user?.isSite() && (
            <Button
              className="modalButtons"
              disabled={checkboxes.length > 0 ? false : true}
              variant="primary"
              onClick={() => handleAllPrintTests()}
            >
              Ok
            </Button>
          )}
          <Button className="modalButtons" variant="primary" onClick={() => handleClose()}>
            Cancel
          </Button>

          {/* <Button className="modalButtons" variant="primary" onClick={updateColumns}>
            Update Columns
          </Button> */}
        </Modal.Footer>
        {openDeleteConfirmation && (
          <ConfirmationModal
            show={openDeleteConfirmation}
            title="Reject Test"
            message=""
            handleConfirm={handleConfirm}
            note={{ label: "Rejected Reason" }}
          />
        )}
        {isShowSendOutOptions && sendOutOptions?.length > 1 && (
          <ConfirmationModal
            show={openDeleteConfirmation}
            title="Select Send Out"
            message=""
            options={sendOutOptions}
            isSelectOptions={true}
            selectedOptions={selectedSendOutObj}
            handleOptions={(e) => setSelectedSendOutObj(e)}
            handleConfirm={() => setIsShowSendOutOptions(false)}
          />
        )}
      </Modal>
    </>
  );
};

export default TestDoneModal;
