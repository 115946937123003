/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://i7sdzcguu8.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "usersApi",
            "endpoint": "https://hmbjn6rhi4.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://oh3u5jwmyfcsdkirlqytlxtipi.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ccq2u7cwxffxbdafloqv2o4shm",
    "aws_cognito_identity_pool_id": "eu-west-1:e0a3c491-0c98-479f-9624-ac1782865f1d",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_0GfxcufKQ",
    "aws_user_pools_web_client_id": "3i039r3lsfe4rge4hsnim6s4s4",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "crnaeb3085eb7b5f48fd9fc30dfb9bd7ef3c105147-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
