import { API_ACTIONS } from "constant";
import { AUTH_ERROR_EMAIL } from "store/features/authentication/authenticationConstant";
import { ICT_CODE_FETCH, CPT_CODE_FETCH } from "store/features/cptCodes/cptCodeConstant";
import { DOC_FETCH_TYPE } from "store/features/documents/documentConstant";
import { GET_CLAIM_STATS, EMPLOYEE_CLAIM_FETCH } from "store/features/employeeClaims/employeeClaimsConstant";
import { INSURANCES_FETCH, INSURANCES_FETCH_ALL } from "store/features/insurances/insuranceConstant";
import { setLoader } from "../features/general/generalSlice";
import { COMPANY_SETTING_FETCH } from "store/features/companySetting/companySettingConstants";

const loaderMiddleware = (store) => (next) => (action) => {
  const { dispatch } = store;

  const nosShowLoader = action.meta?.arg?.noShowLoader;

  const notToCheck = [
    GET_CLAIM_STATS,
    EMPLOYEE_CLAIM_FETCH,
    DOC_FETCH_TYPE,
    AUTH_ERROR_EMAIL,
    DOC_FETCH_TYPE,
    INSURANCES_FETCH,
    INSURANCES_FETCH_ALL,
    COMPANY_SETTING_FETCH,
  ].map((key) => key + "/" + API_ACTIONS.pending);
  if (action.type.endsWith(API_ACTIONS.fulfilled) || action.type.endsWith(API_ACTIONS.rejected)) {
    dispatch(setLoader(false)); // Dispatch setLoader(false) after async action completes or throws an error
  } else if (action.type.endsWith(API_ACTIONS.pending) && !notToCheck.includes(action.type) && !nosShowLoader) {
    dispatch(setLoader(true)); // Dispatch setLoader(true) before async action starts
  }
  return next(action);
};

export default loaderMiddleware;
