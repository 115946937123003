import React, { useState, useRef } from "react";
import { Table, Modal } from "react-bootstrap";

const TestLogsDetailView = (props) => {
  const { item, columns, values, cssClass, changeObject, msg } = props;
  console.log("Values", values);
  const [showDetailModal, setShowDetailModal] = useState(false);

  const childNode = useRef();

  const TableRow = ({ rowItem, index }) => {
    return (
      <tr key={index}>
        <td className="popOver-centered border-0">{rowItem.name}</td>
        <td className="popOver-centered border-0">{rowItem.oldResult.toString()}</td>
        <td className="popOver-centered border-0">{rowItem.newResult.toString()}</td>
      </tr>
    );
  };

  const showSingleLogMessage = (msgObj) => {
    const msg = msgObj[0];
    return `${msg.name} changed ${msg.oldResult} to ${msg.newResult}`;
  };

  return (
    <>
      <td
        className={cssClass}
        ref={childNode}
        onClick={() => setShowDetailModal(true)}
        style={{
          cursor: "pointer",
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        {!values || (Array.isArray(values) && values.length === 0)
          ? msg.slug
          : values.length === 1
          ? showSingleLogMessage(values)
          : "View Details"}
      </td>
      {Array.isArray(values) && values.length > 0 && showDetailModal && (
        <Modal
          show
          animation={true}
          size="lg"
          onHide={() => setShowDetailModal(false)}
          centered
          scrollable
          className="searchList seperateModal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="my-0" id="contained-modal-title-vcenter">
              Detail Logs
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group-wrapper d-block">
              <div className="table-responsive overFlow-y-hidden">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="popOver-centered border-0">Label</th>
                      <th className="popOver-centered border-0">Before</th>
                      <th className="popOver-centered border-0">After</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(values) &&
                      values.map((val, i) => {
                        return <TableRow key={i} index={i} rowItem={val} />;
                      })}
                  </tbody>
                </Table>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default TestLogsDetailView;
