import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import { Client, Site, Lab, Employee, Test, TestTypes, TestConsent, LencoBarcodes, EmployeeTestCounter } from "models";
import { Auth, API, Storage, graphqlOperation } from "aws-amplify";
import * as queries from "graphql/queries";
import {
  setPhoneNo,
  formatTest,
  getPhoneNo,
  formatDoBForDemo,
  parseBooleanVal,
  getLoginUserInfoFromLocal,
  getDiagnosisCode,
  clientIDInLocalStorage
} from "utils";
import { TEST_STATUS, RESULT_VALUE, CONFIG, UN_RESULT } from "constant";
import axios from "axios";
import { jsonToCSV } from "react-papaparse";
import moment from "moment";
import * as uuid from "uuid";
import * as mutations from "graphql/mutations";

import { AWS_ENDPOINT } from "constants/endpoints";
import { ExternalTest } from "models";
import graphQLApi from "graphQLApi";
import api from "api";
const MAX_PAGE_LIMIT = 1000;
const PRE_REGISTER_URL = {
  LIVE: "https://g8bgbgfb45.execute-api.eu-west-1.amazonaws.com/dev/register",
  DEV: "https://qa3dzt4aoj.execute-api.eu-west-1.amazonaws.com/dev/register",
};

const apiName = "AdminQueries";
const publicApiName = "usersApi";

export const callAmplifyAPI = async (path, payload) => {
  try {
    const loginUserInfo = getLoginUserInfoFromLocal();
    const params = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
      body: { ...payload, clientID: clientIDInLocalStorage.get(), ...loginUserInfo },
    };

    const apiRes = await API.post(apiName, path, params);

    return apiRes;
  } catch (err) {
    console.log("Err", err);
    return null;
  }
};

export const callAmplifyAPIWithError = async (path, payload) => {
  const loginUserInfo = getLoginUserInfoFromLocal();
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: { ...payload, clientID: CONFIG.clientID, ...loginUserInfo },
  };

  const apiRes = await API.post(apiName, path, params);

  return apiRes;
};

export const callPublicAPI = async ({ path, payload }) => {
  try {
    const publicApiName = "usersApi";

    let params = {
      body: {
        ...payload,
      },
    };
    const res = await API.post(publicApiName, path, params);

    return res;
  } catch (err) {
    console.log("Err", err);
    throw err;
  }
};

export const callAmplifyAPIPut = async (path, payload) => {
  try {
    const loginUserInfo = getLoginUserInfoFromLocal();
    const params = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
      body: { ...payload, clientID: CONFIG.clientID, ...loginUserInfo },
    };

    const apiRes = await API.put(apiName, path, params);

    return apiRes;
  } catch (err) {
    console.log("Err", err);
    return null;
  }
};

const getClients = async () => {
  const models = await DataStore.query(Client, Predicates.ALL, {
    sort: (s) => s.name(SortDirection.ASCENDING),
  });
  /* const clients = [];
  const promises = [];
  models.map(async (model, i) => {
    promises[i] = findTestsByClient(model.id);
    return clients.push({ ...model, tests: await promises[i] });
  });
  return Promise.all([models, ...promises]).then(() => clients); */
  return models;
};

const getResultClients = async () => {
  const models = await DataStore.query(Client, (client) => client.resultType("eq", "P/F"));
  return models;
};

const getClientById = async (id) => {
  const client = await DataStore.query(Client, (client) => client.id("eq", id));
  return client[0];
};

const getTestGraphQL = async (filter) => {
  let employees = await graphQLApi.getTestGQL(filter);
  return employees;
};

const getEmployeeGraphQl = async () => {
  let employees = await graphQLApi.getEmployeeGQL();
  return employees;
};
const getEmployeeByIdGraphQl = async (id) => {
  let employee = await graphQLApi.getEmployeeByIdGQl(id);
  return employee;
};

const deleteEmployeeGQL = async (employees) => {
  const deletedArray = [];
  for (let i = 0; i < employees.length; i++) {
    const isDeleted = await graphQLApi.deleteEmployeeGQL(employees[i]);
    if (isDeleted) {
      deletedArray.push(employees[i]);
    }
  }
  return deletedArray;
};

const updateEmployeeGraphQL = async (employee) => {
  const employeeData = {
    id: employee.id,
    first: employee.first,
    last: employee.last,
    dob: moment(employee.dob).format("YYYY-MM-DD"),
    id_number: employee.id_number,
    email: employee.email,
    sex: employee.sex,
    phone_number: employee.phone_number,
    countryCode: employee.countryCode,
    street: employee.street,
    street2: employee.street2,
    city: employee.city,
    state: employee.state,
    zip: employee.zip,
    profileImage: employee.profileImage,
    vaccinationCardImage: employee.vaccinationCardImage,
    externalTestImage: employee.externalTestImage,
    _version: employee._version,
  };
  const updateEmp = await graphQLApi.updateEmployeeGQL(employeeData);
  return updateEmp;
};
const getCurrentUser = async () => {
  try {
    const response = await Auth.currentAuthenticatedUser({ bypassCache: true });

    return {
      ...response.attributes,
      roles: response.signInUserSession.accessToken.payload["cognito:groups"] || [],
      name: `${response.attributes["custom:firstName"]} ${response.attributes["custom:lastName"]}`,
      clientID: response.attributes["custom:clientID"],
      connectedID: response.attributes["custom:connectedID"],
      isAdmin: function () {
        return this.roles.some((role) => role === "Admins");
      },
      isLab: function () {
        return this.roles.some((role) => role === "Labs");
      },
      isUser: function () {
        return this.roles.length === 0;
      },
      isSite: function () {
        return this.roles.some((role) => role === "Employers");
      },
      isClient: function () {
        return this.roles.some((role) => role === "Clients");
      },
    };
  } catch (err) {
    console.log(err);
    localStorage.clear();
    return null;
  }
};

const getAllUsers = async () => {
  const models = await DataStore.query(Employee);
  return models;
};

const signIn = async (username, password) => {
  try {
    const response = await Auth.signIn(username, password);
    // console.log(response);
    return {
      ...response.attributes,
      roles: response.signInUserSession.accessToken.payload["cognito:groups"] || [],
      isAdmin: function () {
        return this.roles.some((role) => role === "Admins");
      },
      isUser: function () {
        return this.roles.length === 0;
      },
    };
  } catch (err) {
    return null;
  }
};

const getLabs = async () => {
  const models = await DataStore.query(Lab, Predicates.ALL, {
    sort: (s) => s.name(SortDirection.ASCENDING),
  });
  return models;
};

const getLabsWithTests = async () => {
  const models = await DataStore.query(Lab);
  const labs = [];
  const promises = [];
  models.map(async (model, i) => {
    promises[i] = findTestsByLab(model.id);
    labs.push({ ...model, tests: await promises[i] });
  });
  return Promise.all([models, ...promises]).then(() => labs);
};

const getLabByID = async (labID) => {
  const model = await DataStore.query(Lab, (lab) => lab.id("eq", labID));
  return model[0];
};

const createSite = async (newSite, userID) => {
  const site = await DataStore.save(
    new Site({
      name: newSite.name,
      clientID: newSite.clientID,
      contact_name: newSite.contact,
      contact_phone: setPhoneNo(newSite.phone),
      contact_email: newSite.email,
      user_id: userID,
      antigen: newSite.antigen,
      automateTestType: newSite.automateTestType,
      insurance_required: newSite.insurance_required,
      startDate: newSite.startDate,
      endDate: newSite.endDate,
      orderKitDate: newSite.orderKitDate ? moment(newSite.orderKitDate).toISOString() : null,
      enableShipment: newSite.enableShipment,
      orderQuantity: newSite.orderQuantity,
      sendInsuranceCard: newSite.sendInsuranceCard,
      resetTestDay: newSite.resetTestDay,
      showOnSummaryScreen: newSite.showOnSummaryScreen,
      messageTested: newSite.messageTested,
      messageResulted: newSite.messageResulted,
      messagePositive: newSite.messagePositive,
      isArchive: newSite.isArchive,
      vaccinationCard: newSite.vaccinationCard,
      externalTest: newSite.externalTest,
      externalTestAdmit: newSite.externalTestAdmit,
      externalTestType: newSite.externalTestType,
      admit: newSite.admit,
      patternTesting: newSite.patternTesting,
      patternHippa: newSite.patternHippa,
      preRegistration: newSite.preRegistration,
    })
  );
  return site;
};

const getSites = async () => {
  // const models = await DataStore.query(Site, (site) => site.isArchive("eq", false), {
  //   sort: (s) => s.name(SortDirection.ASCENDING),
  // });

  const models = await DataStore.query(Site, (site) => {}, {
    sort: (s) => s.name(SortDirection.ASCENDING),
  });

  return models;

  const sites = [];
  const promises = [];
  models.map(async (model, i) => {
    promises[i] = findTestsBySite(model.id);
    sites.push({
      ...model,
      tests: await promises[i],
      labID: await promises[i].then((resp) => (resp[0] ? resp[0].labID : null)),
    });
  });
  return Promise.all([models, ...promises]).then(() => sites);
};
const getSitesWithTests = async () => {
  const models = await DataStore.query(Site, (site) => site.isArchive("eq", false), {
    sort: (s) => s.name(SortDirection.ASCENDING),
  });

  const sites = [];
  const promises = [];
  models.map(async (model, i) => {
    promises[i] = findTestsBySite(model.id);
    sites.push({
      ...model,
      tests: await promises[i],
      labID: await promises[i].then((resp) => (resp[0] ? resp[0].labID : null)),
    });
  });
  return Promise.all([models, ...promises]).then(() => sites);
};

const getShows = async () => {
  const models = await DataStore.query(Site);
  return models;
};

const getSitesByClient = async (clientID) => {
  const models = await DataStore.query(Site, (site) => site.clientID("eq", clientID), {
    sort: (s) => s.name(SortDirection.ASCENDING),
  });

  const sites = [];
  const promises = [];
  console.log(models);
  models.map(async (model, i) => {
    promises[i] = findTestsBySite(model.id);
    sites.push({
      ...model,
      tests: await promises[i],
      labID: await promises[i].then((resp) => (resp[0] ? resp[0].labID : null)),
    });
  });
  return Promise.all([models, ...promises]).then(() => sites);
};

const getSitesByUser = async (userID) => {
  let models = await DataStore.query(Site, (site) => site.id("eq", userID), {
    sort: (s) => s.name(SortDirection.ASCENDING),
  });

  const sites = [];
  const promises = [];
  models.map(async (model, i) => {
    promises[i] = findTestsBySite(model.id);
    sites.push({
      ...model,
      tests: await promises[i],
      labID: await promises[i].then((resp) => (resp[0] ? resp[0].labID : null)),
    });
  });
  return Promise.all([models, ...promises]).then(() => sites);
  // console.log(models);
  // return models;
};

const getUserSiteData = async (userID) => {
  let models = await DataStore.query(Site, (site) => site.id("eq", userID), {
    sort: (s) => s.name(SortDirection.ASCENDING),
  });
  return models;
};

const getUserSites = async (userID) => {
  let models = await DataStore.query(Site, (site) => site.user_id("eq", userID), {
    sort: (s) => s.name(SortDirection.ASCENDING),
  });

  if (models.length === 0) {
    const allSites = await DataStore.query(Site);
    models = allSites.filter((s) => s.show_contacts && s.show_contacts.includes(userID));
  }

  const sites = [];
  const promises = [];
  console.log(models);
  return models[0].id;
};

const findSiteByID = async (siteID) => {
  const models = await DataStore.query(Site, (site) => site.id("eq", siteID));
  console.log(models);
  return models;
};

const getEmployeeByID = async (employeeID) => {
  const models = await DataStore.query(Employee, (test) => test.id("eq", employeeID));
  return models.length > 0 ? models[0].mdID || employeeID : employeeID;
};
const getEmployeeTestCount = async (employeeID) => {
  const models = await DataStore.query(Test, (test) => test.employeeID("eq", employeeID));
  return models;
};

const getEmployeeLastShow = async (employeeID) => {
  const models = await DataStore.query(Test, (test) => test.employeeID("eq", employeeID), {
    sort: (s) => s.createdAt(SortDirection.DESC),
  });
  if (models.length === 0) return "N/A";
  return models[0].site_name;
};

const createEmployee = async (demographics = {}, clientID, phoneNumber) => {
  const models = await DataStore.query(Employee, (emp) =>
    emp
      .clientID("eq", clientID)
      .first("eq", demographics.firstName)
      .last("eq", demographics.lastName)
      .dob("eq", buildAWSDate(demographics.dob))
  );
  if (models.length > 0) {
    return models[0];
  }
  //Must be new. Create it
  const employee = await DataStore.save(
    new Employee({
      first: demographics.firstName,
      last: demographics.lastName,
      dob: buildAWSDate(demographics.dob),
      id_number: demographics.idNumber,
      clientID: clientID,
      phone_number: demographics.phoneNo,
      countryCode: demographics.ccCode,
      country: demographics.country || "United State",
      street: demographics.street,
      street2: demographics.street2,
      city: demographics.city,
      state: demographics.state,
      zip: demographics.zip,
    })
  );
  return employee;
};

const createNewEmployee = async (demographics = {}) => {
  let employeeObj = {
    email: demographics.email,
    country: demographics.country,
    countryCode: demographics.countryCode,
    first: demographics.firstName,
    last: demographics.lastName,
    dob: buildAWSDate(formatDoBForDemo(demographics.dob)),
    id_number: demographics.idNumber,
    phone_number: demographics.phoneNumber,
    street: demographics.street,
    street2: demographics.street2,
    city: demographics.city,
    state: demographics.state,
    zip: demographics.zip,
    profileImage: demographics.profileImage,
    isExternalTest: parseBooleanVal(demographics?.isExternalTest),
    externalTestImage: demographics?.externalTestImage || "",
    externalTestResult: demographics?.externalTestResult || "",
    externalTestType: demographics?.externalTestType || "",
    vaccinationCardImage: demographics?.vaccinationCardImage || "",
    vaccinationLocation: demographics?.vaccinationLocation || "",
    vaccinationType: demographics?.vaccinationType || "",
    isPreRegistered: true,
    sex: demographics.sex,
    employeeType: demographics.employeeType,
    isVaccinated: parseBooleanVal(demographics.isVaccinated),
  };

  if (demographics?.externalTestDate) {
    employeeObj = { ...employeeObj, externalTestDate: demographics?.externalTestDate };
  }

  if (demographics?.vaccinationDate) {
    employeeObj = { ...employeeObj, vaccinationDate: demographics?.vaccinationDate };
  }

  const employee = await DataStore.save(new Employee(employeeObj));
  return employee;
};

const preRegisterEmployee = async (employee, env) => {
  axios.defaults.headers.common = {
    "X-API-Key": "wlx6h2qWY04KX2z0xVJAJ8bazvEbgQ0l24JYXS5K",
  };
  const zipcode = employee.zipcode.replaceAll("[^0-9]+", "");
  let params = `first=${encodeURIComponent(employee.first)}&last=${encodeURIComponent(
    employee.last
  )}&dob=${encodeURIComponent(employee.dob)}&id_number=${encodeURIComponent(employee.id_number)}`;
  params += `&email=${encodeURIComponent(employee.email)}&sex=${encodeURIComponent(
    employee.sex
  )}&isVaccinated=${encodeURIComponent(employee.isVaccinated)}`;
  params += `&phone_number=${encodeURIComponent(employee.phone_number)}&street=${encodeURIComponent(employee.street)}`;
  params += `&city=${encodeURIComponent(employee.city)}&state=${encodeURIComponent(
    employee.state
  )}&zipcode=${encodeURIComponent(zipcode)}&labid=${encodeURIComponent(
    CONFIG.appPackage
  )}&countryCode=${encodeURIComponent(employee.countryCode)}`;

  const notification = await axios.post(`${PRE_REGISTER_URL[env]}?${params}`);

  return notification.data;
};

const getPreRegisterByPhone = async (phone, showId, env) => {
  axios.defaults.headers.common = {
    "X-API-Key": "wlx6h2qWY04KX2z0xVJAJ8bazvEbgQ0l24JYXS5K",
  };
  let params = `phoneNo=${encodeURIComponent(phone)}`;
  if (showId) params += `&showId=${encodeURIComponent(showId)}`;

  const notification = await axios.post(`${PRE_REGISTER_URL[env]}?${params}`);

  return notification.data;
};

const preRegisterNewEmployee = async (employee, clientID, isVaccinationInfoEnable, step, env) => {
  axios.defaults.headers.common = {
    "X-API-Key": "wlx6h2qWY04KX2z0xVJAJ8bazvEbgQ0l24JYXS5K",
  };
  const zipcode = employee.zipcode.replaceAll("[^0-9]+", "");
  let params = `first=${encodeURIComponent(employee.first)}&last=${encodeURIComponent(
    employee.last
  )}&dob=${encodeURIComponent(employee.dob)}&id_number=${encodeURIComponent(employee.id_number)}`;
  params += `&email=${encodeURIComponent(employee.email)}&sex=${encodeURIComponent(
    employee.sex
  )}&isVaccinated=${encodeURIComponent(employee.isVaccinated)}`;
  params += `&phone_number=${encodeURIComponent(employee.phone_number)}&countryCode=${encodeURIComponent(
    employee.countryCode
  )}&street=${encodeURIComponent(employee.street)}&street2=${encodeURIComponent(employee.street2)}`;
  params += `&city=${encodeURIComponent(employee.city)}&state=${encodeURIComponent(
    employee.state
  )}&country=${encodeURIComponent(employee.country)}&zipcode=${encodeURIComponent(zipcode)}&rStep=${step}`;

  if (employee.showId) {
    params += `&showId=${employee.showId.id}&showName=${encodeURIComponent(employee.showId.name)}`;
  }
  if (isVaccinationInfoEnable) {
    params += `&vaccinationType=${encodeURIComponent(employee.vaccinationType)}&vaccinationDate=${encodeURIComponent(
      employee.vaccinationFirstDate
    )}&vaccinationLocation=${encodeURIComponent(employee.vaccinationFirstLocation)}`;
    if (employee.vaccinationSecondDate) {
      params += `&vaccinationDate1=${encodeURIComponent(
        employee.vaccinationSecondDate
      )}&vaccinationLocation1=${encodeURIComponent(employee.vaccinationSecondLocation)}`;
    }
    if (employee.isBooster) {
      params += `&isBooster=${encodeURIComponent(employee.isBooster)}&boosterType=${encodeURIComponent(
        employee.boosterType
      )}&boosterDate=${encodeURIComponent(employee.boosterDate)}&boosterLocation=${encodeURIComponent(
        employee.boosterLocation
      )}`;
    }
  }
  if (employee.isExternalTest) {
    params += `&isExternalTest=${encodeURIComponent(employee.isExternalTest)}&externalTestDate=${encodeURIComponent(
      employee.externalTestDate
    )}&externalTestResult=${encodeURIComponent(employee.externalTestResult)}&externalTestType=${encodeURIComponent(
      employee.externalTestType
    )}`;
  }

  console.log("Parmas", params);
  const notification = await axios.post(`${PRE_REGISTER_URL[env]}?${params}`);

  return notification.data;
};

const requestTestKit = async (employee, clientID, env) => {
  axios.defaults.headers.common = {
    "X-API-Key": "wlx6h2qWY04KX2z0xVJAJ8bazvEbgQ0l24JYXS5K",
  };
  const zipcode = employee.zipcode.replaceAll("[^0-9]+", "");
  let params = `first=${encodeURIComponent(employee.first)}&last=${encodeURIComponent(
    employee.last
  )}&order_phone=${encodeURIComponent(employee.order_phone)}`;
  params += `&email=${encodeURIComponent(employee.email)}&phone_number=${encodeURIComponent(
    employee.phone_number
  )}&profileImage=${encodeURIComponent(employee.profileImage)}`;
  params += `&countryCode=${encodeURIComponent(employee.countryCode)}&street=${encodeURIComponent(
    employee.street
  )}&street2=${encodeURIComponent(employee.street2)}`;
  params += `&city=${encodeURIComponent(employee.city)}&state=${encodeURIComponent(
    employee.state
  )}&country=${encodeURIComponent(employee.country)}&zipcode=${encodeURIComponent(zipcode)}`;
  if (employee.showId) {
    params += `&showId=${encodeURIComponent(employee.showId.id)}&showName=${encodeURIComponent(employee.showId.name)}`;
  }
  if (clientID) {
    params += `&clientId=${encodeURIComponent(clientID)}`;
  }
  params += `&testSend=${encodeURIComponent(employee.testSend)}&testSendQty=${encodeURIComponent(
    employee.testSendQty
  )}`;

  params += `&capacity=${employee.capacity}`;

  console.log("Parmas", params);
  const notification = await axios.post(`${PRE_REGISTER_URL[env]}?${params}`);

  return notification.data;
};

const preRegisterClientShows = async (id, env) => {
  axios.defaults.headers.common = {
    "X-API-Key": "wlx6h2qWY04KX2z0xVJAJ8bazvEbgQ0l24JYXS5K",
  };

  let params = `&preregistration=${encodeURIComponent(id)}`;

  console.log("Parmas", params);
  const notification = await axios.post(`${PRE_REGISTER_URL[env]}?${params}`);

  return notification.data.body;
};

const preRegisterShowData = async (id, env) => {
  axios.defaults.headers.common = {
    "X-API-Key": "wlx6h2qWY04KX2z0xVJAJ8bazvEbgQ0l24JYXS5K",
  };

  let params = `&registeruser=${encodeURIComponent(id)}`;

  console.log("Parmas", params);
  const notification = await axios.post(`${PRE_REGISTER_URL[env]}?${params}`);

  return notification.data.body;
};

const createTest = async (dbEmp = {}) => {
  const demo = dbEmp.employee_demographics;
  const [countryCode, phoneNo] = getPhoneNo(demo.phoneNumber, demo.countryCode);
  const createdAt = dbEmp.createdAt ? moment(dbEmp.createdAt).toISOString() : moment().toISOString();
  const resultDate = dbEmp.createdAt ? moment(dbEmp.createdAt).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
  const pcrStatus = dbEmp.result ? "Received By Lab" : "Specimen Collected";
  const obj = {
    batch: CONFIG.isWhiteLabel ? "" : new Date().toDateString(),
    barcode: dbEmp.barcode,
    clientID: dbEmp.clientID,
    orderID: dbEmp.orderID,
    clientName: dbEmp.clientName,
    createSource: dbEmp.createSource ? dbEmp.createSource : "web",
    createdAt: createdAt,
    createdBy: dbEmp.createdBy,
    done: false,
    email: dbEmp.email,
    emailSend: false,
    employeeID: dbEmp.employeeID,
    employee_demographics: { ...demo, countryCode, phoneNumber: phoneNo },
    expired: false,
    isAntigen: dbEmp?.test_type === "Antigen" ? true : false,
    isFalsePositive: false,
    invalidResultStatus: 0,
    labID: dbEmp.labID,
    labName: dbEmp.labName,
    phoneNumber: phoneNo,
    referenceID: moment().valueOf().toString(),
    sequenceNo: parseInt(dbEmp.sequenceNo),
    baseSiteID: dbEmp.siteID,
    siteID: dbEmp.siteID,
    site_name: dbEmp.site_name,
    startTimeStamp: moment().toISOString(),
    status: dbEmp.status,
    submittedBy: dbEmp.createdBy,
    submittedByName: dbEmp.submittedByName,
    test_type: dbEmp.test_type,
    tester_name: dbEmp.tester_name,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    appVer: "0",
    pcrStatus: pcrStatus,
    sr_no: JSON.stringify([{ accession_no: dbEmp.barcode, date: createdAt, name: dbEmp.submittedByName }]),
  };
  if (dbEmp.result && dbEmp.result !== UN_RESULT) {
    Object.assign(obj, { result: dbEmp.result, resultDate: resultDate, resultDateTime: createdAt });
  }
  const test = await DataStore.save(new Test(obj));
  return test;
};

const createOrderAPI = async (dbOrder = {}) => {
  const diagnosis = getDiagnosisCode(dbOrder);

  const newOrderNo = await getOrderAutoNumber();
  const demo = dbOrder.employee_demographics;
  const [countryCode, phoneNo] = getPhoneNo(demo.phoneNumber, demo.countryCode);
  const obj = {
    clientID: dbOrder.clientID,
    clientName: dbOrder.clientName,
    createdBy: dbOrder.createdBy,
    createdByName: dbOrder.createdByName,
    employeeID: dbOrder.employeeID,
    employee_demographics: JSON.stringify({ ...demo, countryCode, phoneNumber: phoneNo }),
    labID: dbOrder.labID,
    labName: dbOrder.labName,
    providerID: dbOrder.providerID,
    providerName: dbOrder.providerName,
    ...(dbOrder.provider && { provider: JSON.stringify(dbOrder.provider) }),
    orderNo: newOrderNo.counter,
    orderDate: dbOrder.orderDate,
    floorNo: dbOrder.floorNo,
    isActive: true,
    siteID: dbOrder.siteID,
    site_name: dbOrder.site_name,
    status: dbOrder.status,
    eligibilityStatus: dbOrder.eligibilityStatus ? JSON.stringify(dbOrder.eligibilityStatus) : null,
    testsToDo: JSON.stringify(dbOrder.testsToDo),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    externalID: dbOrder.externalID,
    location: dbOrder.location,
    clincialComments: dbOrder.clincialComments,
    ...diagnosis,
  };

  const order = await graphQLApi.createOrderGQL(obj);

  return order;
};

const updateOrderAPI = async (dbOrder = {}) => {
  const getOrder = await graphQLApi.getOrderByIdGQl(dbOrder?.id);
  if (!getOrder) return null;
  const demo = dbOrder.employee_demographics;
  const [countryCode, phoneNo] = getPhoneNo(demo.phoneNumber, demo.countryCode);
  const diagnosis = getDiagnosisCode(dbOrder);
  const obj = {
    id: dbOrder.id,
    clientID: dbOrder.clientID,
    clientName: dbOrder.clientName,
    updatedBy: dbOrder.updatedBy,
    updatedByName: dbOrder.updatedByName,
    employeeID: dbOrder.employeeID,
    providerID: dbOrder.providerID,
    providerName: dbOrder.providerName,
    employee_demographics: JSON.stringify({ ...demo, countryCode, phoneNumber: phoneNo }),
    labID: dbOrder.labID,
    orderDate: dbOrder.orderDate,
    labName: dbOrder.labName,
    orderNo: dbOrder.orderNo,
    floorNo: dbOrder.floorNo,
    isActive: true,
    siteID: dbOrder.siteID,
    site_name: dbOrder.site_name,
    status: dbOrder.status,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    testsToDo: JSON.stringify(dbOrder.testsToDo),
    ...(dbOrder.provider && { provider: JSON.stringify(dbOrder.provider) }),
    externalID: dbOrder.externalID,
    location: dbOrder.location,
    clincialComments: dbOrder.clincialComments,
    _version: getOrder?._version,
    ...diagnosis,
  };

  const order = await graphQLApi.updateOrderGQL(obj);
  await api.addLogs({
    testID: order.id,
    slug: "Order Updated",
    newValue: "Updated",
    userName: dbOrder.updatedByName,
    userID: dbOrder.updatedBy,
  });
  return order;
};

const updateRejcetedOrder = async ({ dbOrder, selectedTestIds, reason, user }) => {
  const getOrder = await graphQLApi.getOrderByIdGQl(dbOrder.id);
  if (!getOrder) return null;

  const testsToDo = JSON.parse(getOrder.testsToDo) || {};
  const todoTests = testsToDo.test || [];
  
  const todoUpdatedTests = todoTests.map((t) => {
    const updatedTest = {
      ...t,
    };
    if (selectedTestIds.includes(t.id)) {
      if (!t.collectedDate && t.testStatus !== "Rejected") {
        updatedTest.rejectedReason = reason;
        updatedTest.testStatus = "Rejected";
      }
    }
    return updatedTest;
  });

  const updatedTestsToDo = {
    ...testsToDo,
    test: todoUpdatedTests,
  };

  const obj = {
    id: dbOrder.id,
    updatedBy: user?.sub || dbOrder.updatedBy,
    updatedByName: user?.name || dbOrder.updatedByName,
    testsToDo: JSON.stringify(updatedTestsToDo),
    _version: getOrder._version,
  };
  
  const order = await graphQLApi.updateOrderGQL(obj);
  await api.addLogs({
    testID: order.id,
    slug: "Order Updated",
    newValue: "Recject the Test",
    userName: dbOrder.updatedByName,
    userID: dbOrder.updatedBy,
  });
  return order;
};

const updateOrderStatus = async (dbOrder = {}) => {
  const getOrder = await graphQLApi.getOrderByIdGQl(dbOrder.id);
  if (!getOrder) return null;

  const obj = {
    id: dbOrder.id,
    notes: dbOrder.note,
    updatedBy: dbOrder.updatedBy,
    updatedByName: dbOrder.updatedByName,
    _version: getOrder?._version,
  };

  const order = await graphQLApi.updateOrderGQL(obj);
  return order;
};

const createClient = async (newClient) => {
  // const phoneNumber = newClient.phone.replace(/\D/g, "");
  const client = await DataStore.save(
    new Client({
      name: newClient.name,
      contact_name: newClient.contact,
      contact_phone: setPhoneNo(newClient.phone),
      contact_email: newClient.email,
      resultType: newClient.resultType,
      showOnSummaryScreen: newClient.showOnSummaryScreen,
      whiteLabel: newClient.whiteLabel,
      whiteLabelPackage: newClient.whiteLabelPackage,
      isArchive: newClient.isArchive,
      // user_id: newUserID,
    })
  );
  return client;
};

const createLab = async (newLab, newUserID) => {
  const lab = await DataStore.save(
    new Lab({
      name: newLab.name,
      contact_name: newLab.contact,
      contact_phone: setPhoneNo(newLab.phone),
      contact_email: newLab.email,
      user_id: newUserID,
      street: newLab.street,
      city_state_zip: newLab.city_state_zip,
      lab_director: newLab.lab_director,
      testing_notes: newLab.testing_notes,
      clia_number: newLab.clia_number,
      default_antigen: newLab.default_antigen,
      default_molecular: newLab.default_molecular,
      default_other: newLab.default_other,
      tubes_provided: newLab.tubes_provided,
      sendInsurance: newLab.sendInsurance,
      logo: newLab.logo,
      barCodeProvided: newLab.barCodeProvided,
      barCodeAlertLimit: 0,
      antigen_notes: newLab.default_antigen ? newLab.antigen_notes : "",
      molecular_notes: newLab.default_molecular ? newLab.molecular_notes : "",
      showOnSummaryScreen: newLab.showOnSummaryScreen,
      whiteLabel: newLab.whiteLabel,
      whiteLabelPackage: newLab.whiteLabelPackage,
      other_notes: newLab.other_notes,
      isArchive: newLab.isArchive,
    })
  );
  return lab;
};

const deleteClient = async (id) => {
  await DataStore.delete(Client, (client) => client.id("eq", id));
};

const deleteLab = async (id) => {
  await DataStore.delete(Lab, (lab) => lab.id("eq", id));
};

const deleteSite = async (id) => {
  await DataStore.delete(Site, (site) => site.id("eq", id));
};
const deleteEmployee = async (id) => {
  await DataStore.delete(Employee, (e) => e.id("eq", id));
};

const getTests = async () => {
  let currentPage = 0;
  let fetchData = true;
  let models = await DataStore.query(Test, (test) => test.labID("ne", null), {
    sort: (test) => test.createdAt(SortDirection.DESCENDING),
  });

  return models;
};

const getCompletedTests = async () => {
  let models = await DataStore.query(Test, (test) => test.result("ne", null), {
    sort: (test) => test.createdAt(SortDirection.DESCENDING),
  });

  return models;
};

const getExternalTest = async () => {
  let models = await DataStore.query(ExternalTest, Predicates.ALL, {
    sort: (test) => test.createdAt(SortDirection.DESCENDING),
  });

  return models;
};

const getTotalAdminTests = async () => {
  let models = await DataStore.query(Test, (test) => test.labID("ne", null), {
    sort: (test) => test.createdAt(SortDirection.DESCENDING),
  });

  return models;
};

const getPendingTests = async () => {
  let models = await DataStore.query(Test, (test) => test.status("eq", "Pending").labID("ne", null), {
    sort: (test) => test.createdAt(SortDirection.DESCENDING),
  });

  return models;
};

const getUnSubmittedTest = async () => {
  const models = await DataStore.query(
    Test,
    (test) => test.status("eq", "Pending").result("eq", null).test_type("eq", "PCR"),
    {
      sort: (test) => test.createdAt(SortDirection.DESCENDING),
    }
  );
  return models;
};

const getUnprocessedTests = async () => {
  let currentPage = 0;
  let fetchData = true;
  let models = await DataStore.query(
    Test,
    (test) =>
      test
        .labID("ne", null)
        .result("eq", null)
        .or((test) => test.status("eq", "Processing").status("eq", "Pending").status("eq", "Sent")),
    {
      sort: (test) => test.createdAt(SortDirection.DESCENDING),
    }
  );

  return models;
};

const getAllEmployees = async () => {
  let models = await DataStore.query(Employee, Predicates.ALL);
  return models;
};

const callApi = async (path, param) => {
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: param,
  };

  const data = await API.post(apiName, path, params);
  console.log("Api Data", data);
  return data;
};

const submitUnprocessedTests = async (tests, user, env) => {
  const response = [];
  const testsByLab = {};
  console.log("tests", tests);

  let allTests = [];
  const totalTests = tests.length;

  for (const test of tests) {
    if (!testsByLab[test.labID]) {
      testsByLab[test.labID] = [test];
    } else {
      testsByLab[test.labID].push(test);
    }
  }

  for (const lab in testsByLab) {
    const labData = await getLabByID(lab);
    if (labData) {
      allTests = allTests.concat(
        testsByLab[lab].map((t) => {
          return { ...t, labName: labData.name };
        })
      );
      const [formattedTests, bucket, batch] = formatTests(labData, testsByLab[lab]);
      try {
        await Storage.put(`${batch}.csv`, formattedTests, { bucket: bucket });
        response.push({ lab: labData, tests: testsByLab[lab], error: null, totalTest: totalTests });
      } catch (e) {
        console.log("Error uploading to bucket", e);
        response.push({
          lab: labData,
          tests: testsByLab[lab],
          error: "Error uploading to bucket",
          totalTest: totalTests,
        });
      }
    }
  }

  await saveFileSendToLab(
    `${moment().format("DD_MM_YYYY_HH_mm_ss")}_send_to_lab_${CONFIG.envType}.csv`,
    allTests.map((t) => {
      return { ...t, status: "Sent", submittedBy: user.sub, submittedByName: user.name };
    })
  );

  return response;
};

const reSubmitUnprocessedTests = async (tests, user) => {
  const response = [];
  const testsByLab = {};
  for (const test of tests) {
    if (!testsByLab[test.labID]) {
      testsByLab[test.labID] = [test];
    } else {
      testsByLab[test.labID].push(test);
    }
  }

  for (const lab in testsByLab) {
    const labData = await getLabByID(lab);
    if (labData) {
      const [formattedTests, bucket, batch] = formatTests(labData, testsByLab[lab]);
      try {
        await Storage.put(`${batch}.csv`, formattedTests, { bucket: bucket });
        response.push({ lab: labData, tests: testsByLab[lab], error: null });
      } catch (e) {
        console.log("Error uploading to bucket", e);
        response.push({ lab: labData, tests: testsByLab[lab], error: "Error uploading to bucket" });
      }
    }
  }
  return response;
};

const formatTests = (labData, tests) => {
  const labName = labData.name;
  let batch;
  switch (labName.toLowerCase()) {
    case "lenco":
      batch = new Date(tests[0].createdAt)
        .toString()
        .toLocaleString("en-US", { timeZone: "America/New_York" })
        .substring(0, 21)
        .replace(/(:|\s+)/g, "_");
      return [formatLencoCSV(tests, labData), "lenco-tests", batch];
    case "alliance":
      batch = new Date(tests[0].createdAt)
        .toString()
        .toLocaleString("en-US", { timeZone: "America/New_York" })
        .substring(0, 21)
        .replace(/(:|\s+)/g, "_");
      return [formatAllianceCSV(tests, labData), "alliance-tests", batch];
    case "biolab":
      batch = new Date(tests[0].createdAt)
        .toString()
        .toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
        .substring(0, 21)
        .replace(/(:|\s+)/g, "_");
      return [formatBiolabCSV(tests, labData), "biolabs-tests", batch];
    case "sunrise":
      batch = new Date(tests[0].createdAt)
        .toString()
        .toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
        .substring(0, 21)
        .replace(/(:|\s+)/g, "_");
      return [formatSunriseCSV(tests, labData), "sunrise-tests", batch];
    case "lifebrite":
      batch = new Date(tests[0].createdAt)
        .toString()
        .toLocaleString("en-US", { timeZone: "America/New_York" })
        .substring(0, 21)
        .replace(/(:|\s+)/g, "_");
      return [formatLifebriteCSV(tests, labData), "lifebrite-tests", batch];
    case "alta":
      batch = new Date(tests[0].createdAt)
        .toString()
        .toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
        .substring(0, 21)
        .replace(/(:|\s+)/g, "_");
      return [formatAltaCSV(tests, labData), "alta-tests", batch];
    case "new discovery":
      batch = new Date(tests[0].createdAt)
        .toString()
        .toLocaleString("en-US", { timeZone: "EST" })
        .substring(0, 21)
        .replace(/(:|\s+)/g, "_");
      return [formatNewDiscoveryCSV(tests), "new-discovery-tests", batch];
    case "dls":
      batch = new Date(tests[0].createdAt)
        .toString()
        .toLocaleString("en-US", { timeZone: "HST" })
        .substring(0, 21)
        .replace(/(:|\s+)/g, "_");
      return [formatDLSCSV(tests), "dls-tests", batch];
    case "oxygen":
      batch = new Date(tests[0].createdAt)
        .toString()
        .toLocaleString("en-US", { timeZone: "EST" })
        .substring(0, 21)
        .replace(/(:|\s+)/g, "_");
      return [formatOxygenCSV(tests), "oxygen-tests", batch];
    default:
      console.log("default case");
      return [
        formatSunriseCSV(tests, labData),
        "no-lab-found-tests",
        `${labName}/${new Date(tests[0].createdAt)
          .toString()
          .substring(0, 21)
          .replace(/(:|\s+)/g, "_")}`,
      ];
  }
};

const toISOLocal = (d) => {
  var z = (n) => ("0" + n).slice(-2);
  var zz = (n) => ("00" + n).slice(-3);
  var off = d.getTimezoneOffset();
  var sign = off > 0 ? "-" : "+";
  off = Math.abs(off);

  return (
    d.getFullYear() +
    "-" +
    z(d.getMonth() + 1) +
    "-" +
    z(d.getDate()) +
    "T" +
    z(d.getHours()) +
    ":" +
    z(d.getMinutes()) +
    ":" +
    z(d.getSeconds()) +
    "." +
    zz(d.getMilliseconds()) +
    sign +
    z((off / 60) | 0) +
    ":" +
    z(off % 60)
  );
};

const parseDateOfBirth = (dob) => {
  const arr = dob.split("-");
  return `${arr[1]}/${arr[2]}/${arr[0]}`;
};

const parseDateOfBirthLenco = (dob) => {
  const arr = dob.split("-");
  return `${arr[1]}-${arr[2]}-${arr[0]}`;
};

const formatLencoCSV = (tests, labData) => {
  const formattedTests = tests.map((test) => {
    const demographics =
      typeof test.employee_demographics === "object"
        ? test.employee_demographics
        : JSON.parse(test.employee_demographics);
    const obj = {
      Provider_ID: 33133,
      patientFirst: demographics.firstName,
      patientLast: demographics.lastName,
      patientDOB: demographics.dob.includes("-")
        ? parseDateOfBirthLenco(demographics.dob)
        : `${demographics.dob.substring(0, 2)}-${demographics.dob.substring(2, 4)}-${demographics.dob.substring(4)}`,
      patientGender: demographics.sex === "M" ? "male" : "female",
      address1: demographics.street,
      addressCity: demographics.city,
      addressState: demographics.state,
      addressZip: demographics.zip.substring(0, 5),
      emailAddress: "",
      patientPhone: test.phoneNumber,
      Accession: test.barcode,
      collection_date: `${test.createdAt.substring(5, 7)}-${test.createdAt.substring(8, 10)}-${test.createdAt.substring(
        0,
        4
      )}`,
      result: "",
    };
    if (labData.sendInsurance) {
      Object.assign(obj, {
        insurance_name: demographics.insurance_name,
        insurance_number: demographics.insurance_number,
      });
    }
    return obj;
  });
  return jsonToCSV(formattedTests);
};

const formatAllianceCSV = (tests, labData) => {
  const formattedTests = tests.map((test) => {
    const demographics =
      typeof test.employee_demographics === "object"
        ? test.employee_demographics
        : JSON.parse(test.employee_demographics);
    const obj = {
      client_id: 3211,
      barcode: test.barcode,
      date_created: `${test.createdAt.substring(5, 7)}/${test.createdAt.substring(8, 10)}/${test.createdAt.substring(
        0,
        4
      )}`,
      first_name: demographics.firstName,
      last_name: demographics.lastName,
      dob: demographics.dob.includes("-")
        ? parseDateOfBirth(demographics.dob)
        : `${demographics.dob.substring(0, 2)}/${demographics.dob.substring(2, 4)}/${demographics.dob.substring(4)}`,
      sex: demographics.sex,
      address_street: demographics.street,
      address_city: demographics.city,
      address_state: demographics.state,
      address_zip: demographics.zip,
      emailAddress: "",
      patientPhone: test.phoneNumber,
      reference_id: test.referenceID,
      result: "",
    };
    if (labData.sendInsurance) {
      Object.assign(obj, {
        insurance_name: demographics.insurance_name,
        insurance_number: demographics.insurance_number,
      });
    }
    return obj;
  });
  return jsonToCSV(formattedTests);
};

const formatBiolabCSV = (tests, labData) => {
  const formattedTests = tests.map((test) => {
    const demographics =
      typeof test.employee_demographics === "object"
        ? test.employee_demographics
        : JSON.parse(test.employee_demographics);
    const obj = {
      client_id: 3739,
      barcode: test.barcode,
      date_created: toISOLocal(new Date(test.createdAt)),
      first_name: demographics.firstName,
      last_name: demographics.lastName,
      dob: demographics.dob.includes("-")
        ? parseDateOfBirth(demographics.dob)
        : `${demographics.dob.substring(0, 2)}/${demographics.dob.substring(2, 4)}/${demographics.dob.substring(4)}`,
      sex: demographics.sex,
      address_street: demographics.street,
      address_city: demographics.city,
      address_state: demographics.state,
      address_zip: demographics.zip,
      emailAddress: "",
      patientPhone: test.phoneNumber,
      reference_id: test.referenceID,
      result: "",
    };
    if (labData.sendInsurance) {
      Object.assign(obj, {
        insurance_name: demographics.insurance_name,
        insurance_number: demographics.insurance_number,
      });
    }
    return obj;
  });
  return jsonToCSV(formattedTests);
};

const formatSunriseCSV = (tests, labData) => {
  const formattedTests = tests.map((test) => {
    const demographics =
      typeof test.employee_demographics === "object"
        ? test.employee_demographics
        : JSON.parse(test.employee_demographics);
    const obj = {
      client_id: 36,
      barcode: test.barcode,
      date_created: toISOLocal(new Date(test.createdAt)),
      first_name: demographics.firstName,
      last_name: demographics.lastName,
      dob: demographics.dob.includes("-")
        ? parseDateOfBirth(demographics.dob)
        : `${demographics.dob.substring(0, 2)}/${demographics.dob.substring(2, 4)}/${demographics.dob.substring(4)}`,
      sex: demographics.sex,
      address_street: demographics.street,
      address_city: demographics.city,
      address_state: demographics.state,
      address_zip: demographics.zip,
      emailAddress: "",
      patientPhone: test.phoneNumber,
      reference_id: test.referenceID,
      result: "",
    };
    if (labData.sendInsurance) {
      Object.assign(obj, {
        insurance_name: demographics.insurance_name,
        insurance_number: demographics.insurance_number,
      });
    }
    return obj;
  });
  return jsonToCSV(formattedTests);
};

const formatAltaCSV = (tests, labData) => {
  const formattedTests = tests.map((test) => {
    const demographics =
      typeof test.employee_demographics === "object"
        ? test.employee_demographics
        : JSON.parse(test.employee_demographics);
    const obj = {
      client_id: 1,
      barcode: test.barcode,
      date_created: toISOLocal(new Date(test.createdAt)),
      first_name: demographics.firstName,
      last_name: demographics.lastName,
      dob: demographics.dob.includes("-")
        ? parseDateOfBirth(demographics.dob)
        : `${demographics.dob.substring(0, 2)}/${demographics.dob.substring(2, 4)}/${demographics.dob.substring(4)}`,
      sex: demographics.sex,
      address_street: demographics.street,
      address_city: demographics.city,
      address_state: demographics.state,
      address_zip: demographics.zip,
      emailAddress: "",
      patientPhone: test.phoneNumber,
      reference_id: test.referenceID,
      result: "",
    };
    if (labData.sendInsurance) {
      Object.assign(obj, {
        insurance_name: demographics.insurance_name,
        insurance_number: demographics.insurance_number,
      });
    }
    return obj;
  });
  return jsonToCSV(formattedTests);
};

const formatLifebriteCSV = (tests, labData) => {
  const formattedTests = tests.map((test) => {
    const demographics =
      typeof test.employee_demographics === "object"
        ? test.employee_demographics
        : JSON.parse(test.employee_demographics);
    const obj = {
      barcode: test.barcode,
      date_created: toISOLocal(new Date(test.createdAt)).split("T")[0],
      first_name: demographics.firstName,
      last_name: demographics.lastName,
      dob: demographics.dob.includes("-")
        ? parseDateOfBirth(demographics.dob)
        : `${demographics.dob.substring(0, 2)}/${demographics.dob.substring(2, 4)}/${demographics.dob.substring(4)}`,
      sex: demographics.sex,
      address_street: demographics.street,
      address_city: demographics.city,
      address_state: demographics.state,
      address_zip: demographics.zip,
      emailAddress: "",
      patientPhone: test.phoneNumber,
      reference_id: test.referenceID,
      insurance_name: demographics.insurance_name,
      insurance_number: demographics.insurance_number,
      test_code: 1,
      result: "",
    };
    if (labData.sendInsurance) {
      Object.assign(obj, {
        insurance_name: demographics.insurance_name,
        insurance_number: demographics.insurance_number,
      });
    }
    return obj;
  });
  return jsonToCSV(formattedTests);
};

const formatDLSCSV = (tests) => {
  const formattedTests = tests.map((test) => {
    const demographics =
      typeof test.employee_demographics === "object"
        ? test.employee_demographics
        : JSON.parse(test.employee_demographics);
    return {
      PatientFirstName: demographics.firstName,
      PatientMiddleName: "",
      PatientLastName: demographics.lastName,
      patientDOB: demographics.dob.includes("-")
        ? parseDateOfBirthLenco(demographics.dob)
        : `${demographics.dob.substring(0, 2)}-${demographics.dob.substring(2, 4)}-${demographics.dob.substring(4)}`,
      barcode: test.barcode,
      date_created: test.createdAt.toString(),
      PatientGender: demographics.sex,
      Address1: demographics.street,
      Address2: "",
      AddressCity: demographics.city,
      AddressState: demographics.state,
      AddressZip: demographics.zip,
      EmailAddress: "",
      PatientPhone: test.phoneNumber,
      UniqueID: test.id,
      AccessionOrderID: test.barcode,
      TestkitID: "",
      Covid19TestCode: "",
      SpecimenSource: "OP",
      CollectionDateTime: "",
      OrderingPhysician: "",
      Race: "UNK: Unknown",
      Ethnicity: "UNK: Unknown",
      FirstCovidTest: "",
      HealthCareWorker: "",
      CovidSymptomatic: "",
      SymptomOnsetDate: "",
      CovidHospitalized: "",
      InICU: "",
      CongregateCareSetting: "",
      Pregnant: "",
      result: "",
    };
  });
  return jsonToCSV(formattedTests);
};

const formatNewDiscoveryCSV = (tests) => {
  const formattedTests = tests.map((test) => {
    const demographics =
      typeof test.employee_demographics === "object"
        ? test.employee_demographics
        : JSON.parse(test.employee_demographics);
    return {
      barcode: test.barcode,
      date_created: test.createdAt,
      first_name: demographics.firstName,
      last_name: demographics.lastName,
      dob: demographics.dob.includes("-")
        ? parseDateOfBirth(demographics.dob)
        : `${demographics.dob.substring(0, 2)}/${demographics.dob.substring(2, 4)}/${demographics.dob.substring(4)}`,
      sex: demographics.sex,
      address_street: demographics.street,
      address_city: demographics.city,
      address_state: demographics.state,
      address_zip: demographics.zip,
      emailAddress: "",
      patientPhone: test.phoneNumber,
      reference_id: test.referenceID,
      result: "",
    };
  });
  return jsonToCSV(formattedTests);
};

const formatOxygenCSV = (tests) => {
  const formattedTests = tests.map((test) => {
    const demographics =
      typeof test.employee_demographics === "object"
        ? test.employee_demographics
        : JSON.parse(test.employee_demographics);
    return {
      accession_number: test.barcode,
      requisition_number: test.referenceID,
      date: new Date(test.createdAt).toString().substring(0, 15),
      time: new Date(test.createdAt).toString().substring(16),
      clinic_id: "52a3c04d-2b81-43c0-8c9d-5a5ae8f067fc",
      first_name: demographics.firstName,
      last_name: demographics.lastName,
      patient_dob: demographics.dob.includes("-")
        ? parseDateOfBirth(demographics.dob)
        : `${demographics.dob.substring(0, 2)}/${demographics.dob.substring(2, 4)}/${demographics.dob.substring(4)}`,
      patient_gender: demographics.sex,
      patient_phone: test.phoneNumber,
      patient_email: "",
      patient_ethnicity_code: "U",
      patient_race_code: "U",
      provider_first_name: "",
      provider_last_name: "",
      provider_npi: "1111111111",
      provider_phone_number: "",
      specimen_type: "OS",
      panel_type: "COV  (COVID-19 (2019 Coronavirus (SARS-CoV-2)))",
      result: "",
    };
  });
  return jsonToCSV(formattedTests);
};

const getCurrentLabUserTests = async () => {
  const user = await getCurrentUser();

  let lab = await DataStore.query(Lab, (lab) => lab.user_id("eq", user.sub));
  if (lab.length === 0) {
    const labs = await DataStore.query(Lab);
    lab = labs.filter((l) => {
      return l.lab_contacts && l.lab_contacts.includes(user.sub);
    });
    if (lab.length === 0) return [];
  }

  if (lab[0].default_antigen || lab[0].default_molecular) {
    const models = await DataStore.query(
      Test,
      (test) =>
        test
          .labID("eq", lab[0].id)
          .test_type("eq", "PCR")
          .or((test) => test.status("eq", "Processing").status("eq", "Pending").status("eq", "Sent")),
      {
        sort: (test) => test.createdAt(SortDirection.DESCENDING),
      }
    );

    return models.filter((t) => !t.result);
  }

  const models = await DataStore.query(
    Test,
    (test) =>
      test
        .labID("eq", lab[0].id)
        .test_type("eq", "PCR")
        .or((test) => test.status("eq", "Processing").status("eq", "Pending").status("eq", "Sent")),
    {
      sort: (test) => test.createdAt(SortDirection.DESCENDING),
    }
  );

  return models.filter((t) => !t.result);

  return models;
};

const getAllCurrentLabUserTests = async () => {
  const user = await getCurrentUser();
  let lab = await DataStore.query(Lab, (lab) => lab.user_id("eq", user.sub));
  if (lab.length === 0) {
    const labs = await DataStore.query(Lab);
    lab = labs.filter((l) => {
      return l.lab_contacts && l.lab_contacts.includes(user.sub);
    });
    if (lab.length === 0) return [];
  }

  const models = await DataStore.query(Test, (test) => test.labID("eq", lab[0].id), {
    sort: (test) => test.createdAt(SortDirection.DESCENDING),
  });
  return models.filter((model) => model.result);
};

const getCurrentLabUserProcessedTests = async (id, user) => {
  let labID = id;
  if (!labID) {
    const lab = await DataStore.query(Lab, (lab) => lab.user_id("eq", user.sub));
    if (lab.length > 0) {
      labID = lab[0].id;
    } else {
      const labs = await DataStore.query(Lab);
      const filterLab = labs.filter((l) => {
        return l.lab_contacts && l.lab_contacts.includes(user.sub);
      });
      if (filterLab.length > 0) {
        labID = filterLab[0].id;
      }
    }
  }

  if (labID === null) return [];

  let currentPage = 0;
  let fetchData = true;
  const models = await DataStore.query(
    Test,
    (test) =>
      test
        .labID("eq", labID)
        .result("ne", null)
        .test_type("eq", "PCR")
        .or((test) => test.status("eq", "Processed").status("eq", "Pending")),
    {
      sort: (test) => test.createdAt(SortDirection.DESCENDING),
    }
  );

  const tests = models.map((test) => {
    const obj = formatTest(test);
    obj.status = TEST_STATUS.Processed;
    return obj;
  });

  return tests;
};
const getPendingReleaseTests = async () => {
  const models = await DataStore.query(
    Test,
    (test) => test.status("eq", "Pending").or((t) => t.result("eq", "Positive").result("eq", "positive")),
    {
      sort: (test) => test.updatedAt(SortDirection.DESCENDING),
    }
  );

  return models;
};

const sendToLab = async (tests = []) => {
  tests.forEach(async (test) => {
    await DataStore.save(
      Test.copyOf(test, (updated) => {
        updated.status = "Sent";
      })
    );
  });
};

const findTestByID = async (id) => {
  const models = await DataStore.query(Test, (test) => test.id("eq", id));
  // console.log("findTestByID", models);
  return models[0];
};

const findTestByBarcode = async (id) => {
  const models = await DataStore.query(Test, (test) => test.barcode("eq", id));
  if (models.length > 0) return models[0];

  const byId = await DataStore.query(Test, (test) => test.id("eq", id));
  if (byId.length > 0) return byId[0];

  return null;
};
const findExternalTestByID = async (testID) => {
  const models = await DataStore.query(ExternalTest, (test) => test.id("eq", testID));
  console.log(models);
  return models[0];
};
const getTestTypes = async () => {
  const models = await DataStore.query(TestTypes, Predicates.ALL, {
    sort: (t) => t.name(SortDirection.ASCENDING),
  });
  console.log("getTestTypes", models);
  return models;
};

const updateTestTypes = async (obj) => {
  console.log("IN UPDATE Obj ", obj);
  const items = await DataStore.query(TestTypes, (t) => t.id("eq", obj.id));
  return await DataStore.save(
    TestTypes.copyOf(items[0], (updated) => {
      updated.totalTime = obj.totalTime;
      updated.firstAlert = obj.firstAlert;
      updated.secondAlert = obj.secondAlert;
      updated.duration = obj.duration ? parseInt(obj.duration) : 0;
    })
  );
};

const updateTestFalsePositive = async () => {
  const items = await DataStore.query(Test, (t) => t.sequenceNo("eq", 22923));
  if (items.length > 0) {
    return await DataStore.save(
      Test.copyOf(items[0], (updated) => {
        updated.isFalsePositive = false;
      })
    );
  }
};

const findTestsByClient = async (clientID) => {
  const models = await DataStore.query(Test, (test) => test.clientID("eq", clientID).result("ne", "Expired"));
  // console.log(models);
  return models;
};

const findTestsBySite = async (siteID) => {
  const models = await DataStore.query(Test, (test) => test.siteID("eq", siteID).result("ne", "Expired"));
  return models;
};

const findTestsByLab = async (labID) => {
  const models = await DataStore.query(Test, (test) => test.labID("eq", labID).result("ne", "Expired"));
  return models;
};

const fetchAllUsersInGroup = async (groupName) => {
  const path = "/listUsersInGroup";
  let NextToken = null;
  let users = [];
  try {
    do {
      const myInit = {
        queryStringParameters: {
          groupname: groupName,
          limit: 60,
          token: NextToken,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        },
      };
      const models = await API.get(apiName, path, myInit);
      console.log(`Fetching ${groupName}`, models);
      NextToken = models.NextToken || null;
      users = users.concat(models.Users);
    } while (NextToken);
    return users;
  } catch (e) {
    console.log("Error getting users:", e);
  }
};

const fetchAllCrewMembers = async (groupName, NextToken) => {
  const path = "/listUsersInGroup";
  let users = [];
  const myInit = {
    queryStringParameters: {
      groupname: groupName,
      limit: 60,
      token: NextToken,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  const models = await API.get(apiName, path, myInit);
  return models;
};

const fetchAllEmployees = async (phone) => {
  const models = await DataStore.query(Employee, (test) => test.phone_number("eq", phone));
  return models;
};

const fetchUsersInGroup = async (groupName) => {
  const path = "/listUsersInGroup";
  try {
    const myInit = {
      queryStringParameters: {
        groupname: groupName,
        limit: 60,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const models = await API.get(apiName, path, myInit);

    return models.Users;
  } catch (e) {
    console.log("Error getting users:", e);
  }
};

const fetchAllUsers = async (filter) => {
  let path = "/listUsers";
  let params = {
    queryStringParameters: {
      limit: 60,
      filter: filter,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  const models = await API.get(apiName, path, params);
  return models;
};

const changeUserRole = async (prevRole, newRole, username) => {
  console.log(prevRole, newRole, username);
  let path = "/removeUserFromGroup";
  let prevRoleAlias;
  if (prevRole === "Crew") {
    prevRoleAlias = "Employee";
  } else if (prevRole === "Health & Safety") {
    prevRoleAlias = "Employer";
  } else {
    prevRoleAlias = prevRole;
  }
  let params = {
    body: {
      username: username,
      groupname: prevRoleAlias + "s",
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const roleRemoval = await API.post(apiName, path, params);
    path = "/addUserToGroup";
    params.body["groupname"] = newRole + "s";
    const roleChange = await API.post(apiName, path, params);
    // console.log("Changing role", roleRemoval, roleChange);
  } catch (e) {
    console.log("Error changing user role:", e);
    alert("Unable to change user role");
  }
};

const addUserToGroup = async (newRole, username) => {
  let params = {
    body: {
      username: username,
      groupname: newRole + "s",
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const path = "/addUserToGroup";
    const roleChange = await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing user role:", e);
    alert("Unable to Add user role");
  }
};

const confirmUserSignUp = async (username) => {
  console.log(username);
  const path = "/confirmUserSignUp";
  try {
    const params = {
      body: {
        username: username,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const done = await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing user role:", e);
    alert("Fail to confirm user");
  }
};

const updateUserInfo = async (user) => {
  console.log("[update_info]", user);
  const path = "/updateUserInfo";
  let note = "";
  let connectedID = "";
  if (user.show) {
    note = user.show.label;
    connectedID = user.show.id;
  } else if (user.lab) {
    note = user.lab.label;
    connectedID = user.lab.id;
  } else if (user.client) {
    note = user.client.label;
  } else if (user.role === "Admin" || user.role === "Tester") note = "System";
  else note = "";

  try {
    const infoUpdated = await callAmplifyAPI(path, {
      username: user.username,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      note: note,
      clientID: user.client ? user.client.id : "",
      connectedID: connectedID,
    });
    console.log("[update_info]", infoUpdated);
  } catch (e) {
    console.log("Error changing user role:", e);
    alert("Unable to update user info");
  }
};

const updateUserSecondRole = async (user) => {
  console.log("[updateUserSecondRole]", user);
  const path = "/updateUserSecondRole";

  const params = {
    body: {
      username: user.username,
      role: user.adRole,
      connectedID: user.connectedID,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
    console.log("[update_info]", infoUpdated);
  } catch (e) {
    console.log("Error changing user role:", e);
    alert("Unable to update user info");
  }
};

const updateAssociatedInfo = async (username, note) => {
  console.log("[update_info]", username, note);
  const path = "/updateAssociatedInfo";

  const params = {
    body: {
      username: username,
      note: note,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing user role:", e);
  }
};

const updateUserPassword = async (username, password) => {
  console.log("[update_user_password_info]", username, password);
  const path = "/updateUserPassword";
  const params = {
    body: {
      username: username,
      password: password,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  const infoUpdated = await API.post(apiName, path, params);
  console.log("[update_user_password_info]", infoUpdated);
};

const deleteUser = async (username) => {
  console.log("[delete_user_password_info]", username);
  const path = "/deleteUser";
  const params = {
    body: {
      username: username,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing user role:", e);
    alert("Unable to update user password");
  }
};

const sendSMSNotification = async (phoneNumber, message) => {
  try {
    const notification = await axios.post(AWS_ENDPOINT + "/notification", {
      phone_number: phoneNumber.replace(/\D/g, ""),
      message: message,
    });
    return notification;
  } catch (error) {
    console.log(error);
  }
};
const updateClientsHippa = async (id, newHippa, fileName) => {
  const client = await DataStore.query(Client, (client) => client.id("eq", id));
  await DataStore.save(
    Client.copyOf(client[0], (updated) => {
      updated.hippa = newHippa;
      updated.hippaFile = fileName || "";
    })
  );
};

const updateClient = async (newClient) => {
  const client = await DataStore.query(Client, (client) => client.id("eq", newClient.id));
  await DataStore.save(
    Client.copyOf(client[0], (updated) => {
      updated.name = newClient.name;
      updated.contact_name = newClient.contact;
      updated.contact_phone = setPhoneNo(newClient.phone);
      updated.contact_email = newClient.email;
      updated.resultType = newClient.resultType;
      updated.showOnSummaryScreen = newClient.showOnSummaryScreen;
      updated.whiteLabel = newClient.whiteLabel;
      updated.whiteLabelPackage = newClient.whiteLabelPackage;
      updated.isArchive = newClient.isArchive;
    })
  );
};

const updateSite = async (newSite) => {
  console.log("UPDATING", newSite);
  const site = await DataStore.query(Site, (site) => site.id("eq", newSite.id));
  await DataStore.save(
    Site.copyOf(site[0], (updated) => {
      updated.name = newSite.name;
      updated.contact_name = newSite.contact;
      updated.contact_phone = setPhoneNo(newSite.phone);
      updated.contact_email = newSite.email;
      updated.antigen = newSite.antigen;
      updated.automateTestType = newSite.automateTestType;
      updated.insurance_required = newSite.insurance_required;
      updated.sendInsuranceCard = newSite.sendInsuranceCard;
      updated.resetTestDay = newSite.resetTestDay;
      updated.startDate = newSite.startDate;
      updated.endDate = newSite.endDate;
      updated.clientID = newSite.clientID;
      updated.showOnSummaryScreen = newSite.showOnSummaryScreen;
      updated.messageTested = newSite.messageTested;
      updated.messageResulted = newSite.messageResulted;
      updated.messagePositive = newSite.messagePositive;
      updated.isArchive = newSite.isArchive;
      updated.vaccinationCard = newSite.vaccinationCard;
      updated.admit = newSite.admit;
      updated.externalTest = newSite.externalTest;
      updated.externalTestAdmit = newSite.externalTestAdmit;
      updated.externalTestType = newSite.externalTestType;
      updated.patternTesting = newSite.patternTesting;
      updated.patternHippa = newSite.patternHippa;
      updated.preRegistration = newSite.preRegistration;
      updated.orderKitDate = newSite.orderKitDate ? moment(newSite.orderKitDate).toISOString() : null;
      updated.enableShipment = newSite.enableShipment;
      updated.orderQuantity = newSite.orderQuantity;
    })
  );
  return;
};

const updateSiteArchive = async (id, isArchive) => {
  console.log("UPDATING SITE ARCHIVE", id, isArchive);
  const res = await DataStore.query(Site, (site) => site.id("eq", id));
  if (res.length === 0) return;
  await DataStore.save(
    Site.copyOf(res[0], (updated) => {
      updated.isArchive = isArchive;
    })
  );
  return;
};

const addUserToSite = async (updatedSite, user) => {
  console.log("FROM API", updatedSite, user);
  const models = await DataStore.query(Site, (site) => site.id("eq", updatedSite.id));
  return await DataStore.save(
    Site.copyOf(models[0], (updated) => {
      if (updated.show_contacts === null) {
        updated.show_contacts = [user];
      } else {
        if (models[0].show_contacts.indexOf(user) === -1) {
          updated.show_contacts = [...models[0].show_contacts, user];
        }
      }
    })
  );
};

const removeUserToSite = async (updatedSite, user) => {
  console.log("FROM API", updatedSite, user);
  const models = await DataStore.query(Site, (site) => site.id("eq", updatedSite.id));
  return await DataStore.save(
    Site.copyOf(models[0], (updated) => {
      if (updated.show_contacts !== null) {
        updated.show_contacts = updated.show_contacts.filter((lc) => lc !== user);
      }
    })
  );
};

const addUserToLab = async (newLab, user) => {
  if (newLab) {
    const lab = await DataStore.query(Lab, (lab) => lab.id("eq", newLab.id));
    console.log("[ADD_USER_TO_LAB]", lab);
    try {
      if (lab.length > 0) {
        const result = await DataStore.save(
          Lab.copyOf(lab[0], (updated) => {
            if (updated.lab_contacts === null) {
              updated.lab_contacts = [user];
            } else {
              if (updated.lab_contacts.indexOf(user) === -1) updated.lab_contacts = [...updated.lab_contacts, user];
            }
          })
        );
        console.log("[ADD_USER_TO_LAB]", result);
      }
    } catch (e) {
      console.log("Error adding user to lab:", e);
    }
  }
};

const removeUserToLab = async (prevLab, user) => {
  if (prevLab) {
    const lab = await DataStore.query(Lab, (lab) => lab.id("eq", prevLab.id));
    console.log("[REMOVE_USER_TO_LAB]", lab);
    try {
      if (lab.length > 0) {
        const result = await DataStore.save(
          Lab.copyOf(lab[0], (updated) => {
            if (updated.lab_contacts !== null) {
              updated.lab_contacts = updated.lab_contacts.filter((lc) => lc !== user);
            }
          })
        );
        console.log("[REMOVE_USER_TO_LAB]", result);
      }
    } catch (e) {
      console.log("Error adding user to lab:", e);
    }
  }
};

const updateLab = async (newLab) => {
  console.log("IN UPDATE LAB ", newLab);
  const lab = await DataStore.query(Lab, (lab) => lab.id("eq", newLab.id));
  return await DataStore.save(
    Lab.copyOf(lab[0], (updated) => {
      updated.name = newLab.name;
      updated.contact_name = newLab.contact;
      updated.contact_phone = setPhoneNo(newLab.phone);
      updated.contact_email = newLab.email;
      updated.street = newLab.street;
      updated.city_state_zip = newLab.city_state_zip;
      updated.lab_director = newLab.lab_director;
      updated.clia_number = newLab.clia_number;
      updated.testing_notes = newLab.testing_notes;
      updated.default_antigen = newLab.default_antigen;
      updated.default_molecular = newLab.default_molecular;
      updated.default_other = newLab.default_other;
      updated.tubes_provided = newLab.tubes_provided;
      updated.sendInsurance = newLab.sendInsurance;
      updated.logo = newLab.logo;
      updated.barCodeProvided = newLab.barCodeProvided;
      updated.barCodeAlertLimit = newLab.barCodeAlertLimit;
      updated.antigen_notes = newLab.default_antigen ? newLab.antigen_notes : "";
      updated.molecular_notes = newLab.default_molecular ? newLab.molecular_notes : "";
      updated.other_notes = newLab.default_other ? newLab.other_notes : "";
      updated.showOnSummaryScreen = newLab.showOnSummaryScreen;
      updated.whiteLabel = newLab.whiteLabel;
      updated.whiteLabelPackage = newLab.whiteLabelPackage;
      updated.isArchive = newLab.isArchive;
    })
  );
};

const updateLabBarCodes = async (barCodeData, id) => {
  barCodeData.forEach(async (row) => {
    await DataStore.save(
      new LencoBarcodes({
        labID: id,
        barcode: row.barcode,
        available: true,
      })
    );
  });
};

const getLabBarCodes = async (id) => {
  const models = await DataStore.query(LencoBarcodes, (lab) => lab.labID("eq", id).available("eq", true));
  return models;
};

const updateLabLogo = async (id, fileName) => {
  const lab = await DataStore.query(Lab, (lab) => lab.id("eq", id));
  return await DataStore.save(
    Lab.copyOf(lab[0], (updated) => {
      updated.logo = fileName;
    })
  );
};

const updateBarCodeAlert = async (val) => {
  const labs = await DataStore.query(Lab, (lab) => lab.barCodeProvided("eq", true));
  console.log("Labs", labs, parseInt(val));
  labs.forEach(async (lab) => {
    await DataStore.save(
      Lab.copyOf(lab, (updated) => {
        updated.barCodeAlertLimit = parseInt(val);
      })
    );
  });
};

const resetDefaultAntigenLab = async (labID) => {
  const labs = await DataStore.query(Lab, (lab) => lab.id("ne", labID).whiteLabel("eq", false));
  labs.forEach(async (lab) => {
    await DataStore.save(
      Lab.copyOf(lab, (updated) => {
        updated.default_antigen = false;
      })
    );
  });
};

const resetDefaultMolecularLab = async (labID) => {
  const labs = await DataStore.query(Lab, (lab) => lab.id("ne", labID).whiteLabel("eq", false));
  labs.forEach(async (lab) => {
    await DataStore.save(
      Lab.copyOf(lab, (updated) => {
        updated.default_molecular = false;
      })
    );
  });
};

const resetDefaultOtherLab = async (labID) => {
  const labs = await DataStore.query(Lab, (lab) => lab.id("ne", labID).whiteLabel("eq", false));
  labs.forEach(async (lab) => {
    await DataStore.save(
      Lab.copyOf(lab, (updated) => {
        updated.default_other = false;
      })
    );
  });
};

// const AWS = require('aws-sdk');
// AWS.config.update({
//   accessKeyId: process.env.AWS_ACCESS_KEY,

// })

const buildAWSDate = (dobFromID) => {
  const awsdob = [];
  awsdob.push(dobFromID.substring(4));
  awsdob.push(dobFromID.substring(0, 2));
  awsdob.push(dobFromID.substring(2, 4));
  return awsdob.join("-");
};

const findHippaConsent = async (employeeID, showId) => {
  const models = await DataStore.query(TestConsent, (test) => test.employeeID("eq", employeeID).siteID("eq", showId));
  return models;
};

const deleteWrongTest = async (ids) => {
  const path = "/deleteTests";

  const apiRes = await callAmplifyAPI(path, {
    ids: ids,
  });
  return apiRes.data;
};

const updateDemographics = (c, crew) => {
  const demos = { ...c.employee_demographics };
  demos.firstName = crew.firstName;
  demos.lastName = crew.lastName;
  demos.email = crew.email;
  return demos;
};

const updateCrewInfo = async (crew) => {
  const res = await DataStore.query(Test, (test) => test.employeeID("eq", crew.employeeID));
  res.forEach(async (c) => {
    await DataStore.save(
      Test.copyOf(c, (updated) => {
        updated.employee_demographics = updateDemographics(c, crew);
        updated.email = crew.email;
      })
    );
  });

  const employee = await DataStore.query(Employee, (test) => test.id("eq", crew.employeeID));
  employee.forEach(async (e) => {
    await DataStore.save(
      Test.copyOf(e, (updated) => {
        updated.first = crew.firstName;
        updated.last = crew.lastName;
        updated.email = crew.email;
      })
    );
  });

  console.log("Data", crew, employee);
};

const updateCompleteCrewInfo = async (crew) => {
  const res = await DataStore.query(Test, (test) => test.id("eq", crew.id));
  const [countryCode, phoneNo] = getPhoneNo(crew.employee_demographics.phoneNumber);

  const dbTest = res[0];
  await DataStore.save(
    Test.copyOf(dbTest, (updated) => {
      updated.employee_demographics = { ...crew.employee_demographics };
      updated.phoneNumber = phoneNo;
      updated.email = crew.employee_demographics.email;
    })
  );
  const demographics = crew.employee_demographics;

  const employeeData = {
    id: crew.employeeID,
    first: demographics.firstName,
    last: demographics.lastName,
    dob: moment(demographics.dob).format("YYYY-MM-DD"),
    id_number: demographics.idNumber,
    email: crew.employee_demographics.email,
    phone_number: phoneNo,
    countryCode: countryCode,
    street: demographics.street,
    street2: demographics.street2,
    city: demographics.city,
    state: demographics.state,
    zip: demographics.zip,
    vaccinationCardImage: demographics.vaccinationCardImage,
    externalTestImage: demographics.externalTestImage,
    _version: demographics._version,
  };

  const updatedTodo = await API.graphql({ query: mutations.updateEmployee, variables: { input: employeeData } });

  // const employee = await DataStore.query(Employee, (test) => test.id("eq", crew.employeeID));
  // const dbEmp = employee[0];
  // await DataStore.save(
  //   Employee.copyOf(dbEmp, (updated) => {
  //     updated.first = demographics.firstName;
  //     updated.last = demographics.lastName;
  //     updated.dob = buildAWSDate(demographics.dob);
  //     updated.id_number = demographics.idNumber;
  //     updated.phone_number = demographics.phoneNumber;
  //     updated.street = demographics.street;
  //     updated.street2 = demographics.street2;
  //     updated.city = demographics.city;
  //     updated.state = demographics.state;
  //     updated.zip = demographics.zip;
  //   })
  // );
  console.log("Data", crew, updatedTodo, res);
};

const sendEmailToCrew = async (data) => {
  console.log("[sendEmailToCrew]", data);
  // const path = "/sendEmailToCrew";
  const path = "/sendEmailAttachement";

  const params = {
    body: {
      data: data,
      isPortalCall: true,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  const infoUpdated = await API.post(apiName, path, params);
};

const sendInvitationEmail = async (data) => {
  console.log("[sendGeneralEmail]", data);
  // const path = "/sendEmailToCrew";
  const path = "/sendGeneralEmail";

  const params = {
    body: {
      data: data,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  const infoUpdated = await API.post(apiName, path, params);
};

const getTestAutoNumber = async () => {
  console.log("[getAutoNumber]");
  const path = "/getAutoNumber";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {},
  };

  const counterData = await API.post(apiName, path, params);
  console.log("Counter Api", counterData);
  return counterData;
};

const getOrderAutoNumber = async () => {
  console.log("[getOrderAutoNumber]");
  const path = "/orders/orderNo";

  const counterData = await callAmplifyAPI(path, {});
  console.log("Counter Api", counterData);
  return counterData;
};

const getLabTestAutoNumber = async (date, labID) => {
  console.log("[getLabAutoNumber]");
  const path = "/getLabAutoNumber";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: { date: date, labID },
  };

  const counterData = await API.post(apiName, path, params);
  console.log("Counter Api", counterData);
  return counterData;
};

const getPreRegisterData = async () => {
  console.log("[getPreRegisterRecord]");
  const path = "/getPreRegisterRecord";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      // phone_number: "26951b33-c456-42de-9afb-33431695d56e",
      phone_number: "8322999999",
    },
  };

  const counterData = await API.post(apiName, path, params);

  const params1 = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      phone_number: "57b4f60a-acec-4150-8224-49eb3d2e5c8e",
    },
  };

  await API.post(apiName, path, params1);
  console.log("Counter Api", counterData);
};

const deletePreRegistration = async (id) => {
  console.log("[deletePreRegistration]");
  const path = "/deletePreRegistration";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: id,
    },
  };

  const counterData = await API.post(apiName, path, params);
};

const getTestListFromApi = async (siteid) => {
  console.log("[testList]");
  const path = "/testList";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      siteID: siteid,
    },
  };

  const apiRes = await API.post(apiName, path, params);
  if (apiRes.Items) {
    return apiRes.Items.filter((f) => f._delete !== true && f.result);
  }
  return [];
};

const getTestLogs = async (id) => {
  const path = "/getTestLogs";
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: id,
    },
  };

  const apiRes = await API.post(apiName, path, params);
  return apiRes.data;
};

const getAppTestLogs = async (id) => {
  const path = "/getAppTestLogs";
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: id,
    },
  };

  const apiRes = await API.post(apiName, path, params);
  return apiRes.data;
};
const getAdminTestList = async (id) => {
  const path = "/adminTestList";
  let items = [];
  let nextPage = null;
  do {
    const params = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
      body: {
        siteID: id,
        siteKey: "employeeID",
        siteIndex: "byEmployee",
        nextPage: nextPage,
      },
    };
    const apiRes = await API.post(apiName, path, params);
    nextPage = apiRes.LastEvaluatedKey ?? null;
    items = items.concat(apiRes.Items);
  } while (nextPage);
  return items;
};
const getShowPreRegistration = async (id) => {
  const path = "/getShowPreRegistration";
  let items = [];
  let nextPage = null;
  const token = (await Auth.currentSession()).getAccessToken().getJwtToken();

  do {
    const params = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: {
        id: id,
        nextPage: nextPage,
      },
    };
    const apiRes = await API.post(apiName, path, params);
    nextPage = apiRes.data.LastEvaluatedKey ?? null;
    items = items.concat(apiRes.data.Items);
  } while (nextPage);

  return items;
};

const addTestLogs = async (obj) => {
  const path = "/addTestLogs";
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      params: obj,
    },
  };

  const apiRes = await API.post(apiName, path, params);
  return apiRes.data;
};

const addTestAppLogs = async (obj) => {
  const path = "/addTestAppLogs";
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      data: obj,
    },
  };

  const apiRes = await API.post(apiName, path, params);
  return apiRes.data;
};

const getSettingForApp = async (siteid) => {
  console.log("[testList]");
  const path = "/getSettingForApp";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {},
  };

  const apiRes = await API.post(apiName, path, params);
  return apiRes;
};

const getTestFromApi = async (id) => {
  console.log("[testDetails]");
  const path = "/adminTestList";
  let tests = [];
  let nextPage = null;
  const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
  do {
    const params = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: {
        siteID: id,
        siteKey: "clientID",
        siteIndex: "byClient",
        nextPage: nextPage,
      },
    };
    const apiRes = await API.post(apiName, path, params);
    nextPage = apiRes.LastEvaluatedKey ?? null;
    tests = tests.concat(apiRes.Items);
  } while (nextPage);
  return tests;
};

const saveUserPersonalisation = async (userID, personalisationData) => {
  const path = "/saveUserPersonalisation";
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      userID: userID,
      personalisationData: personalisationData,
    },
  };

  const apiRes = await API.post(apiName, path, params);
  return apiRes.data;
};

const getUserPersonalisation = async (userID) => {
  const path = "/getUserPersonalisation";
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      userID: userID,
    },
  };

  const apiRes = await API.post(apiName, path, params);
  return apiRes.data;
};

const createDelivery = async (deliveryObj) => {
  const path = "/addPRTestLogs";
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      obj: deliveryObj,
    },
  };

  const apiRes = await API.post(apiName, path, params);
  return apiRes.data;
};

const updateEmailStatus = async (id) => {
  const res = await DataStore.query(Test, (test) => test.id("eq", id));
  if (res.length === 0) return;
  await DataStore.save(
    Test.copyOf(res[0], (updated) => {
      updated.emailSend = true;
    })
  );
};

const updateTestResult = async (tests) => {
  await Storage.put(`${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_result_${CONFIG.envType}.csv`, jsonToCSV(tests), {
    bucket: "result-update",
  });
};

const updateWhiteLabelTestResult = async (tests, isSimpleResult) => {
  const fileName = isSimpleResult ? "simplelabresult" : "labresult";
  await Storage.put(
    `${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_${fileName}_${CONFIG.envType}.csv`,
    jsonToCSV(tests),
    {
      bucket: "result-update",
    }
  );
};

const updateTestLab = async (tests) => {
  await Storage.put(
    `${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_lab_change_${CONFIG.envType}.csv`,
    jsonToCSV(tests),
    {
      bucket: "result-update",
    }
  );
};

const updateTestType = async (tests) => {
  await Storage.put(
    `${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_change_test_type_${CONFIG.envType}.csv`,
    jsonToCSV(tests),
    {
      bucket: "result-update",
    }
  );
};

const updateTestClientLab = async (tests) => {
  await Storage.put(
    `${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_change_config_${CONFIG.envType}.csv`,
    jsonToCSV(tests),
    {
      bucket: "result-update",
    }
  );
};

const updateStatusByLab = async (tests) => {
  await Storage.put(`${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_lab_${CONFIG.envType}.csv`, jsonToCSV(tests), {
    bucket: "result-update",
  });
};

const releaseTest = async (tests) => {
  await Storage.put(
    `${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_pending_release_${CONFIG.envType}.csv`,
    jsonToCSV(tests),
    {
      bucket: "result-update",
    }
  );
};

const sendEmailToPendingRelease = async (tests) => {
  await Storage.put(`${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_email_${CONFIG.envType}.csv`, jsonToCSV(tests), {
    bucket: "result-update",
  });
};

const sendSmsToCrews = async (tests) => {
  console.log("tests", tests);
  await Storage.put(
    `${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_invalid_sms_${CONFIG.envType}.csv`,
    jsonToCSV(tests),
    {
      bucket: "result-update",
    }
  );
};

const addDeleteLogs = async (data) => {
  await Storage.put(`${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_logs_${CONFIG.envType}.csv`, jsonToCSV(data), {
    bucket: "result-update",
  });
};

const submitToLab = async (labData, tests) => {
  const [formattedTests, bucket, batch] = formatTests(labData, tests);
  try {
    await Storage.put(`${batch}.csv`, formattedTests, { bucket: bucket });
  } catch (e) {
    console.log("Error uploading to bucket", e);
    return "Error uploading to bucket";
  }
  return `Successfully submitted to ${labData.name}`;
};

const updateTestLabWithResetStatus = async (id, labID) => {
  const res = await DataStore.query(Test, (test) => test.id("eq", id));
  res.forEach(async (c) => {
    await DataStore.save(
      Test.copyOf(c, (updated) => {
        updated.result = null;
        updated.resultDate = null;
        updated.resultDateTime = null;
        updated.StampBy = null;
        updated.StampByName = null;
        updated.status = "Sent";
        updated.labID = labID;
        updated.emailSend = false;
      })
    );
  });
};

const updateQuarantineData = async (id, startDate, endDate) => {
  const res = await DataStore.query(Test, (test) => test.id("eq", id));
  console.log("In Update Quarantine", id, startDate, endDate);
  if (res.length > 0) {
    if (startDate && endDate) {
      await DataStore.save(
        Test.copyOf(res[0], (updated) => {
          updated.quarantinedStart = moment(startDate).toISOString();
          updated.quarantinedEnd = moment(endDate).toISOString();
        })
      );
    } else if (startDate) {
      await DataStore.save(
        Test.copyOf(res[0], (updated) => {
          updated.quarantinedStart = moment(startDate).toISOString();
          updated.quarantinedEnd = null;
        })
      );
    }
  }
};

const getExpiredTest = async () => {
  const items = await DataStore.query(Test, (test) => test.result("eq", "Expired"), {
    sort: (test) => test.createdAt(SortDirection.DESCENDING),
  });
  return items;
};

const getPositiveTests = async (id) => {
  if (!id) return [];

  const test = await DataStore.query(Test, (test) =>
    test.siteID("eq", id).quarantinedStart("ne", null).quarantinedEnd("eq", null)
  );
  return test;
};

const UpdateEndQuarantine = async (users) => {
  for (let i = 0; i < users.length; i++) {
    const res = await DataStore.query(Test, (test) => test.id("eq", users[i]));
    await DataStore.save(
      Test.copyOf(res[0], (updated) => {
        updated.quarantinedEnd = moment().utc().toISOString();
      })
    );
  }
};

const getTodaysTest = async () => {
  const startDate = moment().subtract(1, "days").startOf("day").utc().toISOString();
  const endDate = moment().subtract(1, "days").endOf("day").utc().toISOString();

  const models = await DataStore.query(Test, (test) => test.createdAt("between", [startDate, endDate]));
  console.log("Models", models);
  return models;
};

const deleteUserLinkFromEmployee = async (val) => {
  const users = await DataStore.query(Employee, (e) => e.subID("eq", val));
  users.forEach(async (user) => {
    await DataStore.save(
      Employee.copyOf(user, (updated) => {
        updated.subID = null;
      })
    );
  });
};

const hrCheckIn = async (id) => {
  const path = "/graphqlEmployeeCheckIn";
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: "60956afd-0d86-4268-9c3d-83b80733cfd0",
      version: 15,
      checkIn: moment().toISOString(),
    },
  };

  const apiRes = await API.post(apiName, path, params);
  return apiRes.data;
};
const validateAccessionNo = async (test) => {
  const path = "/validateAccessionNo";
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: test.barcode,
      labID: test.labID,
    },
  };

  const apiRes = await API.post(apiName, path, params);
  return apiRes.isAvailable;
};

const saveFileSendToLab = async (fileName, formattedTests) => {
  try {
    await Storage.put(fileName, jsonToCSV(formattedTests), { bucket: "result-update" });
  } catch (err) {
    console.log("Error", err);
  }
};

const cloneTest = async () => {
  const res = await DataStore.query(Test, (test) => test.sequenceNo("eq", 46145));
  console.log("Res", res);
  if (res.length === 0) return;

  const demographics = res[0].employee_demographics;

  // const employee = await DataStore.save(
  //   new Employee({
  //     first: demographics.firstName,
  //     last: demographics.lastName,
  //     dob: buildAWSDate('10291972'),
  //     id_number: demographics.idNumber,
  //     phone_number: demographics.phoneNumber,
  //     street: demographics.street,
  //     street2: demographics.street2,
  //     city: demographics.city,
  //     state: demographics.state,
  //     zip: demographics.zip,
  //   })
  // );

  await DataStore.save(
    Test.copyOf(res[0], (updated) => {
      updated.employee_demographics = { ...demographics, dob: "10291972", id: "eac9a1c8-a627-430c-86ed-b2a6053bcfc4" };
      updated.employeeID = "eac9a1c8-a627-430c-86ed-b2a6053bcfc4";
    })
  );
};

const getTestListFromPG = async (param) => {
  console.log("[getTestFromPG]");
  let statusNew = "";
  let statusPending = "";
  let test_type = [];
  let dob = "";
  if (param?.status?.toLowerCase() == "new") {
    statusNew = "pending";
    test_type = ["PCR"];
  }
  if (param?.status?.toLowerCase() == "pending") {
    statusPending = "pending";
    test_type = ["Lucira", "Molecular", "Antigen", "Rapid Flu", "Rapid PCR"];
  }
  if (param?.dob) {
    dob = param?.dob?.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
  }

  const path = "/getTestFromPG";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      params: dob
        ? { ...param, dob }
        : statusNew
        ? { ...param, status: statusNew, test_type }
        : statusPending
        ? { ...param, test_type }
        : param,
    },
  };

  console.log("updated params::", params);

  const apiRes = await API.post(apiName, path, params);

  return apiRes.data;
};

const getSummaryFromPG = async (param) => {
  console.log("[getSummary]");
  const path = "/getSummary";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      params: param,
    },
  };

  const apiRes = await API.post(apiName, path, params);

  return apiRes.data;
};

const getTestBySequenceNoByAPI = async (param) => {
  console.log("[getSummary]");
  const path = "/getTestBySequenceNo";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: parseInt(param),
    },
  };

  const apiRes = await API.post(apiName, path, params);

  return apiRes.data;
};

const getAppVersion = async (param) => {
  console.log("[getAppVersion]");
  const path = "/appVersion";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      name: "ios",
    },
  };
  const apiRes = await API.post(apiName, path, params);
  return apiRes.data;
};

const updateTestStatus = async () => {
  const res = await DataStore.query(Test, (test) => test.id("eq", "652e3558-a5d5-4f14-a75d-4c6e81491047"));
  console.log("res", res);
  if (res.length === 0) return;
  return;
  res.forEach(async (c) => {
    await DataStore.save(
      Test.copyOf(c, (updated) => {
        updated.result = null;
        updated.status = "Sent";
        updated.resultDate = null;
        updated.resultDateTime = null;
      })
    );
  });
};

const getTestBySequenceNo = async (num) => {
  const res = await DataStore.query(Test, (test) => test.sequenceNo("eq", parseInt(num)));
  return res;
};

const getDeletedTestList = async () => {
  const path = "/archivedTests";

  const apiRes = await callAmplifyAPI(path, { params: { clientID: CONFIG.clientID } });

  return apiRes?.rows || [];
};
const unResultTests = async (tests) => {
  await Storage.put(`${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_unresult_${CONFIG.envType}.csv`, jsonToCSV(tests), {
    bucket: "result-update",
  });
};

export default {
  getEmployeeGraphQl,
  deleteEmployeeGQL,
  getTodaysTest,
  getClients,
  getResultClients,
  getSitesByClient,
  getLabs,
  getLabsWithTests,
  createEmployee,
  requestTestKit,
  preRegisterEmployee,
  getPreRegisterByPhone,
  preRegisterNewEmployee,
  preRegisterClientShows,
  deletePreRegistration,
  preRegisterShowData,
  getEmployeeTestCount,
  getEmployeeLastShow,
  createTest,
  getTests,
  getPendingTests,
  sendToLab,
  getCurrentUser,
  getAllUsers,
  signIn,
  getCurrentLabUserTests,
  getAllCurrentLabUserTests,
  getCurrentLabUserProcessedTests,
  getTestGraphQL,
  getPendingReleaseTests,
  findTestByID,
  findTestByBarcode,
  findTestsByClient,
  releaseTest,
  sendEmailToPendingRelease,
  unResultTests,
  fetchUsersInGroup,
  fetchAllUsersInGroup,
  fetchAllUsers,
  changeUserRole,
  addUserToGroup,
  confirmUserSignUp,
  updateUserInfo,
  updateUserSecondRole,
  deleteUser,
  updateUserPassword,
  sendSMSNotification,
  deleteEmployee,
  createLab,
  deleteLab,
  updateClientsHippa,
  updateClient,
  updateSite,
  updateSiteArchive,
  updateLab,
  updateLabBarCodes,
  getLabBarCodes,
  updateLabLogo,
  updateBarCodeAlert,
  resetDefaultAntigenLab,
  resetDefaultMolecularLab,
  resetDefaultOtherLab,
  getClientById,
  createSite,
  getSites,
  getSitesWithTests,
  sendSmsToCrews,
  getShows,
  createClient,
  deleteClient,
  deleteSite,
  getSitesByUser,
  getUserSiteData,
  findSiteByID,
  addUserToSite,
  removeUserToSite,
  addUserToLab,
  removeUserToLab,
  getUnprocessedTests,
  getUnSubmittedTest,
  getLabByID,
  submitUnprocessedTests,
  reSubmitUnprocessedTests,
  getTestTypes,
  updateTestTypes,
  updateTestType,
  findHippaConsent,
  getCompletedTests,
  getTotalAdminTests,
  deleteWrongTest,
  sendEmailToCrew,
  sendInvitationEmail,
  updateCrewInfo,
  updateCompleteCrewInfo,
  updateEmailStatus,
  updateTestResult,
  getExpiredTest,
  updateQuarantineData,
  getTestAutoNumber,
  getOrderAutoNumber,
  getLabTestAutoNumber,
  getTestListFromApi,
  getTestFromApi,
  getPreRegisterData,
  findExternalTestByID,
  getExternalTest,
  fetchAllCrewMembers,
  fetchAllEmployees,
  getPositiveTests,
  UpdateEndQuarantine,
  getUserSites,
  getEmployeeByIdGraphQl,
  getEmployeeByID,
  updateEmployeeGraphQL,
  getAllEmployees,
  getTestLogs,
  addTestLogs,
  updateTestLab,
  updateTestClientLab,
  getAppTestLogs,
  addTestAppLogs,
  submitToLab,
  getAdminTestList,
  getShowPreRegistration,
  updateTestFalsePositive,
  addDeleteLogs,
  formatTests,
  deleteUserLinkFromEmployee,
  hrCheckIn,
  saveFileSendToLab,
  getUserPersonalisation,
  saveUserPersonalisation,
  getSettingForApp,
  cloneTest,
  getTestListFromPG,
  getSummaryFromPG,
  updateTestStatus,
  getTestBySequenceNo,
  updateStatusByLab,
  getDeletedTestList,
  getTestBySequenceNoByAPI,
  createNewEmployee,
  createDelivery,
  updateWhiteLabelTestResult,
  validateAccessionNo,
  createOrderAPI,
  updateOrderAPI,
  updateRejcetedOrder,
  updateOrderStatus,
};
