import API from "api";
import Filter from "components/Filter";
import Icon from "components/Icon";
import { AppContext } from "context/app-context";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import TestTypeModal from "views/TestTypeSetting/TestTypeModal";
import Loader from "../../components/Loader/Loader";
import { calculateTdWidth, loadReqFormTest, formatNumber } from "../../utils";

import TestDetailsModal from "./TestDetailsModal";
import { createTestsDetails } from "api";

import { updateTestsDetails } from "api";
import MainTable from "components/Table/MainTable";
import { TEST_MANAGEMENT_PERSONALIZATION } from "constants/personalization";
import { TABLE_QUICK_TOOLS } from "constant";
import { TEST_ASSAY_LOGS_SLUG } from "constant";
import { CONFIG, TESTS_MANAGEMENT } from "constant";

import { userGivenPermission } from "store/features/authentication/authenticationSelectors";
import { useDispatch, useSelector } from "react-redux";
import { updateTestNames } from "store/features/general/generalSlice";
import TestResultSetting from "./TestResultSetting";
import LabBarCodeAlertSetting from "./LabBarCodeAlertSetting";
import { getTestAssociatePanel } from "store/features/testAssay/testAssaySlice";
import Status from "components/Status";
import { ORDER_STATUS } from "constant";
import { showConfirmationModal } from "store/features/general/generalAction";

const TestTypeSetting = () => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const appContext = useContext(AppContext);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [testSources, setTestSources] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const permission = useSelector(userGivenPermission);
  const [filterListData, setFilterList] = useState([]);

  const [openDetailModal, setOpenDetailModal] = useState(false);

  const [testPanelData, setTestPanelData] = useState(null);

  const searchTerms = ["Name"];

  const dispatch = useDispatch();

  const getTestsPanesl = async () => {
    dispatch(getTestAssociatePanel());
  };

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => handleResize(), [mainWidth]);

  const tdWidth = calculateTdWidth(width - 50, 5);

  const [loading, setLoading] = useState();
  const [list, setList] = useState([]);

  const [editObj, setEditObj] = useState(null);

  const getList = async (filtersData) => {
    const apiData = await API.getTestTypes(filtersData);
    setList(apiData);
    setFilterList(apiData);
  };

  useEffect(() => {
    if (filter.name) {
      const filteredData = list.filter((item) => item.name.toLowerCase().includes(filter.name.toLowerCase()));
      setFilterList(filteredData);
    } else {
      // Optionally, reset the list if the filter is cleared
      getList(); // Assuming getList fetches and sets the unfiltered list
    }
  }, [filter.name]);

  useEffect(() => {
    getSourceType();
    getList();
    getTestsPanesl();
  }, []);

  const getSourceType = async () => {
    const sources = await API.getTestSources();
    setTestSources(sources);
  };

  const handleEdit = (item) => {
    setEditObj(item);
  };

  const handleSave = async (item) => {
    const obj = { ...item };
    setEditObj(null);
    setLoading(true);
    try {
      let res;
      let message = "The test was created successfully";
      let logObj = {
        updatedBy: appContext.user?.sub,
        updatedByName: appContext.user?.name,
        userID: appContext.user?.sub,
        userName: appContext.user?.name,
      };

      if (item.isNew) {
        res = await API.addTestTypes(obj);

        logObj = {
          ...logObj,
          testID: res.id,
          slug: "Create",
          newValue: TEST_ASSAY_LOGS_SLUG.TEST_CREATED,
        };
      } else {
        res = await API.updateTestTypes(obj);

        logObj = {
          ...logObj,
          testID: res.id,
          slug: "Update",
          newValue: TEST_ASSAY_LOGS_SLUG.TEST_UPDATED,
          changeObject: res,
        };
        message = "Test updated successfully";
      }

      await API.addLogs(logObj);

      const updatedList = await API.getTestTypes();

      const formattedTests = await loadReqFormTest({ clientID: CONFIG.clientID, tests: updatedList });

      dispatch(updateTestNames({ tests: formattedTests }));

      setLoading(false);
      setList(updatedList);
      setFilterList(updatedList);
      appContext.showSuccessMessage(message);
    } catch (error) {
      setEditObj(obj);
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const handleClose = async (newSource) => {
    setShowSourceModal(null);

    if (newSource) {
      appContext.showSuccessMessage("Source added successfully");
      setTestSources([...testSources, newSource]);
    }
  };

  const handleCloseTestTypeModal = async () => {
    setEditObj(null);
    setList(await API.getTestTypes());
  };

  const handleSaveTestDetail = async (testDetailsData) => {
    const { isNew } = testDetailsData;
    const action = isNew ? "create" : "update";

    try {
      // Not save the Pathegon on base table because it is being customized for this test
      // const result = await (isNew ? createTestsDetails : updateTestsDetails)(testDetailsData);

      const updatedPathegons = editObj?.targetRanges?.map((item) =>
        item.id === testDetailsData.id ? testDetailsData : item
      );

      setEditObj((prevEditObj) => ({
        ...prevEditObj,
        targetRanges: prevEditObj?.targetRanges?.map((item) =>
          item.id === testDetailsData.id ? testDetailsData : item
        ),
      }));
      if (!editObj.isNew) {
        try {
          await API.updateTestPathegon({ id: editObj.id, targetRanges: updatedPathegons });
        } catch (error) {
          console.error("Failed to update Test Pathegon:", error);
        }
      }

      setOpenDetailModal(false);
      setTestPanelData(null);
    } catch (error) {
      console.error("Error", error);
      appContext.showErrorMessage(`Unable to ${action} panel test.`);
    }
  };

  const handleSetTestPanelData = (data) => {
    if (data?.id && data.currentRanges) {
      setEditObj((prevEditObj) => {
        const exists = [...data.currentRanges];
        return {
          ...prevEditObj,
          typeOfTest: data.typeOfTest,
          targetRanges: exists,
        };
      });
    }
    const copyObject = { ...data };
    delete copyObject.currentRanges;
    setTestPanelData(copyObject);
  };

  const getIctLabelAndTitle = (ictCodes) => {
    const codes = ictCodes?.map((item) => item.code) || [];

    const ictLabel = !codes || codes.length === 0 ? "" : codes.length === 1 ? codes[0] : "Multi";

    const title = !codes || codes.length === 0 ? "" : codes.length === 1 ? codes[0] : codes.join(", ");

    return { ictLabel, title };
  };

  const getSources = (sources) => {
    let sourceTitle;
    let source;
    if (Array.isArray(sources)) {
      if (sources.length === 1) {
        sourceTitle = sources[0]?.label;
        source = sources[0]?.label;
      } else {
        sourceTitle = sources.map((item) => item.label).join(", ");
        source = "Multi";
      }
    } else {
      sourceTitle = "";
      source = sources?.label;
    }
    return { sourceTitle, source };
  };

  const customRenderTD = (item, row) => {
    const { ictLabel, title } = getIctLabelAndTitle(row?.ictCodes);
    const { sourceTitle, source } = getSources(row?.sources);
    if (item.itemKey === "icdCodes") {
      return (
        <td
          key={item.id}
          className="ellipsis"
          style={{
            textAlign: item.textAlign,
            textOverflow: item.textOverflow,
            cursor: "pointer",
          }}
          title={title}
        >
          {ictLabel}
        </td>
      );
    }

    if (item.itemKey === "isARKStone") {
      const testStatusColor = row[item.itemKey] ? "green" : "maroon";

      return (
        <div className="star-icon-wrapper cursor-pointer text-center mt-3">
          <Status type="circle" size="md" color={testStatusColor} crossIcon={testStatusColor} />
        </div>
      );
    }

    if (item.itemKey === "source") {
      return (
        <td
          key={item.id}
          className="ellipsis"
          style={{
            textAlign: item.textAlign,
            textOverflow: item.textOverflow,
            cursor: "pointer",
          }}
          title={sourceTitle}
        >
          {source}
        </td>
      );
    }
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.edit:
        handleEdit(row);
        break;
      default:
        break;
    }
  };

  const handleArchive = async (item) => {
    try {
      setLoading(true);
      const res = await API.archiveTestType(item);

      const logObj = {
        updatedBy: appContext.user?.sub,
        updatedByName: appContext.user?.name,
        userID: appContext.user?.sub,
        userName: appContext.user?.name,
        testID: res.id,
        slug: "Archived",
        newValue: TEST_ASSAY_LOGS_SLUG.TEST_ARCHIVED,
        changeObject: res,
      };

      await API.addLogs(logObj);

      const updatedList = await API.getTestTypes();

      const formattedTests = await loadReqFormTest({ clientID: CONFIG.clientID, tests: updatedList });

      setLoading(false);
      setList(updatedList);
      setFilterList(updatedList);
      appContext.showSuccessMessage("Test Archived successfully");

      dispatch(updateTestNames({ tests: formattedTests }));
    } catch (ex) {
      console.log("Ex", ex);
      setLoading(false);
    }
  };

  return (
    <>
      {!loading ? (
        <div className="shows-screen-parent">
          <Row className="">
            <Col md="12">
              <Card className="strpied-tabled-with-hover bg-transparent border-0">
                <Card.Header className="shows-screen-wrapper">
                  <Row className="pb-4">
                    <Col md-="12">
                      <Card.Title
                        as="h4"
                        style={{
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        Test Setting ({formatNumber(filterListData.length)})
                      </Card.Title>
                      <div className="shows-filter-wrapper">
                        <div className="shows-filter-inner">
                          <Icon
                            handleClick={() => setShowFilter(!showFilter)}
                            title={"Filter"}
                            label={"Filter"}
                            iconType={"filter"}
                          />

                          {permission[TESTS_MANAGEMENT]?.write && (
                            <Icon
                              handleClick={() =>
                                setEditObj({
                                  isNew: true,
                                  typeOfTest: "Qualitative",
                                  targetRanges: [],
                                })
                              }
                              title={"Create Test Type"}
                              label={"Create New"}
                              iconType={"createIcon"}
                            />
                          )}
                        </div>
                      </div>
                      <div>
                        {showFilter && <Filter filterTerms={searchTerms} setFilter={setFilter} filter={filter} />}
                      </div>
                    </Col>
                  </Row>
                </Card.Header>

                <Card.Body className="table-full-width px-0 desktop-noScroll pt-0 mt-0">
                  <div className="table-responsive pendingReleaseTable">
                    <MainTable
                      columns={TEST_MANAGEMENT_PERSONALIZATION}
                      rows={filterListData}
                      // flipSort={flipSort}
                      // sortBy={sortBy}
                      // sortDescending={sortDescending}
                      // selectedRows={checkboxes}
                      tools={[TABLE_QUICK_TOOLS.edit]}
                      // personalisationKey={"orderpersonalize"}
                      // individualRowCssClass={(row) =>
                      //   row.employee_demographics?.stat && !row.result ? "trDataWrapper isStatRow" : "trDataWrapper"
                      // }
                      dropDownOptions={[
                        { value: "Logs", title: "Audit Trail" },
                        { value: "Archive", title: "Archive" },
                      ]}
                      handleDropDownClick={(type, row) => {
                        if (type === "Audit Trail") {
                          appContext.showLogs({ ...row, title: "Test Management Logs", ordID: row.id });
                        } else {
                          let deletedItems = { ...row };
                          deletedItems = { ...deletedItems, isActive: false, isNew: false };
                          const confirmationModalData = {
                            showModal: true,
                            onConfirm: () => handleArchive(deletedItems),
                            title: "Archive Test ",
                            message: "Are you sure, you want to archive that Test?",
                          };
                          dispatch(showConfirmationModal(confirmationModalData));
                        }
                      }}
                      customColumnCellRenderer={customRenderTD}
                      handleCellClick={handleCellClick}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
            {/* {CONFIG.isWhiteLabel ? <TestResultSetting /> : <LabBarCodeAlertSetting />} */}
          </Row>
        </div>
      ) : (
        <Loader />
      )}
      {editObj && (
        <TestTypeModal
          item={editObj}
          handleSave={handleSave}
          handleClose={handleCloseTestTypeModal}
          testSources={testSources}
          openDetailModal={() => setOpenDetailModal(true)}
          mainTests={list}
          setTestPanelData={(data) => handleSetTestPanelData(data)}
        />
      )}
      {openDetailModal && (
        <TestDetailsModal
          data={{ ...testPanelData, callFrom: "test" }}
          handleSaveTestDetail={handleSaveTestDetail}
          handleClose={() => {
            setOpenDetailModal(false);
            setTestPanelData(null);
          }}
        />
      )}
    </>
  );
};

export default TestTypeSetting;
