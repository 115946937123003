import React, { useCallback, useEffect, useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import styles from "./InputStyle.module.css";
import { debounce } from "debounce";
import { ViewportList } from "react-viewport-list";
import api from "api";
import { convertToUpper } from "utils";

const InputCPTCodeDropDown = (props) => {
  const {
    label,
    type,
    placeholder,
    handleChange,
    labelStyle,
    inputStyle,
    value,
    maxlength,
    groupWrapper,
    optionList,
    readOnly,
    selectedDiagnosis,
    searchtype,
    required,
    showLabelOnTop,
    showLabel,
    showInput = true,
    showCross = true,
    dataIds = [],
  } = props;

  const [input, setInput] = useState(value || "");

  const [description, setDescription] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);
  const scrollableContainerRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [testResponse, setTestResponse] = useState(null);

  const inputRef = useRef(null);
  const handleTypeDelay = useCallback(
    debounce((data, selectedDiagnosis) => {
      handleChangeInputText(data, selectedDiagnosis);
    }, 200),
    []
  );

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setSelectedOptionIndex((prevIndex) => (prevIndex < filteredList.length - 1 ? prevIndex + 1 : prevIndex));
      scrollToOption(selectedOptionIndex + 1);
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setSelectedOptionIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
      scrollToOption(selectedOptionIndex - 1);
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (selectedOptionIndex !== -1) {
        const selectedOption = filteredList[selectedOptionIndex];
        if (selectedOption) {
          setInput(selectedOption.code);
          handleChange({
            ...selectedOption,
            value: selectedOption.code,
            label: selectedOption.description,
          });
          setFilteredList([]);
        }
      }
    }
  };

  useEffect(async () => {
    if (value && value.trim()) {
      setInput(value);
    } else {
      setInput("");
      setDescription("");
    }
    setSelectedOptionIndex(-1);
  }, [value]);

  useEffect(() => {
    if (!input || !input.trim()) {
      setFilteredList([]);
      setSelectedOptionIndex(-1);
    }
  }, [input]);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (isDropdownOpen && !e.target.closest(".form-group-wrapper")) {
        // Close the dropdown when clicking outside of it
        setIsDropdownOpen(false);
      }
    };

    // Add event listener to detect clicks outside of the dropdown
    document.addEventListener("click", handleOutsideClick);

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isDropdownOpen]);

  const scrollToOption = (index) => {
    if (scrollableContainerRef.current) {
      const listItem = scrollableContainerRef.current.querySelector(`.listOption-${index}`);
      if (listItem) {
        const containerRect = scrollableContainerRef.current.getBoundingClientRect();
        const listItemRect = listItem.getBoundingClientRect();
        if (listItemRect.bottom > containerRect.bottom) {
          // Scroll down to make the selected item visible
          scrollableContainerRef.current.scrollTop += listItemRect.bottom - containerRect.bottom;
        } else if (listItemRect.top < containerRect.top) {
          // Scroll up to make the selected item visible
          scrollableContainerRef.current.scrollTop -= containerRect.top - listItemRect.top;
        }
      }
    }
  };

  const getList = async () => {
    const apiData = await api.getTestTypes();
    setTestTypesList(apiData);
  };
  const getPanelList = async () => {
    const apiRes = await api.getPanels();

    setPanelList(apiRes);
  };

  const handleChangeInputText = async (data, selectedDiagnosis) => {
    if (!data || !data.trim()) {
      setFilteredList([]);
      return;
    }

    let response = {};
    if (searchtype === "cpt") {
      response = await api.searchCptCode({ search: data });
    }
    if (searchtype === "test") {
      const [testTypes, panels] = await Promise.all([api.getTestTypes(), api.getPanels()]);

      const targetRangeIds = panels.flatMap((panel) => panel.targetRanges.map((range) => range.id));
     
      const filteredTestItem = testTypes
        .filter(
          (item) =>
            (item.name.toLowerCase().includes(data.toLowerCase()) || item.code === data) &&
            !dataIds.includes(item.id) &&
            !targetRangeIds.includes(item.id)
        )
        .map((item) => ({
          ...item,
          description: item.name,
          label: item.name,
        }));
      const filteredPanelItem = panels
        .filter((item) => item.name.toLowerCase().includes(data.toLowerCase()) && !dataIds.includes(item.id))
        .map((item) => ({
          ...item,
          description: item.name,
          label: item.name,
        }));

      
      const mergedTestArr = [...filteredTestItem, ...filteredPanelItem];

      response = {
        cpt: [],
        panel: filteredPanelItem,
        tests: filteredTestItem,
        rows: mergedTestArr,
      };

      setTestResponse(response);

      response["rows"] = mergedTestArr;

      // setTestResponse(resObj);

      // response = await api.getCptWithTests({ search: data });
      // console.log("ResponDOwn", response);
      // setTestResponse(response);

      // const resRows = [
      //   ...response?.panel.map((item) => {
      //     return { ...item, description: item.name };
      //   }),
      //   ...response?.tests.map((item) => {
      //     return { ...item, description: item.name };
      //   }),
      // ];
      // console.log("🚀 ~ handleChangeInputText ~ resRows:", resRows);

      // response["rows"] = resRows;
    }

    if (searchtype === "ict") {
      response = await api.searchICTCode({ search: data });
      if (selectedDiagnosis.length > 0 && response && response.rows.length > 0) {
        response = response.rows.filter((f) => !selectedDiagnosis.includes(f.code));
      }
    }

    setFilteredList(response?.rows);
  };

  const optionRow = (option, index) => {
    const isSelected = index === selectedOptionIndex;
    // console.log("option", option);
    let changeObj = { ...option, value: option.code, label: option.description };

    if (searchtype === "test" && changeObj.hasOwnProperty("code") && changeObj.code !== null) {
      changeObj = { changeObj, testResponse };
    }
    return (
      <li
        key={index}
        tabIndex={0}
        className={`listOption listOption-${index} ${isSelected ? "selected" : ""}`}
        onClick={() => {
          setInput(showInput ? option.code : "");
          handleChange(changeObj);
          setFilteredList([]);
        }}
        title={option.description}
      >
        <span className={`fw-bold optListContent ${isSelected ? "selected" : ""}`}>{option.internalCode}</span>
        <span className={`fw-light optListContent ${isSelected ? "selected" : ""}`}>{option.description}</span>
      </li>
    );
  };

  return (
    <Form.Group className={`${groupWrapper ? groupWrapper : "form-group-wrapper"}`}>
      {!showLabelOnTop && showLabel && label ? (
        <>
          <Form.Label className={`${styles.inputLabel} ${labelStyle}`}>{`${label}`}</Form.Label>
          {required && <span style={{ color: "red" }}>*</span>}
        </>
      ) : (
        ""
      )}
      <div className="position-relative w-100">
        {showLabelOnTop && label && showLabel ? (
          <>
            <Form.Label className={`${styles.inputLabel} ${labelStyle}`}>{`${label}`}</Form.Label>
            {required && <span style={{ color: "red" }}>*</span>}
          </>
        ) : (
          ""
        )}
        <Form.Control
          ref={inputRef}
          type={type}
          maxLength={maxlength}
          onKeyDown={handleKeyDown}
          onClick={() => {
            setIsDropdownOpen(true);
          }}
          title={description}
          value={input}
          readOnly={readOnly}
          placeholder={placeholder}
          className={`${styles.inputStyling} ${inputStyle}`}
          onChange={(e) => {
            const val = convertToUpper(e.target.value);
            setIsDropdownOpen(true);
            if (val) {
              setInput(val);
              setDescription("");
              handleTypeDelay(val, selectedDiagnosis);
            } else {
              handleTypeDelay("");
              handleChange("");
              setInput(val);
            }
          }}
        />
        {value && !readOnly && showCross && (
          <i
            className={`fas fa-times ${showLabel ? "cancelSearchInputWithLabel" : "cancelSearchInput"}`}
            onClick={() => {
              handleTypeDelay("");
              handleChange("");
              setInput("");
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }}
          />
        )}
        {filteredList && !readOnly && isDropdownOpen && filteredList.length > 0 && (
          <ul
            className="optionListWrapper"
            onBlur={() => {
              setInput("");
              setFilteredList([]);
              setIsDropdownOpen(false);
            }}
            onKeyDown={handleKeyDown} // Handle keyboard events
            tabIndex={0} // Make the list focusable
            ref={scrollableContainerRef}
          >
            <ViewportList items={filteredList} overflowAnchor="auto" withCache={true}>
              {(item, index) => optionRow(item, index)}
            </ViewportList>
          </ul>
        )}
      </div>
    </Form.Group>
  );
};

export default InputCPTCodeDropDown;
