import React, { useContext, useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import TestTypeSelector from "./TestTypeSelector";
import MainTable from "components/Table/MainTable";
import { CREATE_TEST_PANEL_PERSONALIZE } from "constants/personalization";
import api from "api";
import { AppContext } from "context/app-context";
import Loader from "components/Loader/Loader";
import { TABLE_QUICK_TOOLS } from "constant";
import { toTitleCase, formattedString, getUniqueSources } from "utils";
import { TEST_PANEL_TYPES } from "constant";

const CreatePanelModal = ({ item, handleClose, testTypesList, loadPanel, testPanelList }) => {
  const [newItem, setNewItem] = useState({ ...item });

  const [panelsList, setPanelsList] = useState([]);

  const [loading, setLoading] = useState(false);

  const appContext = useContext(AppContext);

  const handleTestSelect = (test) => {
    setPanelsList((previousItems) => {
      const updatedList = new Map(previousItems.map((item) => [item.id, item]));
      updatedList.set(test.id, test);
      return Array.from(updatedList.values());
    });
  };

  useEffect(() => {
    if (item && item.targetRanges) {
      setPanelsList(item.targetRanges);
    }
  }, [item]);

  const TYPE_TO_SHOW = {
    panel: "Panel",
    qualitativeWithPathogen: "Qualitative With Pathogen",
    qualitativeWithOutPathogen: "Qualitative Without Pathogen",
    quantitativeWithOutPathogen: "Quantitative Without Pathogen",
    quantitativeWithPathogen: "Quantitative With Pathogen",
  };

  const customRenderTD = (item, row, index) => {
    if (item.itemKey === "type") {
      return <td key={`type_${item.id}`}>{TYPE_TO_SHOW[row.type]}</td>;
    }
    if (item.itemKey === "name") {
      return <td key={`name_${item.id}`}>{formattedString(row?.name) || ""}</td>;
    }
  };

  const preparedSaveObject = () => {
    let combineSource = [];

    const targetRanges = panelsList.map(({ name, id, type, sources }) => {
      return {
        name,
        id,
        type: type,
      };
    });

    return {
      ...item,
      sources: getUniqueSources(panelsList),
      isActive: true,
      name: newItem?.name || "",
      setting: null,
      targetRanges: targetRanges,
      type: TEST_PANEL_TYPES.PANEL,
      code: newItem.code,
    };
  };

  const handleCreateOrUpdatePanel = async () => {
    if (!newItem.name) {
      appContext.showErrorMessage("Enter valid panel name");
      return;
    }
    if (!newItem.code) {
      appContext.showErrorMessage("Enter  valid panel code");
      return;
    }
    if (panelsList.length === 0) {
      appContext.showErrorMessage("Add tests in panels");
      return;
    }
    setLoading(true);

    const objToSave = preparedSaveObject();

    if (item.isNew) {
      const isAlreadyExist = testPanelList.some((item) => item.name === objToSave.name);
      if (isAlreadyExist) {
        appContext.showErrorMessage("Panel with this name alredy exist.");
        return;
      }
    }

    try {
      if (objToSave.isNew) {
        await api.addTestTypes(objToSave);
        appContext.showSuccessMessage("Panel added.");
      } else {
        await api.updateTestTypes(objToSave);
        appContext.showSuccessMessage("Panel updated.");
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      setLoading(false);
      loadPanel();
      handleClose();
    }
  };

  const handleDelete = (row) => {
    setPanelsList((prevPanelsList) => prevPanelsList.filter((panel) => panel.id !== row.id));
  };

  return (
    <Modal
      show
      // style={{ transform: "translate(0, -30%)" }}
      animation={true}
      onHide={() => handleClose()}
      centered
      size={"xl"}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 h4" id="contained-modal-title-vcenter">
          Panel Management
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0, lineHeight: 0 }}></Modal.Body>
      {loading && <Loader />}
      <div style={{ display: "flex", gap: "16px", paddingLeft: "20px", paddingRight: "20px" }}>
        <div style={{ flex: 1 }}>
          <p className="modalLineHeaders">
            Panel Name
            <span style={{ color: "red" }}>*</span>
          </p>
          <input
            className="modalInput"
            value={newItem?.name || ""}
            placeholder="Enter Panel Name "
            style={{ width: "100%" }}
            onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
            tabIndex={1}
          />
        </div>
        <div style={{ flex: 1 }}>
          {" "}
          <p className="modalLineHeaders">
            Internal Panel code
            <span style={{ color: "red" }}>*</span>
          </p>
          <input
            className="modalInput"
            value={newItem.code}
            placeholder="Internal Panel code"
            style={{ width: "100%" }}
            onChange={(e) => {
              const value = e.target.value;
              setNewItem({ ...newItem, code: value });
            }}
            tabIndex={2}
          />
        </div>
      </div>
      <div style={{ marginTop: "20px", paddingLeft: "20px", paddingRight: "20px", width: "100%" }}>
        <TestTypeSelector
          label="Select Test / Panel"
          onChange={handleTestSelect}
          testTypeList={testTypesList}
          testPanelList={testPanelList}
          selectedPanel={item?.isNew ? null : item}
          value={""}
          required={true}
        />
      </div>
      <div style={{ padding: "20px" }}>
        {panelsList.length > 0 && (
          <div style={{ background: "#f2f0f0", paddingLeft: "4px", paddingRight: "4px", marginTop: "10px" }}>
            <MainTable
              columns={CREATE_TEST_PANEL_PERSONALIZE}
              rows={panelsList}
              customColumnCellRenderer={customRenderTD}
              widthToSkip={155}
              tools={[TABLE_QUICK_TOOLS.delete]}
              handleCellClick={(key, row) => {
                if (key === TABLE_QUICK_TOOLS.delete) {
                  handleDelete(row);
                }
              }}
            />
          </div>
        )}
      </div>

      <Modal.Footer>
        <Button variant="secondary" className="modalButtons" onClick={() => handleClose()}>
          Close
        </Button>

        <Button variant="primary" className="modalButtons" onClick={handleCreateOrUpdatePanel}>
          {item?.isNew ? "Create" : "Update"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreatePanelModal;
