import MultipleFileInput from "components/Input/MultipleFileInput";
import ErrorMessage from "components/Message/ErrorMessage";
import { CONFIG, stringConstant } from "constant";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { removeWhitespaceAndParentheses } from "utils";
import { v4 as uuidv4 } from "uuid";
import api from "api";

const LoadOrdersModal = (props) => {
  const { handleClose, errorMessage, setOrderFiles, onSaveFile, sites } = props;

  const [error, setError] = useState("");
  const [selectedFileNames, setSelectedFileNames] = useState([]);
  const [selectedSite, setSelectedSite] = useState(null);
  const [testDate, setTestDate] = useState("");
  const [testOptions, setTestOptions] = useState();
  const [files, setFiles] = useState([]);

  useEffect(()=>{
    loadTestOptions();
  },[]);  

  useEffect(() => {
    setError(errorMessage);
  }, [errorMessage]);

  const loadTestOptions = async () => {
    const options = await api.getTestTypesOptions();
    setTestOptions(options);
  };

  const getSiteOptions = useMemo(() => {
    if (!CONFIG.clientID) return [];

    return sites
      .filter((s) => s.clientID === CONFIG.clientID)
      .map((l) => {
        return { value: l.id, label: l.name };
      });
  }, [sites]);

  useEffect(() => {
    if (getSiteOptions.length === 1) {
      setSelectedSite(getSiteOptions[0]);
    }
  }, [getSiteOptions]);

  const isValidFiles = () => {
    if (files.length === 0) {
      setError("Please Select at least one file");
      return;
    }
    if (!selectedSite) {
      setError("Client is Required");
      return;
    }
    if (!testDate) {
      setError("Test Date is Required");
      return;
    }
    return true;
  };
  const handleSave = async () => {
    if (!isValidFiles()) return;
    let ttl = files.length;

    const objectsArray = [];

    for (let i = 0; i < ttl; i++) {
      const obj = { status: "pending", id: uuidv4(), floorID: removeWhitespaceAndParentheses(files[i].name) };
      objectsArray.push(obj);
    }

    // select PCR Type for Now Later give test type selection
    const pcrTest = testOptions.find((t)=> t.label.toLowerCase() === 'pcr');
  
    const orderFiles = {
      files,
      uploadStatus: objectsArray,
      testDate: moment(`${testDate} ${moment().format("HH:mm")}`).toISOString(),
      selectedSite,
      selectedFileNames,
      testTypeID: pcrTest?.id
    };
    
    setOrderFiles(orderFiles);
    onSaveFile(orderFiles);
  };

  return (
    <Modal show animation={true} onHide={() => handleClose()} centered size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Upload Files
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="form-group-wrapper d-block">
          <div className="col-sm-12 testAdmin createClientsWrapper">
            <div className="crew-profile-info-cl-2 ">
              {getSiteOptions.length > 1 && (
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">{stringConstant.site} </label>
                  <Select
                    className="w-100 siteSelector"
                    options={getSiteOptions}
                    blurInputOnSelect={true}
                    defaultValue={null}
                    menuPlacement="auto"
                    placeholder={`Select ${stringConstant.site}`}
                    value={selectedSite ? selectedSite : null}
                    onChange={(e) => setSelectedSite(e)}
                  />
                </div>
              )}
              <div className="form-group-wrapper dateWrapper">
                <label className="modalLineHeaders">Test Date: </label>

                <input
                  className="modalInput"
                  type="date"
                  value={testDate}
                  style={{ width: "100%", marginBottom: "10px" }}
                  onChange={(e) => setTestDate(e.target.value)}
                  min={moment().format("YYYY-MM-DD")}
                />
              </div>
            </div>

            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Document Files: </label>
              <MultipleFileInput
                files={files}
                setFiles={setFiles}
                setSelectedFileNames={setSelectedFileNames}
                selectedFileNames={selectedFileNames}
                acceptFiles={".pdf, .csv"}
              />
            </div>
          </div>
          <div style={{ marginTop: "10px" }}>
            {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button className="modalButtons" variant="primary" onClick={() => handleClose()}>
          Close
        </Button>
        <Button className="modalButtons" variant="secondary" onClick={() => handleSave()}>
          Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LoadOrdersModal;
