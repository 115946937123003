import React, { useState, useContext, useRef } from "react";
import { Button, Card, Table, Row, Col, DropdownButton, Dropdown, Modal } from "react-bootstrap";
import { useEffect } from "react";
import api from "api";
import { Auth } from "aws-amplify";
import { Bounce } from "react-activity";
import "react-activity/dist/Bounce.css";
import { AppContext } from "context/app-context";
import Filter from "components/Filter";
import TimeFilter from "components/TimeFilter";
import HeaderItem from "components/Table/HeaderItem";
import WhiteLabelUserModal from "components/Users/WhiteLabelUserModal";
import UserPasswordModal from "components/Users/UserPasswordModal";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import TDData from "components/Table/TDData";
import {
  EmptyUserObj,
  USER_ROLE_TYPE,
  PAGE_LIMIT,
  HEALTH_AND_SAFTEY,
  newRoleOptions,
  SYSTEM_USERS_VALUE,
} from "constant";
import { formatPhoneNumber, setPhoneNo, calculateTdWidth, sortingFilterInLC } from "utils";
import { userGivenPermission } from "store/features/authentication/authenticationSelectors";

import Select from "react-select";
import "tableStyling.css";
import Loader from "components/Loader/Loader";
import { createBreakpoint } from "react-use";
import { CONFIG } from "constant";
import Icon from "components/Icon";
import editIcon from "assets/img/edit-icon.png";
import { toTitleCase } from "utils";
import RenderTd from "components/Personalization/RenderTd";
import { useSelector } from "react-redux";
import { DEFAULT_USER_PERSONALIZATION } from "constant";

const UsersViewWhiteLabel = ({ isEditPermission, isDeletePermission }) => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => handleResize(), [mainWidth]);
  const permission = useSelector(userGivenPermission);

  const tdWidth = calculateTdWidth(width - 100, 7);

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [openCreator, setOpenCreator] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [newUser, setNewUser] = useState(EmptyUserObj);
  const [loading, setLoading] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [userToDelete, setUserToDelete] = useState("");
  const [timeFilter, setTimeFilter] = useState("All Users");
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const appContext = useContext(AppContext);
  const [fullscreen, setFullscreen] = useState(true);
  const searchTerms = ["First Name", "Last Name", "Email", "Phone", "Role"];
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessageText, setErrorMessageText] = useState("");
  const [usersPerPage, setUsersPerPage] = useState(PAGE_LIMIT);
  const [editUser, setEditUser] = useState(false);
  const [cloneRoles, setCloneRoles] = useState("");
  // const [pageNumbers, setPageNumbers] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setErrorMessageText("");
    }, 2000);
  }, [errorMessageText]);

  const sortUsers = (users, sortParam) => {
    if (sortDescending) {
      return [...users].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...users].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  const getUserLabName = (labId) => {
    const filterLab = appContext.labs.filter((lab) => lab.id === labId);
    if (filterLab.length > 0) {
      return filterLab[0];
    }
  };

  const getUserClientName = (clientId) => {
    return appContext.clients.find((client) => clientId === client.id);
  };

  const getUserSiteName = (userId) => {
    if (userId) {
      const filterSite = appContext.sites.filter((s) => s.id === userId);
      if (filterSite.length > 0) {
        return filterSite[0];
      }
    }
  };

  const isJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const handleAdditionalFields = (user) => {
    let obj = {};
    const associated = [];
    const oldRole = [];
    if (!user?.roles || user?.roles === "undefined") {
      oldRole.push(user.userRole.slice(0, -1));

      return {
        ...user,
        username: user.userID,
        phone: user.phone_number.replace(/ /g, ""),
        additionalFields: obj,
        oldRole: oldRole,
        roleTitle: user.userRole.slice(0, -1),
        roleValue: user.userRole.slice(0, -1),
        associated: "System",
      };
    }

    const userRoles = isJson(user.roles) ? JSON.parse(user.roles) : user.roles;
    if (user.phone_number === "+923224340323") {
      console.log("Phone", userRoles);
    }
    for (const role of userRoles) {
      if (role.role === HEALTH_AND_SAFTEY || role.role?.toLowerCase().includes("employer")) {
        const site = getUserSiteName(role?.relatedId);
        if (site) {
          let show = { label: site.name, value: site.id, id: site.id, name: site.name };
          associated.push(site.name);
          Object.assign(user, { show });
          obj = { ...obj, show: show };
        }
      }

      if (role.role === USER_ROLE_TYPE.Lab || role.role === USER_ROLE_TYPE.labTech) {
        const lab = getUserLabName(role?.relatedId);
        if (lab) {
          let labData = {
            label: lab.name,
            value: lab.id,
            id: lab.id,
            name: lab.name,
            appPackage: lab.whiteLabelPackage,
          };
          associated.push(lab.name);
          Object.assign(user, { lab: labData });
          obj = { ...obj, lab: labData };
        }
      }

      if (role.role === USER_ROLE_TYPE.Client) {
        const client = getUserClientName(role?.relatedId);
        if (client) {
          let clientData = { label: client.name, value: client.id, id: client.id, name: client.name };
          associated.push(client.name);
          Object.assign(user, { client: clientData });
          obj = { ...obj, client: clientData };
        }
      }

      if (role.role === USER_ROLE_TYPE.Provider) {
        const provider = getUserSiteName(role?.relatedId);
        if (provider) {
          let providerData = { label: provider.name, value: provider.id, id: provider.id, name: provider.name };
          associated.push(provider.name);
          Object.assign(user, { provider: providerData });
          obj = { ...obj, provider: providerData };
        }
      }
    }

    let roleTitle = "";
    let roleHoverTitle = "";
    const ROLE_LABEL = {
      Lab: "Admin",
      LabTech: "Lab Technician",
    };

    const roleValueToShow = (value) => {
      let roleTitle = value.endsWith("s") ? value.slice(0, -1) + "" : value;
      roleTitle = ROLE_LABEL[roleTitle] || roleTitle;
      return roleTitle;
    };

    const displayRole = userRoles.filter((r) => r.role !== "Client");

    if (displayRole.length > 1) {
      roleTitle = "Multi";
      roleHoverTitle = displayRole.map((t) => roleValueToShow(t.role)).join(",");
    } else {
      roleTitle = roleValueToShow(displayRole[0]?.role || userRoles[0]?.role);
      roleHoverTitle = roleTitle;
    }

    let onlyRoles = displayRole?.map((obj) => {
      return obj.role;
    });

    let roleValue = displayRole?.map((obj) => {
      if (obj.role === "Employee") return "Patient";
      return obj.role;
    });

    return {
      ...user,
      roleHoverTitle,
      username: user.userID,
      phone: user.phone_number.replace(/ /g, ""),
      additionalFields: obj,
      oldRole: onlyRoles,
      roleTitle,
      roleValue,
      associated: associated.join(",") || "System",
    };
  };

  const formatUsers = () => {
    const formattedUsers = appContext.users.filter((u) => u.firstName).map((user) => handleAdditionalFields(user));
    setUsers(sortUsers(formattedUsers, sortBy));
    setFilteredUsers(sortUsers(nestedFilter(formattedUsers, filter), sortBy));
  };

  const filterUsers = (searchTerm, searchInput) => {
    return setFilteredUsers(users.filter((user) => user[searchTerm].includes(searchInput)));
  };

  const existingLabUser = async (user) => {
    const searchInput = user.phone;
    let findExistingUser = users.find((user) => user.phone.includes(searchInput));

    if (findExistingUser && !editUser) {
      let msg = `Phone Number is already registered`;
      await appContext.resetUsers(CONFIG.whiteLabelID);
      appContext.showErrorMessage(msg);
      resetView();
      return true;
    }
    return false;
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }

        if (key === "First Name") {
          return obj.firstName && obj.firstName.toLowerCase().includes(filters[key].toLowerCase());
        }

        if (key === "Last Name") {
          return obj.lastName && obj.lastName.toLowerCase().includes(filters[key].toLowerCase());
        }

        if (key === "phone_number") {
          return obj.phone_number && obj.phone_number.replace(/\D/g, "").includes(filters[key].toLowerCase());
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    return models;
  };

  const formatRoleChangeParams = (roles, userId) => {
    const roleArrFormat = roles.map((element) => {
      if (element === HEALTH_AND_SAFTEY || element.toLowerCase().includes("employer")) {
        return "Employers";
      }
      return `${element}s`;
    });
    return {
      groupname: roleArrFormat,
      username: userId,
    };
  };

  const getUserBaseRole = (user) => {
    if (user.role.includes("Admin")) return "Admins";
    if (user.role.includes("LabTech")) return "LabTechs";
    if (user.role.includes("Lab")) return "Labs";
    if (user.role.includes(HEALTH_AND_SAFTEY)) return "Employers";
    if (user.role.includes("Client")) return "Clients";
    if (user.role.includes("Tester")) return "Testers";
    if (user.role.includes("Provider")) return "Providers";
    return "Employees";
  };

  const mergeWithPriority = (array1, array2, key) => {
    const map = new Map();

    array2.forEach((item) => map.set(item[key], item));

    array1.forEach((item) => {
      if (!map.has(item[key])) {
        map.set(item[key], item);
      }
    });

    return [...map.values()];
  };

  const concateUsermultipleRoleData = async (rolesData, concateRoles) => {
    let newCloneRoles = mergeWithPriority(JSON.parse(cloneRoles), concateRoles, "role");
    let items = rolesData.split(",");
    items.push("Client");

    return newCloneRoles.filter((f) => items.indexOf(f.role) !== -1);
  };

  const updateLabUserInfo = async (userId, user, userMultipleRolesData) => {
    let userRolesData = userMultipleRolesData.map((r) => r.role).join(",");

    // userMultipleRolesData.push({
    //   role: "Lab",
    //   relatedId: "c701eca8-bbc2-48af-aef0-14131c1dc0ad",
    //   title: "Zend Lab",
    //   clientID: "2b99da15-4aef-4ac2-b75e-eab64c0ebc6c",
    //   labID: "c701eca8-bbc2-48af-aef0-14131c1dc0ad",
    // });

    let updateStatus = await api.updateUserInfoV1(user, userRolesData, JSON.stringify(userMultipleRolesData));

    // removeMultiUserGroup
    if (userId && user.oldRole && user.oldRole.length > 0) {
      await api.removeMultipleUserFromGroup(formatRoleChangeParams(user.oldRole, userId));
    }
    // addMultipleUserToGroup
    if (userId && userRolesData.length > 0) {
      await api.addMultipleUserToGroup(formatRoleChangeParams(userRolesData.split(","), userId));
    }
    resetView();
    return updateStatus;
  };

  const resetView = () => {
    setCloneRoles("");
    setOpenCreator(false);
    setEditUser(false);
    setLoading(false);
  };

  const createNewUserInCognito = async (user, note) => {
    try {
      const attributePayload = {
        email: user.email,
        "custom:role": user.role,
        "custom:autoConfirm": "true",
        "custom:firstName": `${user.firstName}`,
        "custom:lastName": `${user.lastName}`,
        "custom:note": note,
        "custom:privilege": "whiteLabelUser",
        "custom:clientID": CONFIG.clientID,
        "custom:connectedID": CONFIG.clientID,
        "custom:labID": CONFIG.whiteLabelID,
        "custom:is2FaEnabled": user?.is2FaEnabled ? "y" : "n",
      };

      const signUp = await Auth.signUp({
        username: user.phone,
        password: user.password,
        attributes: attributePayload,
      });

      return signUp;
    } catch (e) {
      console.log("Exce", e);
    }
    return null;
  };

  const checkAndAddUserRoles = async (user, currentRoleData, multipleRoleData) => {
    const existingRolesData = [...currentRoleData];
    const newRoles = JSON.parse(multipleRoleData);

    const hasRole = (roleArray, role) => roleArray.some((r) => r.role === role);

    const addOrUpdateRole = (role, relatedId, title) => {
      const existingRole = existingRolesData.find((r) => r.role === role);
      if (existingRole) {
        existingRole.relatedId = relatedId;
        existingRole.isWhiteLabelLab = true;
      } else {
        existingRolesData.push({
          role,
          relatedId,
          title,
          isWhiteLabelLab: true,
        });
      }
    };

    if (hasRole(newRoles, "Lab")) addOrUpdateRole("Lab", user.lab.id, user.lab.label);
    if (hasRole(newRoles, "LabTech")) addOrUpdateRole("LabTech", user.lab.id, user.lab.label);
    if (hasRole(newRoles, "Client")) addOrUpdateRole("Client", CONFIG.clientID, CONFIG.baseName);

    if (hasRole(newRoles, "Tester") && !hasRole(existingRolesData, "Tester")) {
      existingRolesData.push({
        role: "Tester",
        isWhiteLabelLab: true,
      });
    }

    return existingRolesData;
  };

  const addLabIDWithTester = (roles) => {
    const multipleRoleData = JSON.parse(roles);
    const newRoles = multipleRoleData.map((r) => {
      return {
        ...r,
        clientID: CONFIG.clientID,
        labID: CONFIG.whiteLabelID,
      };
    });

    return JSON.stringify(newRoles);
  };

  const createUser = async (user) => {
    let isLabUserExists = await existingLabUser(user);
    if (isLabUserExists) {
      return;
    }

    try {
      setLoading(true);
      let userId = null;
      const phoneNo = user.phone;
      let rolesData = user.role;

      user.role = getUserBaseRole(user);
      let multipleRoleData = addLabIDWithTester(user.multipleRoles);

      if (user.isNew) {
        let userExistsInDB = await api.isUserExistsInDB(phoneNo);
        if (Object.keys(userExistsInDB).length > 0) {
          appContext.showErrorMessage("Phone Number is already registered in Medflow with defferent particulars");
          resetView();
          return;
        }
      }

      //for new User
      if (user.isNew) {
        let noteArr = [];
        let note = "";
        if (user.show) noteArr.push(user.show.label);
        if (user.lab) noteArr.push(user.lab.label);
        if (user.client) noteArr.push(user.client.label);
        if (rolesData.includes("Admin")) noteArr.push("System");
        if (rolesData.includes("LabTech")) noteArr.push("Lab Tech");
        if (rolesData.includes("Tester")) noteArr.push("Tester");

        note = noteArr.join(",");

        const signUp = await createNewUserInCognito(user, note);
        if (!signUp) {
          appContext.showErrorMessage("Fail to Create the User");
          setLoading(false);
          return;
        }
        userId = signUp.userSub;

        // addEmployeeRoles
        if (userId && multipleRoleData.length > 0) {
          let modifiedRoleData = JSON.parse(multipleRoleData);
          modifiedRoleData = modifiedRoleData.map((u) => {
            return { ...u, isWhiteLabelLab: true };
          });

          let addRoleObj = {
            firstName: user.firstName,
            lastName: user.lastName,
            phone_number: phoneNo,
            email: user.email,
            userRole: `${user.role === HEALTH_AND_SAFTEY ? "Employer" : user.role}`,
            note,
            username: userId,
            roles: JSON.stringify(modifiedRoleData),
            labID: CONFIG.whiteLabelID,
            clientID: CONFIG.clientID,
            labPackage: CONFIG.appPackage,
            is2FaEnabled: user?.is2FaEnabled ? "y" : "n",
            personalisation: JSON.stringify(DEFAULT_USER_PERSONALIZATION),
            userPermission: user.userPermission,
          };
          await api.addEmployeeRoles(addRoleObj);
        }

        // addMultipleUserToGroup
        if (userId && rolesData.length > 0) {
          await api.addMultipleUserToGroup(formatRoleChangeParams(rolesData.split(","), userId));
        }
        appContext.showSuccessMessage("User created successfully");
      } else {
        userId = user.username;
        let concateRoles = JSON.parse(multipleRoleData);

        let userMultipleRolesData = await concateUsermultipleRoleData(rolesData, concateRoles);
        await updateLabUserInfo(userId, user, userMultipleRolesData);
        appContext.showSuccessMessage("User updated successfully");
      }

      if (userId) {
        let msg = `Hello ${user.firstName}, Please see the below link to access portal. \nUser Name: ${user.phone} \nPassword: ${user.password} \n https://medflow.gosafecamp.com/`;
        if (user.isNew && !CONFIG.isWhiteLabelLab) {
          // await api.sendSMSNotification(user.phone, msg);
        }

        await appContext.resetUsers(CONFIG.whiteLabelID);
      }

      resetView();
    } catch (error) {
      console.log("ERROR:User-", error);
      let message = error.message.replace("_", " ");
      setErrorMessageText(message);
    }
    setLoading(false);
  };

  const handleResetPassword = (obj) => {
    console.log(" Obj", obj);
  };

  const handleClose = () => {
    setOpenCreator(false);
    setOpenDeleteConfirmation(false);
  };

  const handlePasswordClose = () => {
    setOpenPasswordModal(false);
  };

  const handleChangePage = (number) => {
    setCurrentPage(number);
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = (users) => {
    return users.slice(indexOfFirstUser, indexOfLastUser);
  };

  let usersToMap =
    Object.values(filter).length === 0 || Object.keys(filter).length === 0
      ? currentUsers(users)
      : currentUsers(filteredUsers);

  const getPageNumbers = (users) => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(users.length / usersPerPage); i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  let pageNumbers = getPageNumbers(users);

  useEffect(() => {
    loadPrevSorting();
    if (CONFIG.whiteLabelID) {
      appContext.resetUsers(CONFIG.whiteLabelID);
    }
  }, [appContext?.userLab]);

  useEffect(() => {
    formatUsers();
  }, [appContext?.users]);

  useEffect(() => {
    if (users.length > 0) setUsers(sortUsers(users, sortBy));
    const prevSortingState = sortingFilterInLC.get();
    prevSortingState["users"] = { filter, timeFilter, sortBy, sortDescending };
    sortingFilterInLC.save(prevSortingState);
  }, [sortDescending]);

  useEffect(() => {
    if (users.length > 0) {
      setFilteredUsers(nestedFilter(users, filter));
      setCurrentPage(1);
    }
  }, [filter]);

  const loadPrevSorting = () => {
    const sortingFilters = sortingFilterInLC.get();
    if (sortingFilters.users.sortBy) {
      setSortDescending(sortingFilters.users.sortDescending);
      setSortBy(sortingFilters.users.sortBy);
    }
  };

  if (filteredUsers.length > 0) {
    pageNumbers = getPageNumbers(filteredUsers);
  }

  const onRoleChange = async (e, user) => {
    setLoading(true);
    await api.changeUserRole(user.role, e.value, user.username);
    setTimeout(() => {
      appContext.resetUsers(CONFIG.whiteLabelID);
    }, 1500);
  };

  const renderPageNumbers = () => {
    let slicedPages = pageNumbers;
    if (currentPage === 1) {
      slicedPages = pageNumbers.slice(0, 3);
    } else if (currentPage === pageNumbers.length) {
      slicedPages = pageNumbers.slice(currentPage - 3, currentPage);
    } else {
      slicedPages = pageNumbers.slice(currentPage - 2, currentPage + 1);
    }

    return slicedPages.map((number) => {
      return (
        <li
          key={number}
          onClick={() => handleChangePage(number)}
          style={{
            textDecoration: currentPage === number ? "none" : "underline",
            color: currentPage === number ? "black" : "#A82632",
            display: "inline-block",
            padding: 5,
          }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "none";
          }}
          onMouseLeave={(e) => {
            e.target.style.textDecoration = currentPage === number ? "none" : "underline";
          }}
        >
          {number}
        </li>
      );
    });
  };

  const roleChangeOptions = [
    { value: "Admin", label: "Admin" },
    { value: "Lab", label: "Lab" },
    { value: "Tester", label: "Tester" },
    { value: "Employee", label: "Crew" },
    { value: "Employer", label: "Health & Safety" },
  ];

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const handleEditUser = (user) => {
    const obj = { ...user, isNew: false };
    setCloneRoles(obj.roles);
    setNewUser(obj);
    setOpenCreator(true);
    setEditUser(true);
  };

  const handleConfirm = async (isConfirm) => {
    setOpenDeleteConfirmation(false);
    if (!isConfirm) {
      setUserToDelete(null);
      return;
    }
    try {
      let allUserRoles = JSON.parse(userToDelete.roles);
      setLoading(true);
      let isUpdated = true;
      await api.deleteUser(userToDelete.username, userToDelete.id);
      // if (allUserRoles.length > 1) {
      //   allUserRoles = allUserRoles.filter((r) => r.relatedId !== CONFIG.whiteLabelID && !r.isWhiteLabelLab);

      //   isUpdated = await updateLabUserInfo(userToDelete.username, { ...userToDelete, lab: null }, allUserRoles);
      // } else {
      //   await api.deleteUser(userToDelete.username, userToDelete.id);
      // }

      setUserToDelete(null);
      if (isUpdated) {
        appContext.showSuccessMessage("User removed successfully");
      } else {
        appContext.showErrorMessage("Fail to remove the User");
      }
      appContext.resetUsers(CONFIG.whiteLabelID);
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const TableRow = ({ user }) => {
    return (
      <tr key={user.username} className="trDataWrapper">
        <td className="icon">
          {permission[SYSTEM_USERS_VALUE]?.editUserCredentials === true && (
            <img
              src={editIcon}
              alt="edti icon"
              className="fas fa-edit"
              onMouseLeave={(e) => (e.target.style.color = "black")}
              onMouseOver={(e) => {
                e.target.style.color = "blue";
                e.target.style.cursor = "pointer";
              }}
              aria-hidden="true"
              id={user.id}
              onClick={(e) => handleEditUser(user)}
            />
          )}
        </td>

        <RenderTd item={{ itemKey: "firstName" }} user={user} />
        <RenderTd item={{ itemKey: "lastName" }} user={user} />
        <RenderTd item={{ itemKey: "email" }} user={user} />
        <RenderTd key={user.username} item={{ itemKey: "phone_number" }} user={user} />
        <td className="ellipsis" style={{ textAlign: "center", textOverflow: "visible" }} title={user.roleHoverTitle}>
          {user.roleTitle}
        </td>
        {/* <td className="ellipsis" style={{ textAlign: "center", textOverflow: "visible" }} title={user.associated}>
          {user.associated}
        </td> */}
        <td className="ellipsis" style={{ textAlign: "center", textOverflow: "visible" }} title={user.status}>
          {toTitleCase(user.status) || "Confirmed"}
        </td>
        <td className="icon">
          {permission[SYSTEM_USERS_VALUE]?.archiveUser === true && (
            <i
              className="fa fa-trash"
              onMouseLeave={(e) => (e.target.style.color = "black")}
              onMouseOver={(e) => {
                e.target.style.color = "#8B0000";
                e.target.style.cursor = "pointer";
              }}
              aria-hidden="true"
              id={user.id}
              onClick={(e) => {
                setOpenDeleteConfirmation(true);
                setUserToDelete(user);
              }}
            />
          )}
        </td>
      </tr>
    );
  };
  return (
    <div style={{ flex: 1 }} ref={mainWidth}>
      {!loading ? (
        <div className="shows-screen-parent">
          <Row>
            <Col>
              <Card className="strpied-tabled-with-hover bg-transparent border-0">
                <Card.Header>
                  <Row>
                    <Card.Title
                      as="h4"
                      style={{
                        marginBottom: 10,
                        fontWeight: "bold",
                        color: "#565656",
                      }}
                    >
                      Users ({filteredUsers ? filteredUsers.length : users.length})
                    </Card.Title>
                    <div className="buttonHeader">
                      <Icon
                        handleClick={() => setShowFilter(!showFilter)}
                        title={"Filter"}
                        label={"Filter"}
                        iconType={"filter"}
                      />

                      {permission[SYSTEM_USERS_VALUE]?.createNewUser === true && (
                        <Icon
                          handleClick={() => {
                            setNewUser({ ...EmptyUserObj });
                            setOpenCreator(true);
                          }}
                          title={"Create a User"}
                          label={"Create"}
                          iconType={"addUserIcon"}
                        />
                      )}
                    </div>
                    <Col md="12">
                      {showFilter && (
                        <Filter filterTerms={searchTerms} setFilter={setFilter} filter={filter} isUsersTable={true} />
                      )}
                    </Col>
                  </Row>
                  <Row className="pull-right" style={{ borderWidth: 1 }}>
                    <Col></Col>
                    <Col style={{ textAlign: "right" }} md={{ span: 3, offset: 1 }} xl={{ span: 3, offset: 1 }}>
                      Page
                      <ul className="createButtons pr-0" style={{ display: "inline" }}>
                        <i
                          className="fas fa-angle-left"
                          style={{ color: "#585858" }}
                          onMouseOver={(e) => {
                            e.target.style.cursor = "pointer";
                            e.target.style.color = "black";
                          }}
                          onMouseLeave={(e) => (e.target.style.color = "#585858")}
                          onClick={() => {
                            currentPage !== 1 && handleChangePage(currentPage - 1);
                          }}
                        />
                        {/* {currentPage !== 1 && "..."} */}
                        {currentPage} of {pageNumbers.length}
                        {/* {pageNumbers.length >= currentPage && "..."} */}
                        <i
                          className="fas fa-angle-right"
                          style={{ color: "#585858" }}
                          onMouseOver={(e) => {
                            e.target.style.cursor = "pointer";
                            e.target.style.color = "black";
                          }}
                          onMouseLeave={(e) => (e.target.style.color = "#585858")}
                          onClick={() => {
                            currentPage !== pageNumbers.length && handleChangePage(currentPage + 1);
                          }}
                        />
                      </ul>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body className="table-full-width px-0 desktop-noScroll mt-0 pt-0">
                  <div className="table-responsive pendingReleaseTable">
                    <Table className="separate-border-tr">
                      <thead ref={componentRef}>
                        <tr>
                          <th />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="firstName"
                            title="First Name"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                          />

                          <HeaderItem
                            width={tdWidth}
                            ItemKey="lastName"
                            title="Last Name"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="email"
                            title="Email"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="phone_number"
                            title="Phone Number"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="role"
                            title="Role"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned="centered"
                          />
                          {/* <HeaderItem
                          width={tdWidth}
                          ItemKey="associated"
                          title="Associated"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        /> */}
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="status"
                            title="Status"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned="centered"
                          />
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {usersToMap &&
                          usersToMap.map((user, i) => {
                            return (
                              <TableRow
                                key={i}
                                user={user}
                                isEditPermission={permission[SYSTEM_USERS_VALUE]?.createNewUser === true}
                                isDeletePermission={permission[SYSTEM_USERS_VALUE]?.archiveUser === true}
                              />
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        // <h3
        //   style={{
        //     display: "block",
        //     marginLeft: "auto",
        //     marginRight: "auto",
        //     paddingTop: 50,
        //     paddingBottom: 50,
        //   }}
        // >
        //   Loading...
        // </h3>
        <Loader />
      )}
      {openCreator && (
        <WhiteLabelUserModal
          show={openCreator}
          user={newUser}
          handleClose={handleClose}
          handleSave={createUser}
          resetCall={setOpenPasswordModal}
          message={errorMessageText}
        />
      )}
      {openPasswordModal && (
        <UserPasswordModal user={newUser} handleClose={handlePasswordClose} handleSave={handleResetPassword} />
      )}

      {openDeleteConfirmation && (
        <ConfirmationModal
          show={openDeleteConfirmation}
          title="Delete User"
          message="Are you sure, you want to remove User?"
          handleConfirm={handleConfirm}
        />
      )}
    </div>
  );
};

export default UsersViewWhiteLabel;
