import Filter from "components/Filter";
import Icon from "components/Icon";
import Loader from "components/Loader/Loader";
import ProcedureImportModal from "components/Modal/ProcedureImportModal";
import MainTable from "components/Table/MainTable";
import { useContext, useEffect, useState, useMemo } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { TEST_DICTIONARY_PERSONALIZE } from "constants/personalization";
import { setMessage } from "store/features/general/generalAction";
import api from "api";
import InputField from "components/InputField/InputField";
import useOptions from "../TestTypeSetting/useOptions";
import ConfirmationModal from "components/Modal/ConfirmationModal";

import CreateOptions from "../TestTypeSetting/TestUnitSelector/CreateOptions";
import CreateLabelOption from "../TestTypeSetting/LabelIDSelector/CreateLabelOption";
import {
  TEST_SETTING_TYPES,
  DICTIONARY_COLUMNS,
  DICTIONARY_OPTIONS,
  SOURCE_DICTIONARY_COLUMNS,
  TESTS_DICTIONARY,
} from "constant";
import { fetchGeneralSettings } from "store/features/testDictionary/testDictionarySlice";
import { generalSettings } from "store/features/testDictionary/testDictionarySelectors";
import "../../../src/tableStyling.css";
import { TABLE_QUICK_TOOLS, MESSAGE_MODES } from "../../constant";
import { t } from "../../stringConstants";
import { userGivenPermission } from "store/features/authentication/authenticationSelectors";
import { getTestsDetails } from "api";
import { TEST_PANEL_PERSONALIZE } from "constants/personalization";
import { renderSourcesCell, formatNumber } from "utils";
import { createTestsDetails } from "api";
import { updateTestsDetails } from "api";
import { AppContext } from "context/app-context";
import TestDetailsModal from "views/TestTypeSetting/TestDetailsModal";
import LoadTestPanels from "./LoadTestPanels";
import { createBulkTestsDetails } from "api";
import { panelSelector } from "store/features/testAssay/testAssaySelector";
import { getTestAssociatePanel } from "store/features/testAssay/testAssaySlice";
import { getLoginUserInfoFromLocal } from "utils";
import { parseTestUnit } from "utils";
import { customKeyBy } from "util/customLodash";

const TestDictionary = () => {
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({});
  const [openImportModal, setOpenImportModal] = useState(false);
  const [openPanelImportModal, setOpenPanelImportModal] = useState(false);
  const dispatch = useDispatch();
  const generalSettingsData = useSelector(generalSettings);
  const permission = useSelector(userGivenPermission);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [testPanelData, setTestPanelData] = useState(null);

  const loginUserInfo = getLoginUserInfoFromLocal();

  const quickTools = [permission[TESTS_DICTIONARY]?.write && TABLE_QUICK_TOOLS.edit];

  const [createTestDictionary, setCreateTestDictionary] = useState(false);
  const [selectedTDItems, setSelectedTDItems] = useState();
  const [settingType, setSettingType] = useState(TEST_SETTING_TYPES.SOURCE);

  const [filterValues, setFilterValues] = useState([]);
  const { addOption, updateOption, deleteOption } = useOptions(settingType.value);
  const [searchTerms, setSearchTerms] = useState(["Code", "Title"]);
  const [filterColumns, setFilterColumns] = useState(DICTIONARY_COLUMNS);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [userToDelete, setUserToDelete] = useState("");

  const appContext = useContext(AppContext);

  const testPanels = useSelector(panelSelector);

  const getTestsPanesl = async () => {
    dispatch(getTestAssociatePanel());
  };

  const fetchSettings = () => {
    dispatch(fetchGeneralSettings());
    getTestsPanesl();
  };
  useEffect(() => {
    fetchSettings();
  }, []);

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  useEffect(() => {
    setSettingType(DICTIONARY_OPTIONS[0]);
  }, []);

  const handleCellClick = (key, row) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.edit:
        if (settingType.value === "testPanels") {
          // setTestPanelData(row);
          setOpenDetailModal(true);

          setTestPanelData({
            ...row,
            typeOfTest: row.possibleValues ? row.possibleValues : "Quantitative",
            isNew: false,
          });
        } else {
          setSelectedTDItems({ item: row });
          setCreateTestDictionary(true);
        }
        break;

      default:
        break;
    }
  };
  const handelAddOption = (value) => {
    const id = selectedTDItems?.item?.id;
    const action = id ? updateOption : addOption;
    action(value, id);
    setCreateTestDictionary(false);
  };

  useEffect(() => {
    setFilterValues(generalSettingsData?.filter((item) => item?.settingType === settingType?.value));
  }, [settingType, generalSettingsData]);

  const searchRecords = (records, searchParams) => {
    const matchesSearchParams = (record) => {
      return Object.keys(searchParams).every((key) => {
        const searchValue = searchParams[key];
        if (!searchValue) {
          return true;
        }
        const recordValue = record[key]?.toString().toLowerCase();
        return recordValue && recordValue.includes(searchValue.toLowerCase());
      });
    };

    return records.filter((record) => {
      if (settingType?.value === "testPanels") {
        return matchesSearchParams(record); // No need to check settingType for 'testPanels'
      }
      return record?.settingType === settingType?.value && matchesSearchParams(record);
    });
  };

  useEffect(() => {
    const filterRecords = searchRecords(
      settingType.value === "testPanels" ? [...testPanels] : generalSettingsData,
      filter
    );
    setFilterValues(filterRecords);
  }, [filter]);

  useEffect(() => {
    const sourceDictionaryValues = [
      DICTIONARY_OPTIONS[0].value,
      DICTIONARY_OPTIONS[2].value,
      DICTIONARY_OPTIONS[1].value,
    ];
    if (sourceDictionaryValues.includes(settingType.value)) {
      setSearchTerms(["Title"]);
      setFilterColumns(SOURCE_DICTIONARY_COLUMNS);
    } else if (settingType.value === "testPanels") {
      setFilterColumns(TEST_PANEL_PERSONALIZE);
      setFilterValues(testPanels);
      setSearchTerms(["Panel Name"]);
    } else {
      setFilterColumns(DICTIONARY_COLUMNS);
      setSearchTerms(["Code", "Title"]);
    }
  }, [settingType, testPanels]);

  const renderCreateOptions = () => {
    if (!createTestDictionary) return null;

    if (settingType.label === DICTIONARY_OPTIONS[3].label) {
      return (
        <CreateLabelOption
          handleClose={() => setCreateTestDictionary(false)}
          handleSaveChange={handelAddOption}
          selectedValue={{ label: selectedTDItems?.item?.label, code: selectedTDItems?.item?.value }}
        />
      );
    }

    return (
      <CreateOptions
        handleClose={() => setCreateTestDictionary(false)}
        handleSaveChange={handelAddOption}
        title={settingType.label}
        selectedValue={selectedTDItems?.item?.label}
        label={settingType.label}
      />
    );
  };

  const handleConfirm = async (isConfirm) => {
    if (!isConfirm) {
      setUserToDelete(null);
      setOpenDeleteConfirmation(false);
      return;
    }
    try {
      if (settingType && settingType.label === "Test Pathogen") {
        const deleteAssay = await api.deleteTestAssay([userToDelete.id]);
        dispatch(setMessage("Archived Successfully", MESSAGE_MODES.success));
        return;
      }
      deleteOption(userToDelete.id);
      setUserToDelete(null);
      setOpenDeleteConfirmation(false);
      dispatch(setMessage("Archived Successfully", MESSAGE_MODES.success));
    } catch (error) {
      dispatch(setMessage(error?.response?.data?.error, MESSAGE_MODES.error));
    }
  };

  // const renderTd = (item, row) => {
  //   return <td key={item.id}>{row?.title || row?.label}</td>;
  // };

  const handleSaveTestDetail = async (testDetailsData) => {
    const { isNew } = testDetailsData;
    const action = isNew ? "create" : "update";
    try {
      await (isNew ? createTestsDetails : updateTestsDetails)(testDetailsData);
      getTestsPanesl();
      // if (result?.data) {
      //   setEditObj((prevEditObj) => ({
      //     ...prevEditObj,
      //     targetRanges: prevEditObj?.targetRanges?.map((item) => (item.id === result.data.id ? result.data : item)),
      //   }));
      // }

      setOpenDetailModal(false);
      setTestPanelData(null);
    } catch (error) {
      console.error("Error", error);
      appContext.showErrorMessage(`Unable to ${action} panel test.`);
    }
  };

  const customRenderTD = (item, row, index) => {
    if (item.itemKey === "action") {
      return (
        <td key={`bin_${item.id}`} style={{ textAlign: "right" }}>
          <Icon
            iconType={"binIcon"}
            containerClass={"ms-2 cursor-pointer"}
            innerClass={""}
            imageClass={"testTabelDeleteIcon"}
            // handleClick={() => handleTestRemove(row)}
          />
        </td>
      );
    }
    if (item.itemKey === "units") {
      return <td key={`unit_${item.id}`}>{parseTestUnit(row.units)}</td>;
    }
    if (item.itemKey === "sources") {
      return renderSourcesCell(item, row);
    }
    return <td key={item.id}>{row?.title || row?.label}</td>;
  };

  const parseStringToObject = (rawUnits) => {
    return rawUnits.split(",").map((unit) => ({
      label: unit,
      value: unit,
    }));
  };

  const ranges = {
    id: 1,

    // AgeStart: "",
    // AgeEnd: "",

    NormalLow: 0,
    NormalHigh: 0,

    HighLow: 0,
    HighHigh: 0,

    PanicLow: 0,
    PanicHigh: 0,

    RepeatLow: 0,
    RepeatHigh: 0,

    LowLow: 0,
    LowHigh: 0,
  };

  const getMinMax = (input) => {
    const parts = input.split("-").map((str) => str.trim());
    function extractFirstNumber(str) {
      if (!str) return 0;
      const sanitizedStr = str.replace(/,/g, "");
      const match = sanitizedStr.match(/^\d+/);
      return match ? parseFloat(match[0]) : 0;
    }
    const min = parts[0] ? extractFirstNumber(parts[0]) : 0;

    const max = parts[1] ? extractFirstNumber(parts[1]) : 0;

    if (!isNaN(min) && !isNaN(max)) {
      return [
        {
          id: 1,
          ...ranges,
          NormalLow: min,
          NormalHigh: max,
        },
      ];
    } else {
      return [{ ...ranges }];
    }
  };

  const handleImport = async (locData) => {
    const parsedData = locData.map(({ data }) => {
      const {
        Unit,
        "Result Type": resultType,
        "Associated Targets, Panels": name,
        Source,
        "Refrence Interval/ Range": refRanges,
      } = data;

      const rng = getMinMax(refRanges);

      return {
        units: parseStringToObject(Unit),
        possibleValues: resultType,
        isActive: true,
        name: name,
        refType: "Not Detected",
        refInterval: "Not Detected",
        ranges: rng,
        resultType: "",
        sources: parseStringToObject(Source),
        maxValueF: "",
        maxValueM: "",
        minValueF: "",
        minValueM: "",
        additionalInfo: "",
        effectiveDate: "",
        resultType: "",
      };
    });

    const removedDuplicated = customKeyBy(parsedData, "name");
    const dataAfterRemoveDuplicate = Object.values(removedDuplicated);

    try {
      const response = await createBulkTestsDetails({ data: dataAfterRemoveDuplicate });

      getTestsPanesl();
      appContext.showSuccessMessage("Panels Added.");

      setOpenPanelImportModal(false);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const sortFilterValues = (innerFilterValues) => {
    return [...innerFilterValues]?.sort((a, b) => {
      switch (settingType?.label) {
        case "Source":
          return a?.label?.localeCompare(b?.label);
        case "Unit":
          return a?.label?.localeCompare(b?.label);
        case "Department":
          return a.label?.localeCompare(b?.label);
        case "Test Label":
          return a?.title?.localeCompare(b?.title);
        default:
          return 0;
      }
    });
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  {t("testDictionary")} ({formatNumber(filterValues?.length)})
                </Card.Title>
                <div className="buttonHeader d-flex justify-content-between flex-direction-row flex-md-direction-column">
                  <div className="d-flex">
                    <Icon
                      handleClick={() => setShowFilter(!showFilter)}
                      title={"Filter"}
                      label={"Filter"}
                      iconType={"filter"}
                    />
                    {permission[TESTS_DICTIONARY]?.write && (
                      <Icon
                        handleClick={() => {
                          if (settingType.value === "testPanels") {
                            setTestPanelData({ isNew: true, typeOfTest: "Qualitative" });
                            setOpenDetailModal(true);
                          } else {
                            setSelectedTDItems();
                            setCreateTestDictionary(true);
                          }
                        }}
                        title={"Create"}
                        label={"Create"}
                        iconType={"createIcon"}
                      />
                    )}
                    {permission[TESTS_DICTIONARY]?.write && settingType.value === "testPanels" && (
                      <Icon
                        handleClick={() => {
                          setOpenPanelImportModal(true);
                        }}
                        title={"Load Pathogen"}
                        iconType="importIcon"
                        label="Load Pathogen"
                      />
                    )}
                  </div>
                  <div className="dictionary_search">
                    <InputField
                      type="dropDown"
                      groupWrapper="w-100"
                      options={DICTIONARY_OPTIONS}
                      labelStyle="text-capitalize"
                      label="Select Setting Type:"
                      value={settingType}
                      index="Name"
                      isClearable={true}
                      placeholder="Select Setting Type:"
                      handleChange={(e) => {
                        if (e) {
                          setSettingType(e);
                        } else {
                          setSettingType("");
                        }
                      }}
                    />
                  </div>
                </div>

                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    isUsersTable={true}
                    personalisationData={TEST_DICTIONARY_PERSONALIZE}
                  />
                )}
              </Card.Header>

              <Card.Body className="table-full-width desktop-noScroll">
                <div className="table-responsive pendingReleaseTable">
                  <MainTable
                    columns={filterColumns}
                    rows={sortFilterValues(filterValues) || []}
                    flipSort={flipSort}
                    sortBy={sortBy}
                    sortDescending={sortDescending}
                    widthToSkip={155}
                    tools={quickTools}
                    handleCellClick={handleCellClick}
                    customColumnCellRenderer={customRenderTD}
                    dropDownOptions={
                      permission[TESTS_DICTIONARY]?.delete &&
                      TABLE_QUICK_TOOLS.delete && [{ value: "Archive", title: "Archive" }]
                    }
                    handleDropDownClick={async (type, row) => {
                      if (type === "Archive") {
                        setOpenDeleteConfirmation(true);
                        setUserToDelete(row);
                      }
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
          {renderCreateOptions()}

          {openDeleteConfirmation && (
            <ConfirmationModal
              show={openDeleteConfirmation}
              handleClose={() => {
                setUserToDelete(null);
                setOpenDeleteConfirmation(false);
              }}
              title={`Archive ${settingType.label}:`}
              message={`Are you sure, you want to archive ? ${settingType.label}:`}
              handleConfirm={handleConfirm}
            />
          )}
          {openImportModal && (
            <ProcedureImportModal handleClose={() => setOpenImportModal(false)} title={t("importProcedureDataTitle")} />
          )}
        </Row>
      ) : (
        <Loader />
      )}

      {openDetailModal && (
        <TestDetailsModal
          data={{ ...testPanelData, callFrom: "pathegon" }}
          handleSaveTestDetail={handleSaveTestDetail}
          handleClose={() => {
            setOpenDetailModal(false);
            setTestPanelData(null);
          }}
        />
      )}

      {openPanelImportModal && (
        <LoadTestPanels
          handleClose={() => setOpenPanelImportModal(false)}
          handleImport={handleImport}
          appContext={appContext}
          // handleDownloadSample={() => onHandleDownloadSample()}
        />
      )}
    </div>
  );
};

export default TestDictionary;
