import React from "react";
import { Modal, Button } from "react-bootstrap";
import { vhToPixels } from "utils";

const DocumentPreviewModal = ({ isModalOpen, setIsModalOpen, fileUrl, fileType, title }) => {
  return (
    <Modal show={isModalOpen} backdrop="static" onHide={() => setIsModalOpen(false)} size="3xl" centered>
      <Modal.Header closeButton className="h-25" style={{marginBottom:"0px"}}>
        <Modal.Title className="m-0">{title || "Document Preview"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center" style={{padding:"0px", paddingBottom:"5px"}}>
        {fileType === "image" ? (
          <img src={fileUrl} alt="Document Preview" style={{ maxWidth: "100%", height: "auto" }} />
        ) : (
          <iframe src={fileUrl} type="application/pdf" width="100%" height={vhToPixels(80)} title="Document Preview" />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DocumentPreviewModal;
