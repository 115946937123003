import Icon from "components/Icon";
import React from "react";

import "./procedureCodeDetail.css";
import { formatCurrency } from "utils";

const ProcedureCodeDetailView = ({ handelDeleteCpt, cptCodeList, className = "" }) => {
  return (
    <div className={className ? className : "procedure-view-container"}>
      <table className="procedure-view-table">
        <thead>
          <tr>
            <th className="procedure-view-th">Procedure Code</th>

            <th className="procedure-view-th">Procedure Name</th>

            <th className="procedure-view-th">Internal Charge</th>

            <th className="procedure-view-th">Reference Charge</th>
            {handelDeleteCpt && <th className="procedure-view-th">Action</th>}
          </tr>
        </thead>
        <tbody>
          {cptCodeList.map((item, index) => {
            return (
              <tr key={`cpt_detail_${index}`}>
                <td className="procedure-view-td">{item?.internalCode || ""}</td>

                {/* <td className="procedure-view-td" data-title={item?.title || ""}>
                  {item?.title || ""}
                </td> */}
                <td className="procedure-view-td" title={item?.title || ""}>
                  {item?.title || ""}
                </td>

                <td className="procedure-view-td">{formatCurrency(item?.charges || "")}</td>

                <td className="procedure-view-td">{formatCurrency(item?.referenceCharge || "")}</td>
                {handelDeleteCpt && (
                  <td className="procedure-view-td procedure-text-center">
                    <Icon
                      iconType={"binIcon"}
                      containerClass={"cursor-pointer"}
                      innerClass={""}
                      imageClass={"delete-icon-small "}
                      handleClick={() => handelDeleteCpt(index)}
                    />
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ProcedureCodeDetailView;
