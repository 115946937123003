import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RESULTS_TEST, SET_SELECTED_TEST } from "./resultsTestConstant";
import api from "api";
import { generateRefInterval } from "utils";
import { TYPE_OF_TEST } from "constant";
import { getRefInterval } from "utils";
import { customIsEmpty } from "util/customLodash";
import { customIsJsonString } from "util/customLodash";
import { TEST_PANEL_TYPES } from "constant";

export const setSelectedTestAsync = createAsyncThunk(SET_SELECTED_TEST, async (tests) => {
  let simpleTestList = [];
  let detailTestList = [];
  let panelTests = null;

  const processArray = (array, response) => {
    return array.reduce((acc, obj) => {
      const refInterval = generateRefInterval({ ...response, ranges: obj?.ranges?.[0] ?? null });

      if (obj.testType === "mainTest") {
        return acc.concat(obj.targetRanges);
      } else {
        // return acc.concat(obj);
        const { typeOfTest, minValue, maxValue, isAssociatedPanel } = response;

        return acc.concat({
          ...obj,
          possibleValues: typeOfTest,
          refInterval: typeOfTest === "Qualitative" ? "Not Detected" : refInterval,
        });
      }
    }, []);
  };

  const formatTestWithPanel = (testData) => {
    const { targetRanges, typeOfTest } = testData;

    const targetArr = [];

    targetRanges?.forEach((item) => {
      const refInterval = generateRefInterval({ ranges: item?.ranges?.[0] ?? null });

      const finalTargetObj = {
        ...item,
        refInterval: typeOfTest === TYPE_OF_TEST.QUALITATIVE ? "Not Detected" : refInterval,
        typeOfTest,
      };
      targetArr.push(finalTargetObj);
    });

    return targetArr;
  };

  const processTests = async (testId) => {
    const testsList = [];
    const testDetail = await api.getTestTypesByID(testId);

    testsList.push(testDetail);
    // if (testDetail.isAssociatedPanel) {
    //   const testObj = {
    //     ...testDetail,

    //     targetRanges: getRefInterval(testDetail.targetRanges, testDetail.typeOfTest),
    //   };
    //   testsList.push(testObj);
    // } else {
    //   testsList.push({ ...testDetail,  });
    // }

    return testsList;
  };

  const getPanelData = async (panelId) => {
    const panelData = await api.getTestTypesByID(panelId);

    const resultData = await processedPanel(panelData.targetRanges);

    return { panelName: panelData.name, targetRanges: resultData, type: panelData.type };
  };

  const processedPanel = async (targetRanges) => {
    const testsList = [];

    for (const targetItem of targetRanges) {
      if (targetItem.type !== TEST_PANEL_TYPES.PANEL) {
        try {
          const testsData = await processTests(targetItem.id);
          testsList.push(...testsData);
        } catch (error) {
          console.error("Error processing test:", error);
        }
      } else {
        const panelResponse = await getPanelData(targetItem.id);

        testsList.push(panelResponse);
      }
    }

    return testsList;
  };

  for (const test of tests) {
    if (test?.result) {
      const detailRes = await api.getTestResultDetails({ id: test.id });
      if (detailRes && !customIsEmpty(detailRes)) {
        panelTests = {
          ...detailRes,
          clientID: test.clientID,
          siteID: test.siteID,
          barcode: test?.barcode || "",
        };
        break;
      }
    }
    
    const testTypeID = test?.employee_demographics?.testTypeID || test.testTypeID;

    const response = await api.getTestTypesByID(testTypeID);

    if (
      response.type === TEST_PANEL_TYPES.QUALITATIVE_WITH_PATHOGEN ||
      response.type === TEST_PANEL_TYPES.QUANTITATIVE_WITH_PATHOGEN
    ) {
      const testObjWithPanel = {
        tests: response.targetRanges,
        isAssociatedPanel: response.isAssociatedPanel,
        typeOfTest: response.typeOfTest,
        id: test.id,
        type: response.type,
        testName: response.name,
        barcode: test?.barcode || "",
      };
      panelTests = testObjWithPanel;
    } else if (
      response.type === TEST_PANEL_TYPES.QUALITATIVE_WITHOUT_PATHOGEN ||
      response.type === TEST_PANEL_TYPES.QUANTITATIVE_WITHOUT_PATHOGEN
    ) {
      const testObj = {
        ...response,
        units: customIsJsonString(response.units) ? response.units : { label: response.units, value: response.units },
      };
      const finalTestobj = {
        tests: testObj,
        isAssociatedPanel: response.isAssociatedPanel,
        typeOfTest: response.typeOfTest,
        type: response.type,
        id: test.id,
        barcode: test?.barcode || "",
      };
      panelTests = finalTestobj;
    } else if (response.type === TEST_PANEL_TYPES.PANEL) {
      const result = await processedPanel(response.targetRanges);
      const panelFinalData = {
        panelName: response.name,
        type: response.type,
        tests: result,
        id: test.id,
        barcode: test?.barcode || "",
      };

      panelTests = panelFinalData;
    }

    panelTests = { ...panelTests, clientID: test.clientID, siteID: test.siteID };
  }

  const showDetailModal = !simpleTestList.length && detailTestList.length === 1;
  const showSimpleModal = simpleTestList.length > 0;
  const showPanalModal = panelTests !== null;

  return { simpleTestList, detailTestList, panelTests, showDetailModal, showSimpleModal, showPanalModal };
});

const initialState = {
  simpleTestList: null,
  detailTestList: null,
  panelTests: null,
  showDetailModal: false,
  showSimpleModal: false,
  showPanalModal: false,
  listRemovedTest: [],
};

const resultsTestSlice = createSlice({
  name: RESULTS_TEST,
  initialState,
  reducers: {
    resetStateResultState: () => initialState,
    setTestIdsToRemove: (state, action) => {
      state.listRemovedTest = action.payload;
    },
    setSimpleResultModal: (state, action) => {
      state.showSimpleModal = action.payload.showSimpleModal;
      state.simpleTestList = action.payload.simpleTestList;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSelectedTestAsync.fulfilled, (state, action) => {
      Object.assign(state, initialState);

      const { simpleTestList, detailTestList, panelTests, showDetailModal, showSimpleModal, showPanalModal } =
        action.payload;

      state.simpleTestList = simpleTestList;
      state.detailTestList = detailTestList;
      state.panelTests = panelTests;
      state.showDetailModal = showDetailModal;
      state.showSimpleModal = showSimpleModal;
      state.showPanalModal = showPanalModal;
    });
  },
});
export const { resetStateResultState, setTestIdsToRemove, setSimpleResultModal } = resultsTestSlice.actions;
export default resultsTestSlice.reducer;
