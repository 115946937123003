import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import Icon from "components/Icon";
import { useDispatch, useSelector } from "react-redux";
import { userGivenPermission } from "store/features/authentication/authenticationSelectors";
import Loader from "components/Loader/Loader";
import { CONFIG, TESTS_MANAGEMENT } from "constant";
import Filter from "components/Filter";
import CreatePanelModal from "./CreatePanelModal";
import api from "api";
import { TEST_PANELS_PERSONALIZE } from "constants/personalization";
import MainTable from "components/Table/MainTable";
import { TABLE_QUICK_TOOLS } from "constant";

import { toTitleCase, formatNumber } from "utils";
import { setGenericModalData } from "store/features/genericTestsView/genericModalSlice";
import { CREATE_TEST_PANEL_PERSONALIZE } from "constants/personalization";
import { showConfirmationModal } from "store/features/general/generalAction";

const TestPanelsView = () => {
  const permission = useSelector(userGivenPermission);
  const [showFilter, setShowFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const searchTerms = ["Name"];
  const [filter, setFilter] = useState({});
  const [editObj, setEditObj] = useState(null);
  const [detailObj, setDetailObj] = useState(null);

  const dispatch = useDispatch();
  const [testTypesList, setTestTypesList] = useState([]);
  const [panelList, setPanelList] = useState([]);

  const getList = async () => {
    const apiData = await api.getTestTypes();
    setTestTypesList(apiData);
  };
  const getPanelList = async () => {
    const apiRes = await api.getPanels();

    setPanelList(apiRes);
  };

  useEffect(async () => {
    await getPanelList();
    await getList();
  }, []);
  const showPanelsDetails = (row) => {
    if (row.targetRanges.length > 0) {
      const objToShowData = {
        showGenericModal: true,
        genericData: row.targetRanges,
      };
      dispatch(setGenericModalData(objToShowData));
    }
  };

  const customRenderTD = (item, row, index) => {
    if (item.itemKey === "noOfTest") {
      return (
        <td key={`noOfTest${item.id}`} onClick={() => showPanelsDetails(row)} style={{ cursor: "pointer" }}>
          {row?.targetRanges?.length || 0}
        </td>
      );
    }
    if (item.itemKey === "name") {
      return (
        <td key={`name_${item.id}`} onClick={() => showPanelsDetails(row)} style={{ cursor: "pointer" }}>
          {toTitleCase(row?.name) || ""}
        </td>
      );
    }
  };

  const handleEdit = (row) => {
    setEditObj({
      isNew: false,
      ...row,
    });
  };

  const deleteTestPanel = async (row) => {
    const deletedData = await api.deletePanels(row.id);
    if (deletedData) {
      getPanelList();
    }

    return deletedData;
  };

  return (
    <>
      <div className="shows-screen-parent">
        <Row className="">
          <Col md="12">
            <Card className="strpied-tabled-with-hover bg-transparent border-0">
              <Card.Header className="shows-screen-wrapper">
                <Row className="pb-4">
                  <Col md-="12">
                    <Card.Title
                      as="h4"
                      style={{
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      Panel Setting ({formatNumber(panelList.length)})
                    </Card.Title>
                    <div className="shows-filter-wrapper">
                      <div className="shows-filter-inner">
                        <Icon
                          handleClick={() => setShowFilter(!showFilter)}
                          title={"Filter"}
                          label={"Filter"}
                          iconType={"filter"}
                        />

                        {permission[TESTS_MANAGEMENT]?.write && (
                          <Icon
                            handleClick={() =>
                              setEditObj({
                                isNew: true,
                              })
                            }
                            title={"Create Panel Test"}
                            label={"Create New"}
                            iconType={"createIcon"}
                          />
                        )}
                      </div>
                    </div>
                    <div>
                      {showFilter && <Filter filterTerms={searchTerms} setFilter={setFilter} filter={filter} />}
                    </div>
                  </Col>
                </Row>
              </Card.Header>

              <Card.Body className="table-full-width px-0 desktop-noScroll pt-0 mt-0">
                <div className="table-responsive pendingReleaseTable">
                  <MainTable
                    columns={TEST_PANELS_PERSONALIZE}
                    rows={panelList}
                    customColumnCellRenderer={customRenderTD}
                    headerStyle={{ fontWeight: "bold" }}
                    tools={[TABLE_QUICK_TOOLS.edit]}
                    handleCellClick={(key, row) => {
                      if (key === TABLE_QUICK_TOOLS.edit) {
                        handleEdit(row);
                      }
                    }}
                    dropDownOptions={[{ value: "Archive", title: "Archive" }]}
                    handleDropDownClick={async (type, row) => {
                      if (type === "Archive") {
                        const confirmationModalData = {
                          showModal: true,
                          onConfirm: () => deleteTestPanel(row),
                          title: "Archive Test Panel",
                          message: "Are you sure, you want to archive that Test Panel?",
                        };
                        dispatch(showConfirmationModal(confirmationModalData));
                      }
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      {loading && <Loader />}
      {editObj && (
        <CreatePanelModal
          item={editObj}
          handleClose={() => setEditObj(null)}
          testTypesList={testTypesList}
          testPanelList={panelList}
          loadPanel={() => getPanelList()}
        />
      )}
    </>
  );
};

export default TestPanelsView;
