import API from "api";
import Filter from "components/Filter";
import MFPagination from "components/Pagination/MFPagination";
import {
  PAGE_LIMIT,
  PERSONALISATION_KEY,
  PRE_REGISTRATION_STATUS,
  CONFIG,
  ORDER_EMPTY_OBJECT,
  MESSAGE_MODES,
  EMPLOYEE,
  ORDER,
} from "constant";
import { AppContext } from "context/app-context";
import { useContext, useEffect, useMemo, useCallback, useState } from "react";
import { debounce } from "debounce";
import { useDispatch, useSelector } from "react-redux";
import "react-activity/dist/Bounce.css";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useInitialRender } from "components/useInitialRender";
import {
  calculateTdWidth,
  changeSort,
  formatDoBForDemo,
  getPhoneNo,
  makeBarcode,
  parseBooleanValue,
  personalisationInLC,
  showsInSS,
  sortingFilterInLC,
  sortTestList,
  draggablePersonalizationLocalStorage,
} from "utils";
import GQAPI from "views/GraphQL/gqApi";
import { t } from "stringConstants";

import ExportToExcel from "components/ExportToExcel";
import Icon from "components/Icon";
import Loader from "components/Loader/Loader";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import PersonalizationModal from "components/Modal/personalizationModal";
import { EMPS_DROPDOWN_OPTIONS } from "constant";
import moment from "moment";
import { TABLE_QUICK_TOOLS } from "constant";
import MainTable from "components/Table/MainTable";
import { defaultPersonalization } from "constant";
import EmployeeModal from "components/Hipaa/Modal/EmployeeModal";
import PGPagination from "components/Pagination/PGPagination";
import NotesModal from "components/Notes/NotesModal";
import { useHistory } from "react-router-dom";
import { UPLOAD_CSV_FILE } from "constant";
import { formatPatientIntoPgData } from "utils";
import graphQLApi from "graphQLApi";
import gqApi from "./gqApi";
import { setMessage } from "../../../src/store/features/general/generalAction";
import { fetchAllProviders, renderingProviderAsync } from "store/features/providers/providersSlice";
import { selectedProvider } from "store/features/providers/providersSelectors";
import { userGivenPermission } from "store/features/authentication/authenticationSelectors";
import { LAB_MEMBER_PERSONALIZE } from "constants/personalization";
import { IGNORE_INS } from "constant";

const PatientView = () => {
  const [employees, setEmployees] = useState([]);
  const history = useHistory();
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const [selectedShow, setSelectedShow] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState({ isNew: true });
  const [openEmployeeModal, setOpenEmployeeModal] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [noteModal, setNoteModal] = useState(null);
  const initialRender = useInitialRender();

  const [personalize, setPersonalize] = useState([]);
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const permission = useSelector(userGivenPermission);

  const quickTools = [
    TABLE_QUICK_TOOLS.checkbox,
    permission[EMPLOYEE]?.editPatientRecord && TABLE_QUICK_TOOLS.edit,
    permission[EMPLOYEE]?.addPatientNote && TABLE_QUICK_TOOLS.notes,
  ];
  const { providers } = useSelector(selectedProvider);
  const dispatch = useDispatch();
  const STATS_CLICK_TYPE = {
    ORDERS: "orders",
    ATTENDEES: "attendess",
    COMPLETED: "completed",
    UPLOAD: "upload",
    SHIPPED: "shipped",
    DELIVERED: "delivered",
  };

  const selectedItem = ["firstName", "lastName", "email", "phoneNumber", "street", "street2", "state", "city", "sex"];

  const [users, setUsers] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [labs, setLabs] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNo, setPageNo] = useState(1);
  const [error, setError] = useState("");
  const [stats, setStats] = useState("");
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const searchTerms = [
    "First Name",
    "Last Name",
    "Email",
    "Phone",
    "Gender",
    "Primary Payer",
    "Member ID",
    "Additional Payer",
    "Additional Member ID",
    "Last Updated",
    "Eligibility",
    "Note",
    "Status",
    "Date of Birth",
    "Referring Physician",
    "Client",
  ];

  const appContext = useContext(AppContext);

  const itemsToMap = useMemo(() => {
    const list = filteredEmployees;
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredEmployees, currentPage]);

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case "eligibilityStatus":
        appContext.showEligibilityDetails({
          ...row,
          ...row.eligibilityStatus,
          ins_name_f: row.firstName,
          ins_name_l: row.lastName,
          ins_dob: row.dob,
        });
        break;
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row);
        break;

      case TABLE_QUICK_TOOLS.edit:
        appContext.showPatient({ ...row, isNew: false });
        setCheckboxes([]);
        break;
      case TABLE_QUICK_TOOLS.notes:
        setNoteModal(row);
        setCheckboxes([]);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (initialRender) return;

    if (appContext.patientObject && appContext.patientObject !== 0) {
      const eventType = appContext.patientObject.opType;
      const model = formatPatientIntoPgData(appContext.patientObject.element);
      const newList = [...employees];

      if (eventType === "DELETE") {
        setEmployees(employees.filter((t) => t.mdID !== model.mdID));
        setFilteredEmployees(filteredEmployees.filter((t) => t.mdID !== model.mdID));
        setTotalRecord(totalRecord - 1);
        return;
      }

      if (eventType === "INSERT") {
        newList.unshift(model);
        setTotalRecord(totalRecord + 1);
      } else if (eventType === "UPDATE") {
        const index = newList.findIndex((t) => t.mdID === model.mdID);
        if (index !== -1) {
          newList.splice(index, 1, model);
        } else {
          newList.unshift(model);
        }
      }
      setEmployees(newList);
      refreshList(newList);
    }
  }, [appContext.patientObject]);

  const toggleCheckboxes = (val) => setCheckboxes(val ? filteredEmployees.map((t) => t.id) : []);

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filters).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects

    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }

        if (key === "firstName") {
          return obj["first"] && obj["first"].toLowerCase().includes(filters[key]);
        }
        if (key === "lastName") {
          return obj["last"] && obj["last"].toLowerCase().includes(filters[key]);
        }
        if (key === "Show") {
          return obj.showId && appContext.getSiteName(obj.showId).toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "step") {
          return obj.step && PRE_REGISTRATION_STATUS[obj.step].toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "isVaccinated") {
          return parseBooleanValue(obj.isVaccinated).toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "sex") {
          return obj[key] && obj[key] == filters[key];
        }

        if (key === "ordered") {
          return obj["testSend"] === "true" && obj["testSendQty"];
        }
        if (key === "shipped") {
          return obj["orderStatus"] && obj["orderStatus"] !== "delivered";
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    return models;
  };

  useEffect(() => {
    const sortingFilters = sortingFilterInLC.get();

    // const employeePersonalization = personalisationInLC.get(PERSONALISATION_KEY.EMPLOYEE);

    const employeePersonalization = draggablePersonalizationLocalStorage.get(
      appContext.user.sub,
      PERSONALISATION_KEY.EMPLOYEE,
      LAB_MEMBER_PERSONALIZE
    );
    setPersonalize(employeePersonalization?.filter((item) => item.itemKey));
    if (sortingFilters.sites.sortBy) {
      setSortDescending(sortingFilters.sites.sortDescending);
      setSortBy(sortingFilters.sites.sortBy);
    }
    dispatch(fetchAllProviders({ noShowLoader: true }));
    dispatch(renderingProviderAsync({ noShowLoader: true }));
  }, []);

  useEffect(() => {
    if (pageNo > 1) {
      loadEmployees(Object.keys(filter).length === 0 ? { id: 1 } : filter, pageNo, sortBy, sortDescending);
    }
  }, [pageNo]);

  const fetchFromApi = useCallback(
    debounce((filter, page, sortBy, sortDescending) => {
      loadEmployees(filter, page, sortBy, sortDescending);
    }, 800),
    []
  );

  useEffect(() => {
    if (location.state) {
      setShowFilter(true);
      setFilter({ ...filter, [location.state.term]: location.state.name });
      setTriggerFilter(true);
    }
    setTimeout(() => setShowFilter(false), 1);
  }, [location.state]);

  useEffect(() => {
    setPageNo(1);

    fetchFromApi({ ...filter }, 1, sortBy, sortDescending);
  }, [filter, sortBy, sortDescending]);

  const handleEligibilityCheck = async () => {
    if (checkboxes.length > 0) {
      const emps = filteredEmployees.filter((f) => checkboxes.includes(f.id));
      try {
        const empEligibilityToCheck = emps[0];

        if (
          !empEligibilityToCheck?.insuranceCompanyCode ||
          IGNORE_INS.includes(empEligibilityToCheck.insuranceCompanyCode)
        ) {
          dispatch(setMessage("Payer does not support realtime eligibility.", MESSAGE_MODES.info));
          setCheckboxes([]);
          return;
        }
        setCheckboxes([]);
        setLoading(true);
        const res = await API.checkEligibility(empEligibilityToCheck);

        console.log("res", res);
        const { error, data } = res || {};

        const eligibilityError = Array.isArray(error) ? error[0]?.error_mesg : error?.error_mesg;

        if (error) {
          dispatch(setMessage(eligibilityError || "Not Eligible", MESSAGE_MODES.info, "Not Eligible"));
        } else if (data) {
          appContext.showEligibilityDetails({ ...empEligibilityToCheck, ...data });
        } else {
          dispatch(setMessage(eligibilityError || "Not Eligible", MESSAGE_MODES.info, "Not Eligible"));
        }
        setLoading(false);
      } catch (err) {
        console.log("Error: -", err);
      }
    }
  };

  const handleOpenCreatePatient = () => {
    appContext.showPatient({ isNew: true });
    setCheckboxes([]);
  };

  const formatEmployees = (emps) => {
    return emps.map((m) => {
      const providerName = providers?.find((f) => f.id === m.renderingProvider)?.name || "";
      return { ...m, providerName };
    });
  };

  const empsToMap = useMemo(() => {
    const list = filteredEmployees ? [...filteredEmployees] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredEmployees, currentPage]);

  const loadEmployees = async (params, page, sortBy, sortByDirection) => {
    setLoading(true);
    const filterParam = params;
    try {
      Object.assign(filterParam, { page: page, clientID: CONFIG.clientID });

      if (sortBy) {
        Object.assign(filterParam, { orderBy: sortBy, orderByDirection: sortByDirection });
      }

      const pgList = await API.getEmployeeListFromPG(filterParam);
      setLoading(false);

      let ttlEmps = employees.concat(pgList.rows);
      if (pageNo === 1) {
        setTotalRecord(pgList.total);
        ttlEmps = pgList.rows;
      }

      const emps = formatEmployees(ttlEmps);

      setEmployees(emps);
      refreshList(emps);
    } catch (err) {
      console.log("Load Employees Error:-", err);
      setLoading(false);
    }
  };

  const refreshList = (list) => {
    if (sortBy) {
      setFilteredEmployees(
        sortTestList(
          sortBy,
          sortDescending,
          list,
          appContext.getSiteName,
          appContext.getLabName,
          appContext.getClientName,
          appContext.parseResult
        )
      );
    } else {
      setFilteredEmployees(list);
    }
  };

  const handleChangePage = (page, direction) => {
    setCurrentPage(page);
    const currentRecord = filteredEmployees.length;
    if (direction === "next" && page === totalPages && totalRecord > 0 && currentRecord < totalRecord) {
      setPageNo(pageNo + 1);
    }
  };

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(sortBy === by ? !sortDescending : true);
  };

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    const personalisationData = draggablePersonalizationLocalStorage.saveAs(data, PERSONALISATION_KEY.EMPLOYEE);
    setOpenPersonalizationModal(false);
    draggablePersonalizationLocalStorage.save(JSON.stringify(personalisationData));
    await API.saveUserPersonalisation(appContext.user.sub, personalisationData);
  };

  const handleDeleteConfirm = async (isConfirm) => {
    setOpenDeleteConfirmation(false);
    if (!isConfirm) {
      setCheckboxes([]);
      return;
    }
    try {
      const updateSelectedTestResult = filteredEmployees
        .filter((test) => checkboxes.includes(test.id))
        .map((d) => d.mdID);
      const ttlTest = updateSelectedTestResult.length;
      if (!ttlTest) {
        return;
      }
      setLoading(true);
      await gqApi.deleteEmployeeGQL(updateSelectedTestResult);
      setUsers(users.filter((test) => !checkboxes.includes(test.id)));
      setFilteredEmployees(filteredEmployees.filter((test) => !checkboxes.includes(test.id)));
      setCheckboxes([]);

      const message = ttlTest === 1 ? "Record archived successfully" : `${ttlTest} Records archived successfully`;
      appContext.showSuccessMessage(message);
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const handleCheckboxChange = (e, user) => {
    const filteredList = checkboxes.filter((c) => c !== user.id);
    if (e.target.checked) {
      filteredList.push(user.id);
    }
    setCheckboxes(filteredList);
  };

  const handleClickCreateOrder = () => {
    const selectedPatient = filteredEmployees.find((test) => checkboxes.includes(test.id));
    const newOrder = {
      ...ORDER_EMPTY_OBJECT,
      selectedPatient: { ...selectedPatient, id: selectedPatient?.mdID, accNo: selectedPatient?.mdID },
    };
    appContext.showOrderModal(newOrder);
    setCheckboxes([]);
  };

  const archiveRecordMessage = () => {
    let ttlRecord = allSelected ? totalRecord : checkboxes.length;
    return `Are you sure you want to archive the selected  Record${ttlRecord > 1 ? "s" : ""}?`;
  };

  // OPen delivery model from actions th

  return (
    <Container fluid>
      {loading && <Loader />}
      <div className="shows-screen-parent">
        <Row className="mx-0">
          <Col md="12">
            <Card className="border-0 mb-1">
              <Card.Body className="p-0">
                <div className="shows-filter-wrapper">
                  <div className="shows-filter-inner">
                    <Icon
                      handleClick={() => setShowFilter(!showFilter)}
                      title={"Filter"}
                      label={"Filter"}
                      iconType={"filter"}
                    />
                    <Icon
                      handleClick={() => {
                        if (filteredEmployees && filteredEmployees.length > 0) {
                          setAllSelected(!allSelected);
                          toggleCheckboxes(!allSelected);
                        }
                      }}
                      title={!allSelected ? "Select All" : "Deselect All"}
                      label={!allSelected ? "Select All" : "Deselect All"}
                      iconType={"selectAllIcon"}
                    />
                    {/* <Icon
                        handleClick={() => setOpenEmployeeModal(true)}
                        label={"Create"}
                        title={"Create"}
                        iconType={"addUserIcon"}
                      /> */}
                    <ExportToExcel
                      items={filteredEmployees}
                      selectedItem={personalize.map((m) => {
                        if (m.isCheck) return m.itemKey;
                      })}
                      appContext={appContext}
                      filter={filter}
                      title="Patient Records"
                      sortBy={sortBy}
                      summary={stats}
                      emp
                    />
                    {permission[EMPLOYEE]?.archivePatient && (
                      <Icon
                        disabled={checkboxes.length === 0}
                        handleClick={() => setOpenDeleteConfirmation(true)}
                        title={"Archive"}
                        label={"Archive"}
                        iconType={"binIcon"}
                      />
                    )}
                    <Icon
                      handleClick={() => setOpenPersonalizationModal(true)}
                      title={t("personalize")}
                      label={t("personalize")}
                      iconType={"personalizeIcon"}
                    />
                    <Icon
                      handleClick={handleEligibilityCheck}
                      title={"Check Eligibility"}
                      disabled={checkboxes.length === 0}
                      iconType="acknowledgedPositiveIcon"
                      label="Check Eligibility"
                    />
                    {permission[EMPLOYEE]?.createPatient && (
                      <Icon
                        handleClick={() => {
                          handleOpenCreatePatient();
                        }}
                        title={"Create Patient"}
                        label={"Create Patient"}
                        iconType={"createIcon"}
                      />
                    )}
                    {permission[EMPLOYEE]?.write && (
                      <Icon
                        handleClick={() => {
                          appContext.showTestScheduleModal([], UPLOAD_CSV_FILE.employee);
                        }}
                        title={"Upload Patients"}
                        label={"Upload Patients"}
                        iconType={"loadCrewIcon"}
                      />
                    )}
                    {permission[ORDER]?.addOrders && (
                      <Icon
                        handleClick={handleClickCreateOrder}
                        disabled={checkboxes.length !== 1}
                        title={"Create Order"}
                        label={"Create Order"}
                        iconType={"createIcon"}
                      />
                    )}
                    {/* <Icon
                        handleClick={() => {
                          const selectedEmps = filteredEmployees.filter((f) => checkboxes.indexOf(f.id) !== -1);
                          const uploadCallType =
                            selectedEmps.length > 0 ? UPLOAD_CSV_FILE.manualtest : UPLOAD_CSV_FILE.test;

                          appContext.showTestScheduleModal(selectedEmps, uploadCallType);
                          setCheckboxes([]);
                        }}
                        title={"Schedule Test"}
                        label={"Schedule Test"}
                        iconType={"testNowIcon"}
                      />

                      <Icon
                        handleClick={() => appContext.showUpComingSchedule()}
                        title={"Upcoming Test"}
                        label={"Upcoming Test"}
                        iconType={"calendarIcon"}
                      /> */}
                  </div>
                </div>
                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                    isUsersTable={true}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mx-0">
          <Col md="12">
            <Card
              className={`striped-tabled-with-hover bg-transparent    ${
                empsToMap?.length === 1 || empsToMap?.length === 2 ? "test-details-table" : ""
              }`}
            >
              <Card.Header className="shows-screen-wrapper">
                <Row className="pb-4">
                  <Col md-="6">
                    <Card.Title>
                      <div className="">
                        Patients:
                        <span className="ps-2">
                          {" "}
                          {totalRecord ? totalRecord : filteredEmployees ? filteredEmployees.length : 0}
                        </span>
                      </div>
                    </Card.Title>
                  </Col>
                  <Col md="6">
                    <PGPagination
                      currentPage={currentPage}
                      handleChangePage={handleChangePage}
                      totalPages={totalPages}
                      totalRecord={totalRecord}
                      currentRecord={filteredEmployees.length}
                      showSelectedRecord
                      totalSelected={checkboxes.length}
                    />
                  </Col>
                </Row>
              </Card.Header>

              <Card.Body className="table-full-width px-0 desktop-noScroll mt-0 pt-0">
                <MainTable
                  columns={personalize}
                  rows={empsToMap}
                  flipSort={flipSort}
                  sortBy={sortBy}
                  sortDescending={sortDescending}
                  draggable
                  resizable
                  personalisationKey={"employee"}
                  handleSaveDragAndResize={(personalizeArr) => setPersonalize(personalizeArr)}
                  widthToSkip={155}
                  selectedRows={checkboxes}
                  tools={quickTools}
                  dropDownOptions={[
                    { value: "Logs", title: "Audit Trail" },
                    { value: "test", title: "Test History" },
                  ]}
                  handleDropDownClick={(type, row) => {
                    if (type === "Test History") {
                      appContext.openPatientTestViewModal({ patient: row, type: "patient", uiType: "testHistory" });
                    }
                    if (type === "Audit Trail") {
                      appContext.showLogs({ ...row, title: "Patient Logs", recordType: "employee" });
                    }
                  }}
                  handleCellClick={handleCellClick}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      {openEmployeeModal && (
        <EmployeeModal
          handleClose={() => setOpenEmployeeModal(false)}
          selectItem={selectedEmployee}
          handleSave={() => console.log("save employee")}
        />
      )}

      {openPersonalizationModal && (
        <PersonalizationModal
          data={personalize}
          handleChange={handlePersonalization}
          show={openPersonalizationModal}
          handleClose={() => setOpenPersonalizationModal(false)}
        />
      )}

      {openDeleteConfirmation && (
        <ConfirmationModal
          show={openDeleteConfirmation}
          title="Archive Record"
          message={archiveRecordMessage()}
          handleConfirm={handleDeleteConfirm}
        />
      )}

      {noteModal && (
        <NotesModal
          handleClose={(obj) => {
            setNoteModal(null);
            if (obj) {
              const emps = [...filteredEmployees];
              const findIndex = emps.findIndex((f) => f.mdID === obj.id);

              const isEditing = emps[findIndex].note ? true : false; // Check if note already exists

              emps[findIndex] = { ...emps[findIndex], note: obj.note };
              setFilteredEmployees(emps);

              if (isEditing) {
                appContext.showSuccessMessage("Note edited successfully");
              } else {
                appContext.showSuccessMessage("Note added successfully");
              }
            }
          }}
          emp
          user={appContext.user}
          data={noteModal}
        />
      )}
    </Container>
  );
};

export default PatientView;
